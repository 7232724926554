import debug from "@/utilities/debug";
import { openAlert } from "@/utilities/modal";
import { Service } from "@/services/base.service";
import store from "@/store";
import { useEvent } from "@/plugins/events";

class AppFunctions extends Service {
  constructor() {
    if (AppFunctions._instance) {
      return AppFunctions._instance;
    }
    super();
    AppFunctions._instance = this;

    this.isInitialized = false;
  }

  init() {
    if (this.isInitialized) {
      return;
    }

    this.isInitialized = true;

    window.appShowLoader = appShowLoader;
    window.appHideLoader = appHideLoader;
    window.alertAppleSubscriptionSuccess = alertAppleSubscriptionSuccess;
    window.alertNoAppleSubscription = alertNoAppleSubscription;
    window.alertAppleSubscriptionError = alertAppleSubscriptionError;
    window.avatarWasUpdated = avatarWasUpdated;

    debug.log("Registered global app functions.");
  }
}

let appLoaderTimeout = null;

function appShowLoader() {
  debug.log("Running appShowLoader()");
  store.commit("ui/setAppIsLoading", true);

  appLoaderTimeout = setTimeout(() => {
    appHideLoader();
  }, 30000);
}

function appHideLoader() {
  debug.log("Running appHideLoader()");
  store.commit("ui/setAppIsLoading", false);

  if (appLoaderTimeout) {
    clearTimeout(appLoaderTimeout);
  }
}

function alertAppleSubscriptionSuccess() {
  debug.log("Running alertAppleSubscriptionSuccess()");

  store.commit("global/set", { renewingAppleSubscription: false });
}

function alertNoAppleSubscription(errorReason) {
  debug.log("Running alertNoAppleSubscription()");

  if ("production" !== store.state.global.site && errorReason) {
    openAlert("no-apple-subscription", errorReason);
  } else {
    openAlert("no-apple-subscription");
  }

  store.commit("global/set", { renewingAppleSubscription: false });
}

/**
 * @param {Object} data
 * @param {String} data.error Error reason.
 * @param {String} data.email
 */
function alertAppleSubscriptionError(data) {
  debug.log("Running alertAppleSubscriptionError()");

  store.commit("global/setDebugData", { latestAppleSubscriptionError: data });

  if (typeof data === "undefined") {
    data = { error: "None" };
  }

  if (typeof data === "string") {
    if (data.indexOf("{") === 0) {
      data = JSON.parse(data);
    } else {
      data = { error: data };
    }
  }

  if (global.site !== "production") {
    openAlert("apple-subscription-error", {
      reason: data.error,
      isStage: true
    });
  } else {
    openAlert("apple-subscription-error", {
      reason: data.error
    });
  }

  store.commit("global/set", { renewingAppleSubscription: false });
}

function avatarWasUpdated() {
  const eventHandler = useEvent();

  setTimeout(() => {
    eventHandler.trigger("reloadAvatars"); //TODO: Listen to this and handle.
  }, 3000);
}

export default new AppFunctions();
