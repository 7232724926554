<template>
  <Meta />
  <div>
    <div class="app-logo flex items-center justify-center" v-if="!isApp">
      <img
        src="https://cdn.zcooly.com/images/logos/zcooly.png"
        alt="Zcooly"
        width="256"
        height="69"
        class="zcooly-logo"
      />
    </div>
    <div class="window login-window">
      <div class="window-body">
        <nav class="tabs" v-if="!isInternational">
          <ul>
            <li
              class="family-tab"
              data-cy="family-login-tab"
              :class="{ active: activeForm === 'family' }"
            >
              <a
                @click="changeForm('family')"
                class="inline-flex items-baseline justify-center"
              >
                <svg class="inline w-10 h-10 align-middle mr-4">
                  <use xlink:href="#login-family"></use>
                </svg>
                {{ $t("LOGIN_FAMILY") }}
              </a>
            </li>
            <li
              class="school-tab"
              data-cy="school-login-tab"
              :class="{ active: activeForm === 'school' }"
            >
              <a
                @click="changeForm('school')"
                class="inline-flex items-baseline justify-center"
              >
                <svg class="inline w-10 h-10 align-middle mr-4">
                  <use xlink:href="#login-school"></use>
                </svg>
                {{ $t("LOGIN_SCHOOL") }}
              </a>
            </li>
          </ul>
        </nav>
        <div class="login-windows">
          <img
            src="https://cdn.zcooly.com/images/decoration/pi-looking.png"
            class="pi-looking"
            alt=""
          />
          <ParentLogin
            v-if="!isInternational && activeForm === 'family'"
            persist
          />
          <SchoolLogin v-if="!isInternational && activeForm === 'school'" />
          <InternationalLogin v-if="isInternational" persist />
        </div>
      </div>
    </div>

    <div
      class="max-w-window mx-auto px-4 sm:px-16 text-center mb-4"
      v-if="
        inAppSubscription &&
          isIOS &&
          activeForm === 'family' &&
          canUseAppleSignIn
      "
    >
      <Button @click.prevent="redirectToApple" class="btn btn--apple-sign-in">{{
        $t("LOG_IN_WITH_APPLE")
      }}</Button>
    </div>

    <div
      class="max-w-window mx-auto px-4 sm:px-16 text-center mb-4"
      v-if="canUseAppleSubscription"
    >
      <Button
        @click.prevent="openRestoreAppleSubPurchaseModal"
        class="btn btn--apple"
        data-cy="apple-restore-purchase-button"
        >{{ $t("LOG_IN_WITH_APPLE_SUBSCRIPTION") }}</Button
      >
    </div>

    <!-- This button is only for test -->
    <div
      class="max-w-window mx-auto px-4 sm:px-16 text-center mb-4"
      v-if="isApp && isIOS && !isProduction"
    >
      <Button @click.prevent="appTestButton" class="btn btn--error"
        >Töm Apple-cache</Button
      >
    </div>

    <div
      class="max-w-window mx-auto px-4 sm:px-16 text-center mb-4"
      v-if="activeForm === 'school' && !isInternational"
    >
      <a
        :href="skolonUrl"
        target="_self"
        class="btn btn--skolon"
        data-cy="skolon-login-button"
        >{{ $t("LOGIN_WITH_SKOLON") }}</a
      >
    </div>
  </div>
</template>
<script>
import ParentLogin from "./Forms/ParentLogin";
import SchoolLogin from "./Forms/SchoolLogin";
import InternationalLogin from "./Forms/InternationalLogin";

import auth from "@/utilities/auth";
import { canUseAppleSignIn } from "@/utilities/feature-control";

import {
  handleAppTroubleAlert,
  openConfirm,
  openPrompt
} from "@/utilities/modal";
import Meta from "@/components/Meta";

export default {
  name: "Login",
  components: {
    ParentLogin,
    SchoolLogin,
    InternationalLogin,
    Meta
  },
  data() {
    return {
      isApp: this.$store.state.global.isApp,
      isIOS: this.$store.state.global.isIOS,
      isInternational: this.$store.state.global.isInternational,
      isProduction: this.$store.state.global.site === "production",
      inAppSubscription: this.$store.state.global.inAppSubscription,
      canUseAppleSignIn: canUseAppleSignIn(),
      isLoggingIn: false,
      activeForm: "family",
      login: {
        email: "",
        password: "",
        school: "",
        class: "",
        pin: "",
        username: ""
      },
      loginInternationalError: null
    };
  },
  computed: {
    canUseAppleSubscription() {
      return (
        this.inAppSubscription && this.isIOS && this.activeForm === "family"
      );
    },
    skolonUrl() {
      return (
        process.env.VUE_APP_API_ENDPOINT +
        "/oauth/skolon-init/?language=" +
        this.$i18n.locale +
        "&source=" +
        (this.$store.state.global.isApp ? "app" : "web")
      );
    }
  },
  methods: {
    changeForm(form) {
      this.activeForm = form;
    },
    resetPassword() {
      openPrompt("reset-password");
    },
    redirectToApple() {
      auth.redirectToApple();
    },
    openRestoreAppleSubPurchaseModal() {
      openConfirm("restore-apple-sub-purchase").then(
        () => {
          auth.redirectToApp("onAppleReceiptCheck", {});
        },
        () => {}
      );
    },
    appTestButton() {
      auth.redirectToApp("onClearLocalData", {});
    }
  },
  mounted() {
    /**
     * Calculate and change login form based on URL parameters.
     */
    if (
      (this.$route.params?.form || this.$route.query?.form) &&
      !this.isInternational
    ) {
      const form = this.$route.params?.form || this.$route.query?.form;

      switch (form) {
        case "parent":
        case "family":
          this.changeForm("family");
          break;
        case "teacher":
        case "student":
          this.changeForm("school");
          break;
        case "forgot":
          this.resetPassword();
          break;
      }
    }

    handleAppTroubleAlert();
  }
};
</script>
<style scoped lang="scss">
.pi-looking {
  @apply absolute left-0 w-16;
  margin-left: -3.22rem;

  @media screen and (max-width: 560px) {
    display: none;
  }
}

.forgot-password {
  text-align: right;
}
</style>
