import debug from "@/utilities/debug";

let isLoaded = false;

export function loadSecret() {
  /* istanbul ignore next */
  if (isLoaded) {
    debug.warn("secret is already loaded");

    return;
  }

  isLoaded = true;

  const callback = () => {
    return new Promise(resolve => {
      (async () => {
        const language =
            window.location.href.indexOf(".se") !== -1 ? "sv" : "en",
          $img = document.createElement("img");

        let audio = new Audio("/secret/pi-hello-" + language + ".mp3");

        $img.style.position = "fixed";
        $img.width = 300;
        $img.height = 224;
        $img.id = "pi-saying-hello";
        $img.style.left = "0px";
        $img.style.bottom = "-224px";
        $img.style.transform = "translate(0px, 0px) rotate(-20deg) scale(0.3)";
        $img.style.transition = "transform 0.5s linear";
        $img.style.zIndex = "100";

        await (() => {
          return new Promise(loadResolve => {
            $img.src = "/secret/pi-head.png";
            document.body.appendChild($img);

            $img.addEventListener("load", () => {
              loadResolve();
            });
          });
        })();

        await (() => {
          return new Promise(slideInResolve => {
            $img.style.transform = "translate(0, -204px) rotate(5deg) scale(1)";

            setTimeout(slideInResolve, 300);
          });
        })();

        await audio.play();

        await (() => {
          return new Promise(waitResolve => {
            setTimeout(waitResolve, 2000);
          });
        })();

        await (() => {
          return new Promise(slideOutResolve => {
            $img.style.transform =
              "translate(0px, 0px) rotate(-20deg) scale(0.3)";

            setTimeout(slideOutResolve, 300);
          });
        })();

        audio = null;
        document.body.removeChild($img);

        resolve();
      })();
    });
  };

  (callback => {
    const translation = {
      ArrowLeft: 37,
      ArrowUp: 38,
      ArrowRight: 39,
      ArrowDown: 40,
      KeyA: 65,
      KeyB: 66
    };
    const sequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];
    let sequenceIndex = 0,
      isRunning = false;

    document.addEventListener("keyup", event => {
      if (isRunning) {
        return;
      }

      let keyCode = Object.prototype.hasOwnProperty.call(
        translation,
        event.code
      )
        ? translation[event.code]
        : 0;

      if (keyCode === sequence[sequenceIndex]) {
        sequenceIndex++;

        if (sequenceIndex === sequence.length) {
          isRunning = true;
          callback.call(null).finally(() => {
            isRunning = false;
          });
          sequenceIndex = 0;
        }
      } else {
        sequenceIndex = 0;
      }
    });
  })(callback);
}
