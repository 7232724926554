import { openAlert } from "@/utilities/modal";
import store from "@/store/index";
import toast from "@/assets/libs/toast";
import User from "@/api/v2/user";
import router from "@/router/index";
import { post } from "@/utilities/helpers";
import { serializeParams } from "@/api/resource/helpers";

let instance = null;

class Auth {
  constructor() {
    if (instance) {
      throw new Error("Auth is already initialized.");
    }
    instance = this;
  }

  async logout() {
    await store.dispatch("user/logout");
    await store.commit("ui/resetActionsNeeded");

    if (store.state.global.isApp) {
      this.redirectToApp("onLogoutParentAndChild");
    }

    setTimeout(() => {
      router.push({ name: "start" });
    }, 100);
  }

  generateOldUserObject(user) {
    const userObject = {
      userID: user.id,
      accessToken: user.access_token,
      userType: user.user_type,
      firstName: user.first_name || "",
      lastName: user.last_name || "",
      email: user?.login?.email || "",
      groupID: null,
      schoolID: null
    };

    if (user.group?.id) {
      userObject.groupID = user.group.id;
    }

    if (user.groups?.length) {
      userObject.groupID = user.groups[0].id;
    }

    if (user.school?.id) {
      userObject.schoolID = user.school.id;
    }

    return userObject;
  }

  /**
   * Fixing child object and signing it in to redirect to Play.
   * @param {User} child
   * @param {User} parent
   * @param {boolean} stayOnLogin
   * @return {Promise}
   */
  loginChild(child, parent, stayOnLogin = false) {
    const childData = {
      userID: child.id,
      accessToken: child.access_token,
      userType: "child",
      firstName: child.first_name,
      lastName: child.last_name,
      language: child.language,
      isParent: false,
      isThirdPartyUser: false,
      groupID: parent.group?.id,
      schoolID: parent.school?.id,
      subscriptionEnd: parent.group?.license_expiry,
      subscriptionStatus: parent.active,
      subscriptionType: parent.subscription?.provider,
      subscription: {
        status: parent.subscription?.status,
        type: parent.subscription?.provider,
        active: parent.active
      },
      avatar_url: child.meta?.avatar_url || "",
      username: child.login?.username,
      pinCode: child.login?.pin,
      password: child.login?.password,
      birthYear: child.meta?.birth_year,
      birthMonth: child.meta?.birth_month,
      math_level:
        child.meta?.math_level === "high"
          ? 1
          : child.meta?.math_level === "low"
          ? -1
          : 0,
      limited_content:
        child.user_type === "student"
          ? false
          : String(child.meta?.limited_content) !== "0",

      // Temporary fix until all the apps has the correct format.
      limitedContent:
        child.user_type === "student"
          ? false
          : String(child.meta?.limited_content) !== "0",

      parent: {
        userID: parent.id,
        accessToken: parent.access_token,
        userType: "parent",
        firstName: parent.first_name,
        lastName: parent.last_name,
        language: parent.language,
        isParent: true,
        isThirdPartyUser: false,
        groupID: parent.group?.id,
        schoolID: parent.school?.id,
        subscriptionEnd: parent.group?.license_expiry,
        subscriptionStatus: parent.active,
        subscriptionType: parent.subscription?.provider,
        subscription: {
          status: parent.subscription?.status,
          type: parent.subscription?.provider,
          active: parent.active
        }
      }
    };

    const loginPromise = User.loginUser({
      user_id: child.id,
      access_token: child.access_token
    });

    if (!stayOnLogin) {
      loginPromise.then(
        () => {
          this.redirectToApp("onLoginChild", { user: childData });
        },
        async error => {
          await openAlert("error", {
            code: "3853",
            error: error || null
          });
        }
      );
    }

    return loginPromise;
  }

  redirectToApp(event, data) {
    if (!data) {
      data = {};
    }

    store.commit("global/set", {
      Debug: {
        event,
        data
      }
    });

    if (store.state.global.isWeb) {
      let loginData = {
        user: data.user ? data.user : {}
      };

      const url = store.state.global.isInternational
        ? process.env.VUE_APP_URL_PLAY_WEB_LOGIN_EN
        : process.env.VUE_APP_URL_PLAY_WEB_LOGIN_SV;
      const payload = {
        loginType: data.loginType || "",
        user: JSON.stringify(loginData.user)
      };
      post(url, payload);
    } else {
      // This event does not exist for the app.
      if ("onLoginTeacher" === event) {
        return;
      }

      let appUrl = "";

      if (data) {
        /**
         * Remove object prototypes from resource objects or lists.
         */
        if (data.user && typeof data.user !== "string") {
          data.user = JSON.parse(JSON.stringify(data.user));
        }

        const serializedParams = serializeParams(data);

        if (store.state.global.isWSA) {
          window.external.notify(serializedParams + "&event=" + event);
        } else {
          appUrl = "uniwebview://" + event;
          if (serializedParams && serializedParams.length) {
            appUrl += "?" + serializedParams;
          }

          store.commit("global/set", {
            Debug: {
              lastCalledAppUrl: appUrl
            }
          });

          if (window.Cypress || store.state.global.isCypress) {
            toast({
              message: appUrl,
              type: "uniwebview",
              autoRemoveTime: 30000
            });
          } else {
            window.location.href = appUrl;
          }
        }
      } else {
        if (store.state.global.isWSA) {
          window.external.notify("event=" + event);
        } else {
          appUrl = "uniwebview://" + event;
          store.commit("global/set", {
            Debug: {
              lastCalledAppUrl: appUrl
            }
          });
          if (window.Cypress || store.state.global.isCypress) {
            toast({
              message: appUrl,
              type: "uniwebview",
              autoRemoveTime: 30000
            });
          } else {
            window.location.href = appUrl;
          }
        }
      }
    }
  }

  async redirectToApple(page) {
    if (!page) {
      page = "login";
    }

    this.redirectToApp("onSignInWithApple", { page: page });
  }
}

// const singletonify = className => {
//   return new Proxy(className.prototype.constructor, {
//     instance: null,
//     construct: (target, argumentsList) => {
//       if (!this.instance) {
//         this.instance = new target(...argumentsList);
//       }
//       return this.instance;
//     }
//   });
// };

const authInstance = new Auth();
/* Prevent modification of properties and values */
Object.freeze(authInstance);
export default authInstance;
