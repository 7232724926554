import store from "../store";

class Filters {
  sanitizeHTML(rawHTML) {
    let tempElement = document.createElement("div");
    tempElement.innerHTML = rawHTML;

    if (store.state.global.isApp) {
      /**
       * Remove links that may lead somewhere.
       */
      tempElement.querySelectorAll("a").forEach(linkElement => {
        let replacement = document.createElement("span");
        replacement.innerHTML = linkElement.innerHTML;
        linkElement.parentNode.replaceChild(replacement, linkElement);
      });

      /**
       * Remove buttons that may lead somewhere.
       */
      tempElement.querySelectorAll("button").forEach(buttonElement => {
        buttonElement.remove();
      });
    }

    return tempElement.innerHTML;
  }
}

export default {
  install: (app, options) => {
    app.config.globalProperties.$filters = new Filters(options);
  }
};
