<template>
  <div class="login-window">
    <h2 class="font-nunito-extrabold mb-5">
      {{ $t("LOG_IN_AS_CLASS") }}
    </h2>

    <ZForm>
      <SelectInput
        v-if="!isApp"
        name="loginSchoolType"
        v-model="loginSchoolType"
        t-label="STUDENT_OR_TEACHER_QUESTION"
        t-placeholder="STUDENT_OR_TEACHER_QUESTION"
        data-cy="login-school-type"
        :options="[
          { label: $t('STUDENT'), value: 'student' },
          { label: $t('TEACHER'), value: 'teacher' }
        ]"
      />
    </ZForm>

    <div v-if="loginSchoolType === 'student'">
      <div class="form-errors mb-2">
        <p class="error-message" v-if="loginStudentError === 'error'">
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_MISC") }}
        </p>
        <p
          class="error-message"
          v-if="loginStudentError === 'license-expired'"
          data-cy="login-student-error-license"
        >
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_STUDENT_LICENSE_EXPIRED") }}
        </p>
        <p
          class="error-message"
          v-if="loginStudentError === 'credentials'"
          data-cy="login-student-error-credentials"
        >
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_STUDENT_CREDENTIALS") }}
        </p>
      </div>

      <!-- Swedish student login form -->
      <ZForm @submit="onLoginStudent" v-slot="{ meta, isSubmitting }">
        <SelectInput
          name="studentSchool"
          v-model="login.school"
          t-label="SELECT_SCHOOL_OR_PRESCHOOL"
          t-placeholder="SELECT_SCHOOL_OR_PRESCHOOL"
          @change="onSchoolChange"
          :options="schoolList"
          :disabled="isSubmitting"
          data-cy="login-student-school"
        >
        </SelectInput>

        <SelectInput
          name="studentClass"
          v-model="login.class"
          t-label="SELECT_YOUR_CLASS"
          t-placeholder="SELECT_YOUR_CLASS"
          :options="classList"
          :disabled="
            !login.school ||
              !classList.length ||
              isLoadingClasses ||
              isSubmitting
          "
          data-cy="login-student-class"
        >
        </SelectInput>

        <TextInput
          type="tel"
          class="pin-code"
          t-label="ENTER_PIN"
          v-model="login.pin"
          rules="required|numeric|length:4,5"
          pattern="[0-9]*"
          name="studentPin"
          :disabled="!login.school || !login.class || isSubmitting"
          data-cy="login-student-pin"
          autocomplete="new-pin-code"
        />

        <div class="button-container">
          <Button
            type="submit"
            class="btn btn--grass"
            :disabled="
              !meta.valid || !login.school || !login.class || isSubmitting
            "
            :is-loading="isSubmitting"
            data-cy="login-student-button"
            >{{ $t("LOGIN_ZCOOLY_PLAY") }}
          </Button>
        </div>
      </ZForm>
    </div>

    <div v-if="loginSchoolType === 'teacher' && !isApp">
      <div class="form-errors mb-2">
        <p class="error-message" v-if="loginTeacherError === 'error'">
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_MISC") }}
        </p>
        <p
          class="error-message"
          v-if="loginTeacherError === 'license-expired'"
          data-cy="login-teacher-error-license"
        >
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_TEACHER_LICENSE_EXPIRED") }}
        </p>
        <p
          class="error-message"
          v-if="loginTeacherError === 'credentials'"
          data-cy="login-teacher-error-credentials"
        >
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          {{ $t("LOGIN_ERROR_CREDENTIALS") }}
        </p>
      </div>

      <!-- Swedish teacher login form -->
      <ZForm @submit="onLoginTeacher" class="mb-4" v-slot="{ isSubmitting }">
        <TextInput
          type="email"
          name="teacherEmail"
          v-model="login.email"
          rules="required|email"
          t-label="EMAIL_ADDRESS"
          :disabled="isSubmitting"
          data-cy="login-teacher-email"
        />

        <TextInput
          type="password"
          name="teacherPassword"
          v-model="login.password"
          rules="required"
          t-label="PASSWORD"
          :disabled="isSubmitting"
          visibility-toggle
          data-cy="login-teacher-password"
        />

        <p class="forgot-password">
          <a
            @click.prevent="resetPassword"
            class="underline text-sm font-open-sans-bold cursor-pointer"
            >{{ $t("FORGOT_PASSWORD") }}</a
          >
        </p>
        <div class="button-container">
          <Button
            type="submit"
            class="btn btn--grass"
            :disabled="isSubmitting"
            :is-loading="isSubmitting"
            data-cy="login-teacher-button"
            >{{ $t("LOGIN") }}
          </Button>
        </div>
      </ZForm>
    </div>
    <div class="registration-link text-sm mt-8" v-if="!inAppSubscription">
      <p class="mb-2">{{ $t("NOT_ALREADY_MEMBER") }}</p>
      <router-link
        :to="{ name: 'register-teacher' }"
        class="underline font-open-sans-bold"
        data-cy="teacher-registration-link"
        >{{ $t("GET_ZCOOLY_PLAY") }}
      </router-link>
    </div>
  </div>
</template>
<script>
import auth from "@/utilities/auth";
import Button from "@/components/Button";
import SelectInput from "@/components/form-parts/SelectInput";
import ZForm from "@/components/form-parts/ZForm";
import TextInput from "@/components/form-parts/TextInput";
import User from "@/api/v2/user";
import School from "@/api/v1/school";
import Group from "@/api/v1/group";
import { openPrompt } from "@/utilities/modal";
import debug from "@/utilities/debug";

export default {
  name: "SchoolLogin",
  components: { Button, SelectInput, TextInput, ZForm },
  data() {
    return {
      login: {
        school: "",
        class: "",
        pin: "",
        email: "",
        password: ""
      },
      classList: [],
      schoolList: [],
      loginSchoolType: this.$store.state.global.isApp ? "student" : "",
      loginStudentError: null,
      loginTeacherError: null,
      isLoadingClasses: false,
      isLoadingSchools: false,
      isApp: this.$store.state.global.isApp,
      inAppSubscription: this.$store.state.global.inAppSubscription
    };
  },
  methods: {
    async onLoginTeacher({ resolve }) {
      try {
        const user = await User.loginUser({
          email: this.login.email,
          password: this.login.password
        });

        if (user.user_type === "teacher") {
          auth.redirectToApp("onLoginTeacher", {
            user: auth.generateOldUserObject(user),
            loginType: "teacher"
          });
        } else {
          this.$store.dispatch("user/setUser", {
            user: user,
            token: user.login.token,
            fromLogin: true
          });

          await this.$router.push({ name: "children" });
        }
      } catch (error) {
        if (error.response?.status === 401) {
          this.loginTeacherError = "credentials";
        } else if (error.response?.status === 402) {
          this.loginTeacherError = "license-expired";
        } else {
          this.loginTeacherError = "error";
        }
        resolve();
      }
    },
    async loadSchools() {
      if (this.schoolList.length > 1 || this.isLoadingSchools) {
        return;
      }

      this.isLoadingSchools = true;

      try {
        this.schoolList = await School.getRegisteredSchools();
      } catch (error) {
        debug.warn(error);
      }
      this.isLoadingSchools = false;
    },
    async loadClasses(schoolId) {
      if (this.classList.length > 1 || this.isLoadingClasses || !schoolId) {
        return;
      }

      this.isLoadingClasses = true;
      try {
        const classList = await Group.getRegisteredSchoolClasses(schoolId);

        if (classList.length) {
          this.classList = classList.map(group => {
            return {
              value: String(group.id),
              label: group.name
            };
          });
        }
      } catch (error) {
        debug.warn(error);
      }

      this.isLoadingClasses = false;
    },
    onSchoolChange() {
      this.login.class = "";
      this.classList = [];
      this.login.pin = "";
      this.loadClasses(this.login.school);
    },
    async onLoginStudent({ resolve }) {
      /**
       * Save selected school and class in local storage.
       */
      window.localStorage.setItem(
        "studentCache",
        JSON.stringify({
          school: this.schoolList.find(school => {
            return school.value === this.login.school;
          }),
          class: this.classList.find(classItem => {
            return classItem.value === this.login.class;
          })
        })
      );

      try {
        const user = await User.loginUser({
          group_id: this.login.class,
          pin: this.login.pin
        });

        //TODO: Temporary, remove the if-statement, but keep its content, after web game is deployed.
        if (this.$store.state.global.localWebGame) {
          this.$router.push({
            name: "game",
            params: { user: JSON.stringify(user) }
          });
        } else {
          auth.redirectToApp("onLoginStudent", {
            user: auth.generateOldUserObject(user),
            loginType: "student"
          });
        }
      } catch (error) {
        window.localStorage.setItem("studentCache", null);
        if (error.response?.status === 401) {
          this.loginStudentError = "credentials";
        } else if (error.response?.status === 402) {
          this.loginStudentError = "license-expired";
        } else {
          this.loginStudentError = "error";
        }
        resolve();
      }
    },
    resetPassword() {
      openPrompt("reset-password", {
        email: this.login.email
      });
    }
  },
  mounted() {
    /**
     * Loading last student login info from local storage.
     */
    if (window.localStorage.getItem("studentCache")) {
      const studentCache = JSON.parse(
        window.localStorage.getItem("studentCache")
      );
      if (studentCache?.school) {
        this.schoolList.push(studentCache.school);
        this.login.school = studentCache.school.value;
      }
      if (studentCache?.class) {
        this.classList.push(studentCache.class);
        this.login.class = studentCache.class.value;
      }
    }

    this.loadSchools();

    /**
     * Calculate and change login form based on URL parameters.
     */
    if (this.$route.params?.form && !this.isInternational) {
      switch (this.$route.params.form) {
        case "teacher":
          this.loginSchoolType = "teacher";
          break;
        case "student":
          this.loginSchoolType = "student";
          break;
      }
    }

    if (this.$route.query?.form && !this.isInternational) {
      switch (this.$route.query.form) {
        case "teacher":
          this.loginSchoolType = "teacher";
          break;
        case "student":
          this.loginSchoolType = "student";
          break;
      }
    }

    /**
     * Load student school and/or group from GET parameters.
     */
    if (this.$route.query?.schoolId) {
      this.login.school = String(this.$route.query.schoolId);
      this.loadSchools();
    }

    if (this.$route.query?.groupId && this.login.school) {
      this.login.class = String(this.$route.query.groupId);
      this.loadClasses(this.login.school);
    }

    if (this.$route.query?.email?.length) {
      this.login.email = this.$route.query.email;
    }
  }
};
</script>
