import { isObject, merge } from "lodash";
import Auth from "@/utilities/auth.js";
import Registrations from "@/api/v1/registrations";
import Campaigns from "@/api/v1/campaigns";
import Invitations from "@/api/v1/invitations";
import Register from "@/api/v2/register";
import debug from "@/utilities/debug";
import GTM from "@/services/gtm.service";
import registrations from "@/api/v1/registrations";
import localeService from "@/services/locale.service";

const SAVED_REGISTRATION_KEY = "_saved_registration";
let sentLead = false;

export default {
  namespaced: true,
  state: () => ({
    campaignData: {},
    campaignDataIsLoading: false,
    campaignCode: "",
    invitationSender: {},
    invitationSenderIsLoading: false,
    invitationCode: "",
    registeredTeacher: {},
    price: 0, //Set this when selecting a package
    currency: "" //Set this when selecting a package
  }),
  mutations: {
    set(state, data) {
      merge(state, data);

      if (data.email && state.userType === "parent") {
        if (!sentLead) {
          sentLead = true;

          /**
           *  Send data to backend, this will be used in a reminder email to the churned customer
           */
          Registrations.reportRegistrationStep({
            email: data.email,
            step: 1,
            user_type: "parent",
            language: localeService.getGlobalLocale(),
            data: data
          }).then(
            () => {},
            () => {}
          );

          GTM.event("registration", "lead", "registrationType", "parent", {
            membership: state.packageName || null,
            campaignCode: state.campaignCode || null,
            campaignDays: state.campaignData?.trial_extension_days || 0,
            campaignDiscount: state.campaignData?.discount || 0,
            price: state.price,
            currency: state.currency
          });
        }
      }
    },
    resetCampaignCode(state) {
      state.campaignData = {};
      state.campaignCode = "";
      state.campaignDataIsLoading = false;
    },
    loadRegistrationData(state) {
      const savedRegistrationJson = window.localStorage.getItem(
        SAVED_REGISTRATION_KEY
      );

      if (savedRegistrationJson?.length) {
        const savedRegistrationData = JSON.parse(savedRegistrationJson);

        if (isObject(savedRegistrationData)) {
          merge(state, savedRegistrationData);
        }
      }
    }
  },
  actions: {
    registerApple(context, payload) {
      registrations.clearValidateEmailCache();

      /**
       * return promise that resolves when action is taken in modal.
       */
      return new Promise(resolve => {
        /**
         *  Send data to app
         */
        Auth.redirectToApp("onAppleRegistration", payload);
        resolve();
      });
    },
    registerStripe(context, payload) {
      return new Promise((resolve, reject) => {
        Register.registerParent(payload).then(response => {
          resolve(response);
        }, reject);
      });
    },
    inviteParent(context, payload) {
      return new Promise((resolve, reject) => {
        Register.inviteParent(payload).then(response => {
          resolve(response);
        }, reject);
      });
    },
    /**
     * Store campaign data to be used over different components.
     */
    setCampaignCode({ commit, state }, code) {
      commit("set", {
        campaignDataIsLoading: true,
        campaignCode: code,
        campaign_code: code
      });
      return new Promise((resolve, reject) => {
        Campaigns.validateCampaignCode(code).then(
          campaignData => {
            let commitData = {
              campaignDataIsLoading: false
            };

            if (campaignData.name !== state.campaignData.name) {
              commitData.campaignData = campaignData;
            }

            commit("set", commitData);
            resolve(campaignData);
          },
          error => {
            debug.error("Campaign code did not validate: ", error);
            commit("resetCampaignCode");
            reject(error);
          }
        );
      });
    },
    /**
     * Store campaign data to be used over different components.
     */
    setInvitationCode({ commit }, code) {
      commit("set", { invitationSenderIsLoading: true, invitationCode: code });
      return new Promise((resolve, reject) => {
        Invitations.getSender(code).then(
          invitationSender => {
            commit("set", {
              invitationSender: invitationSender,
              invitationSenderIsLoading: false
            });
            resolve(invitationSender);
          },
          error => {
            commit("set", { invitationSenderIsLoading: false });
            reject(error);
          }
        );
      });
    },
    clearRegistrationData() {
      window.localStorage.removeItem(SAVED_REGISTRATION_KEY);
    },
    saveRegistrationData({ state }) {
      window.localStorage.setItem(
        SAVED_REGISTRATION_KEY,
        JSON.stringify(state)
      );
    }
  },
  strict: process.env.VUE_APP_ENV !== "production"
};
