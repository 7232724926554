<template>
  <div v-if="exists">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "TranslationExists",
  props: {
    path: {
      required: true,
      type: String
    }
  },
  computed: {
    exists() {
      return (
        this.$i18n.te(this.path) &&
        this.$i18n.t(this.path).length &&
        this.$i18n.t(this.path) !== this.path &&
        this.$i18n.t(this.path) !== ""
      );
    }
  }
};
</script>
