import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Spinner from "@/components/Spinner.vue";

import prettyPrintJson from "@/directives/pretty-print-json";
import clickOutside from "@/directives/click-outside";
import focusElement from "@/directives/focus-element";
import translationExists from "@/components/utilities/TranslationExists.vue";
import "@/assets/scss/app.scss";
import registerGlobalValidators from "@/components/form-parts/global-validators";
import eventHandler from "@/plugins/events";
import filters from "@/plugins/filters";
import debug from "@/utilities/debug";

window.onunhandledrejection = event => {
  if (event?.reason) {
    console.log("Unhandled promise rejection reason: ", event.reason);
    event.preventDefault();
    debug.reportError("Unhandled promise rejection reason: " + event.reason);
  } else {
    debug.reportError(event.toString());
  }
};

window.addEventListener("error", event => {
  debug.reportError(event);
});

/**
 * Polyfill for Stripe.prototype.replaceAll
 */
if (!String.prototype.replaceAll) {
  /**
   * Replaces all matched occurrences in a string.
   * @param {String|RegExp} search
   * @param {String} replacement
   * @return {String}
   */
  String.prototype.replaceAll = function(search, replacement) {
    /**
     * If search is a regex pattern.
     */
    if (
      Object.prototype.toString.call(search).toLowerCase() === "[object regexp]"
    ) {
      return this.replace(search, replacement);
    }

    /**
     * If search is a string
     */
    return this.replace(new RegExp(search, "g"), replacement);
  };
}

registerGlobalValidators();

createApp(App)
  /* Use */
  .use(i18n)
  .use(store)
  .use(router)
  .use(eventHandler)
  .use(filters)
  /* Directives */
  .directive("pretty-print-json", prettyPrintJson)
  .directive("click-outside", clickOutside)
  .directive("focus", focusElement)
  /* Components */
  .component(translationExists.name, translationExists)
  .component(Button.name, Button)
  .component(Spinner.name, Spinner)
  .component(Card.name, Card)
  .mount("#app");
