import store from "@/store/index";
import semver from "semver";

export function canUseNewRegistration() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 3.2.3"
  );
}

export function canUseAppleSubscription() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 2.3.0"
  );
}

export function canUseAppleMultiPlanSubscription() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 3.1.0"
  );
}

export function canUseSubscriptionPackages() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 3.3.1"
  );
}

export function canUseAppleSignIn() {
  if (store.state.global.isIOS && store.state.global.inAppSubscription) {
    /*let versionString,
      version = [];
    if (store.state.global.isNewIpad) {
      versionString = window.navigator.userAgent.match(
        /OS X (\d+)_(\d+)_?(\d+)?/
      );
      if (versionString && versionString.length > 3) {
        version = [
          parseInt(versionString[1], 10),
          parseInt(versionString[2] || 0, 10),
          parseInt(versionString[3] || 0, 10)
        ];
      } else {
        return false;
      }
    } else {
      versionString = window.navigator.userAgent.match(
        /OS (\d+)_(\d+)_?(\d+)?/
      );
      if (versionString && versionString.length > 3) {
        version = [
          parseInt(versionString[1], 10),
          parseInt(versionString[2] || 0, 10),
          parseInt(versionString[3] || 0, 10)
        ];
      } else {
        return false;
      }
    }

    if (version[0] < 13 && !store.state.global.isNewIpad) {
      return false;
    }*/

    return semver.satisfies(
      semver.coerce(store.state.global.appVersion),
      ">= 3.0.0"
    );
  }
  return false;
}

export function canShareNatively() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 3.0.0"
  );
}

export function canPlayArchipelago() {
  return semver.satisfies(
    semver.coerce(store.state.global.appVersion),
    ">= 3.2.0"
  );
}
