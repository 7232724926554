{
    "ABORT": "Abort",
    "ABOUT": "About Zcooly",
    "ACCEPT_PARENT_INVITATION_DESCRIPTION": "You have been invited to share a family account with {senderEmail}. Review your information here and choose a password to get started.",
    "ACCEPT_PARENT_INVITATION_ERROR": "The link to this page is incomplete. Please make sure that you are using the complete link, or contact our <a href=\"mailto:support%40zcooly.com\">support</a> and we will help you.",
    "ACCEPT_PARENT_INVITATION_FAILED_SUBMIT": "The registration of your parent account failed.",
    "ACCEPT_PARENT_INVITATION_HEADING": "Complete the registration of your parent account",
    "ADDRESS_CITY*": "City*",
    "ADDRESS_ZIP*": "Zip code*",
    "ALERT_CHILD_REMOVED_TEXT": "The child user has been removed and all its data has been deleted.",
    "ALERT_CHILD_REMOVED_TITLE": "The child user has been removed",
    "ALERT_COULD_NOT_INVITE_PARENT_REGISTRATION_TEXT": "We could not invite your partner. You can try again later on the \"My family\" page in your account settings.",
    "ALERT_COULD_NOT_INVITE_PARENT_REGISTRATION_TITLE": "Something went wrong",
    "ALERT_NO_INVITATION_CODE_TEXT": "Could not create an invitation link. Please try again or contact support if the problem persists.",
    "ALERT_NO_INVITATION_CODE_TITLE": "Oh no!",
    "ALERT_REGISTRATION_CHILD_PROFILE_ERROR_TEXT": "An unexpected error has occurred and we will try to solve it as soon as possible. You can complete this step later under your account settings.",
    "ALERT_REGISTRATION_CHILD_PROFILE_ERROR_TITLE": "An error has occurred",
    "ALERT_REGISTRATION_ERROR_TEXT": "An unexpected error has occurred and we will try to solve it as soon as possible. If the error persists, please contact our support and we'll help you.",
    "ALERT_REGISTRATION_ERROR_TITLE": "An error has occurred",
    "ALERT_REGISTRATION_PLAN_ERROR_TEXT": "An unexpected error has occurred and we will try to solve it as soon as possible. You can complete this step later on the subscription page under your account settings.",
    "ALERT_REGISTRATION_PLAN_ERROR_TITLE": "An error has occurred",
    "ALERT_YOUR_QR_CODE_DESCRIPTION": "Point a QR code reader towards the code to open the invitation link.",
    "ALERT_YOUR_QR_CODE_TITLE": "Your QR code",
    "APPROVED_CAMPAIGN_CODE": "Confirmed! You benefit from the following campaign:",
    "APP_SUBSCRIPTION_SLOGAN": "Make learning fun.",
    "BILLING_INFORMATION": "Billing information",
    "BILLING_REFERENCE*": "Billing reference*",
    "BILLING_STREET*": "Street*",
    "BIRTH_YEAR": "Birth year",
    "BY_CLICKING_I_AGREE": "By clicking 'Continue' you accept Zcooly's",
    "CAMPAIGN_CODE": "Campaign code",
    "CAMPAIGN_CODE_IS_NOT_VALID": "The campaign code is not valid.",
    "CANCEL": "Cancel",
    "CANCEL_BOOKING": "Cancel",
    "CANCEL_MY_FREE_UNTIL_FURTHER_NOTICE": "Cancel my free period",
    "CARD_NUMBER": "Card number",
    "CHANGE": "Change",
    "CHANGE_PACKAGE_NEW_PRICE_PER_12_MONTHS": "Your new price will be {price}/6 months.",
    "CHANGE_PACKAGE_NEW_PRICE_PER_6_MONTHS": "Your new price will be {price}/12 months.",
    "CHANGE_PACKAGE_NEW_PRICE_PER_MONTH": "Your new price will be {price}/month.",
    "CHANGE_PAYMENT_INTERVAL": "Change",
    "CHANGE_SUBSCRIPTION_PAYMENT_INTERVAL": "Change payment interval",
    "CHANGE_SUBSCRIPTION_PLAN": "Change membership",
    "CHANGING_SUBSCRIPTION_TOO_SOON_TEXT": "Please wait 5 minutes before making more changes to your subscription.",
    "CHANGING_SUBSCRIPTION_TOO_SOON_TITLE": "Oops!",
    "CHOOSE_PACKAGE": "Choose package",
    "CHOOSE_PAYMENT_METHOD": "Choose payment method",
    "CHOOSE_SUBSCRIPTION_PAYMENT_INTERVAL": "Choose payment interval",
    "CHOOSE_SUBSCRIPTION_PLAN": "Choose subscription plan",
    "CHOOSE_THIS_PACKAGE": "Select this membership",
    "CLOSE": "Close",
    "COMPLETE_REGISTRATION": "Complete registration",
    "CONFIRM_ACCOUNT_DELETION_DESCRIPTION": "If you complete the action, your data and your children's progress in the games will be permanently deleted. You will receive an email afterwards confirming the deletion of your data.",
    "CONFIRM_ACCOUNT_DELETION_DESCRIPTION_EXTRA_PARENT": "If you carry out the action, your personal data will be permanently deleted. Since you are not the account owner, your family's data will remain. You will receive an email afterwards confirming the deletion of your data.",
    "CONFIRM_ACCOUNT_DELETION_EMAIL_INSTRUCTION": "To ensure that this does not happen by mistake, e.g. because you clicked incorrectly, we ask you to enter your e-mail address in the field below. If the e-mail address is correct, the deletion will begin.",
    "CONFIRM_ACCOUNT_DELETION_ERROR_EMAIL": "The email address did not match your account.",
    "CONFIRM_ACCOUNT_DELETION_ERROR_OTHER": "Something went wrong, please contact our support and we will help you.",
    "CONFIRM_ACCOUNT_DELETION_TITLE": "Are you sure?",
    "CONFIRM_APPLE_SUB_LOGOUT": "Are you sure?",
    "CONFIRM_CANCEL_SUBSCRIPTION_TITLE": "Do you want to cancel your subscription?",
    "CONFIRM_CLASS_TAKEOVER_TEXT": "The class you picked already has a teacher assigned to it.<br><br>If you are certain that this is your class, you can proceed with the registration but will need the currently assigned teacher to confirm your registration.<br><br>Register anyway?",
    "CONFIRM_CLASS_TAKEOVER_TITLE": "Get access to your class",
    "CONFIRM_LOGOUT": "In order to log in again you will need to log in with your email and password.<br>Are you sure?",
    "CONFIRM_REMOVE_CHILD_DESCRIPTION": "If you remove this child user, all its progress and data will be lost.",
    "CONFIRM_REMOVE_CHILD_TITLE": "Do you really want to remove this child user?",
    "CONTACT": "Contact",
    "CONTINUE": "Continue",
    "CONTINUE_AND_GET_FREE_ALL_YEAR_LONG": "Continue and play for free all year long",
    "COOKIE_SETTINGS": "Cookie preferences",
    "COST_PER_SIX_MONTHS": "{price} / six months",
    "COST_PER_YEAR": "{price} / year",
    "COULD_NOT_CREATE_SUBSCRIPTION_PAUSE": "Could not schedule pause",
    "COULD_NOT_CREATE_SUBSCRIPTION_PAUSE_ERROR_MESSAGE": "Something went wrong on our servers while trying to schedule the pause. If this is a recurring problem, please contact us for support.",
    "CREATE_ACCOUNT": "Create account",
    "CREATE_PARENT_PROFILE": "Create parent user",
    "CURRENT_PAYMENT_INTERVAL": "Current payment interval",
    "DISCOUNT": "{discount}% Discount",
    "DONE": "Done",
    "EMAIL": "Email",
    "EMAIL_ADDRESS": "Email address",
    "EMAIL_ADDRESS_OR_USERNAME": "Email address or username",
    "EMAIL_US_WITH_ANY_QUESTIONS": "If you have any questions, get in touch with <a href='mailto:support%40zcooly.com'>support%40zcooly.com</a> and they will help you out.",
    "ENTER_CAMPAIGN_CODE": "Enter campaign code",
    "ENTER_FIRSTNAME": "Enter first name",
    "ENTER_LASTNAME": "Enter last namn",
    "ENTER_PARENT_EMAIL": "Enter your email",
    "ENTER_PARTNER_PARENT_EMAIL": "Enter parent email",
    "ENTER_PASSWORD": "Enter your password",
    "ENTER_PIN": "Enter PIN",
    "ERROR_MAXIMUM_REMINDERS_SENT": "You can't send more invitations to this address.",
    "ERROR_MESSAGE_UNABLE_TO_DO_ACTION": "The operation could not be performed.",
    "FIELD_ERROR_CANNOT_MATCH": "The field cannot have the same value.",
    "FIELD_ERROR_CANNOT_MATCH_PARENT_EMAIL": "The email addresses cannot be the same.",
    "FIELD_ERROR_INVALID_EMAIL": "Invalid email address",
    "FIELD_ERROR_INVALID_NAME": "Invalid name. Only use letters, space or hyphen.",
    "FIELD_ERROR_INVALID_NUMBER": "This field can only contain digits.",
    "FIELD_ERROR_INVALID_PASSWORD": "The password needs to be at least 6 characters long.",
    "FIELD_ERROR_INVALID_PHONE_NUMBER": "Invalid phone number",
    "FIELD_ERROR_INVALID_ZIP_CODE": "Invalid zip code",
    "FIELD_ERROR_NOT_MATCHING": "Fields are not matching",
    "FIELD_ERROR_NOT_UNIQUE_EMAIL": "The email address is already in use",
    "FIELD_ERROR_REQUIRED": "This field is required",
    "FIELD_ERROR_TOO_LARGE": "The value must be less than {limit}",
    "FIELD_ERROR_TOO_LONG": "This field can not have more than {limit} characters",
    "FIELD_ERROR_TOO_SHORT": "This field must have at least {limit} characters",
    "FIELD_ERROR_TOO_SMALL": "TThe value must be greater than {limit}",
    "FIELD_HAS_INVALID_VALUE": "Field has invalid value.",
    "FIELD_IS_NOT_AN_EMAIL": "Not a correct email format.",
    "FIELD_IS_REQUIRED": "This field is required.",
    "FIELD_IS_TOO_LONG": "The field needs to have {length} characters.",
    "FIELD_NEEDS_HAVE_X_AMOUNT_OF_CHARS": "The field needs to have {min} characters.",
    "FIRSTNAME": "First name",
    "FORGOT_PASSWORD": "Forgot password?",
    "FREE_FOREVER": "Free forever",
    "FREE_UNTIL_2023": "All school year!",
    "FURTHER_NOTICE_CANCEL_AND_CONFIRM_LOSS_OF_DATA": "Are you sure you want to cancel your free period? This means you wont be able to use Zcooly for free and you will lose all information about the progress your children have made.",
    "FURTHER_NOTICE_CANCEL_AND_CONFIRM_LOSS_OF_DATA_TITLE": "Cancel and stop using Zcooly for free",
    "GENERIC_PAYMENT_ERROR_MESSAGE": "Your selected payment method failed. If your information is correct, it is usually because your bank has refused payment. This may be because online payments are not supported, you do not have enough funds in the account, or the payment card is disabled or reported as lost/stolen. Please check that you can make payments online and try again.",
    "GET_ZCOOLY_PLAY": "I want to create an account",
    "GO_TO_NEXT_STEP_REGISTRATION": "Continue",
    "GO_TO_NEXT_STEP_REGISTRATION_PLAN_SELECTION_MONTH": "Continue ({totalPrice}/mon)",
    "GO_TO_NEXT_STEP_REGISTRATION_PLAN_SELECTION_MONTHS": "Continue ({totalPrice}/{months} mon)",
    "INVITATION_SEND_BY_EMAIL_DESCRIPTION": "Enter the email address of the friend you want to invite. When you click send we will send an invitation mail with your invitation link.",
    "INVITATION_SEND_BY_EMAIL_HEADING": "Invite a friend with an email",
    "INVITE_EXTRA_PARENT_DESCRIPTION": "You can invite another parent to join your family account. The other parent can access the same features as you, but you have individual settings and login credentials.",
    "INVITE_EXTRA_PARENT_HEADING": "Invite new parent",
    "INVITE_PARENT": "Invite parent",
    "I_AGREE_TO_THE_BLANK": "Yes, I agree to Zcooly's ",
    "I_CONFIRM": "I confirm",
    "I_GOT_A_CAMPAIGN_CODE": "I've got a campaign code",
    "I_WANT_TO_CANCEL_MY_SUBSCRIPTION": "I want to cancel my subscription",
    "I_WANT_TO_LOG_IN": "I want to log in",
    "I_WANT_TO_MY_CANCEL_SUBSCRIPTION": "I want to cancel my subscription",
    "I_WANT_TO_PAUSE_LONG_TRIAL_SUBSCRIPTION": "I want to cancel my free period",
    "I_WANT_TO_PAUSE_OR_CANCEL_SUBSCRIPTION": "I want to pause or cancel my subscription",
    "I_WANT_TO_SKIP_THIS_STEP": "Skip this step",
    "KEEP_FREE_UNTIL_FURTHER_NOTICE": "Keep my free period",
    "KEEP_MY_SUBSCRIPTION": "Keep my subscription",
    "LASTNAME": "Last name",
    "LOGIN": "Sign in",
    "LOGIN_ERROR_CREDENTIALS": "Incorrect email or password. Did you forget your password?",
    "LOGIN_ERROR_INTERNATIONAL_CREDENTIALS": "Incorrect username or password. Did you forget your password?",
    "LOGIN_ERROR_MISC": "Whoops! Something went wrong.",
    "LOGIN_ERROR_PARENT_LICENSE_EXPIRED": "Sorry, but your license has expired. Please check your subscription on your account page.",
    "LOGIN_ERROR_STUDENT_CREDENTIALS": "Wrong PIN code used. Ask your teacher for help.",
    "LOGIN_ERROR_STUDENT_LICENSE_EXPIRED": "The class license has expired. Ask your teacher for help.",
    "LOGIN_ERROR_TEACHER_IN_APP": "You have a teacher account and can only sign in in a browser.",
    "LOGIN_ERROR_TEACHER_LICENSE_EXPIRED": "Sorry, but the license for your class has expired, please contact us and we will get you started again!",
    "LOGIN_FAMILY": "Family",
    "LOGIN_SCHOOL": "Class",
    "LOGIN_WITH_SKOLON": "Sign in with Skolon",
    "LOGIN_ZCOOLY_PLAY": "Sign in to Zcooly",
    "LOGOUT_FROM_ZCOOLY_PLAY": "Sign out from Zcooly",
    "LOG_IN": "Log in",
    "LOG_IN_AS_CLASS": "Sign in",
    "LOG_IN_AS_FAMILY": "Sign in",
    "LOG_IN_WITH_APPLE": "Sign in with Apple",
    "LOG_IN_WITH_APPLE_SUBSCRIPTION": "Restore purchase with Apple subscription",
    "LONG_TRIAL_PAUSED": "You subscription is now paused.",
    "LOSS_OF_DATA_BY_CANCELING_TERMS": "I hereby confirm that I am aware of the fact that I will lose all progress my children have made in Zcooly and all other information at Zcooly within a year.",
    "MONTH": "Month",
    "MONTHS": "Months",
    "MONTH_APRIL": "April",
    "MONTH_AUGUST": "August",
    "MONTH_DECEMBER": "December",
    "MONTH_FEBRUARY": "February",
    "MONTH_JANUARY": "January",
    "MONTH_JULY": "July",
    "MONTH_JUNE": "June",
    "MONTH_MARCH": "March",
    "MONTH_MAY": "May",
    "MONTH_NOVEMBER": "November",
    "MONTH_OCTOBER": "October",
    "MONTH_SEPTEMBER": "September",
    "MOST_POPULAR": "Most popular",
    "NO": "No",
    "NOTICE_APPLE_LOGIN_CANCELLED": "Sign in with Apple was canceled.",
    "NOTICE_CHILD_UNPAID_FOR": "In order to play with this child, you need to upgrade your subscription.",
    "NOTICE_IOS_OAUTH_NO_ACCOUNT": "You can not sign in with your Apple account because it is not activated. Try creating an Apple Subscription instead, and you will be up and running in no time.",
    "NOTICE_NO_APPLE_SUBSCRIPTION": "You don't seem to have an active Apple subscription. Sign in with a Zcooly account or click on \"I want to create an account\".",
    "NOTICE_OAUTH_ALREADY_REGISTERED": "You already have an account for {email} - Try to sign in to that account instead.",
    "NOTICE_OAUTH_LOGIN_FAILED": "Sign in failed",
    "NOTICE_SKOLON_ERROR": "You failed to sign in with Skolon. Either you have denied Zcooly access or you don't have access to this product. If you need help, please contact us at <a href=\"mailto:support%40zcooly.com\">support&#64;zcooly.com</a>",
    "NOTICE_TITLE_APPLE_LOGIN_CANCELLED": "Apple sign in failed",
    "NOTICE_TITLE_CHILD_UNPAID_FOR": "This child user is locked",
    "NOTICE_TITLE_NO_APPLE_SUBSCRIPTION": "No apple subscription",
    "NOTICE_TITLE_SKOLON_ERROR": "Skolon sign in failed",
    "NOT_ALREADY_MEMBER": "Don't have a Zcooly account?",
    "OK": "OK",
    "OKAY": "Okay",
    "ON_ANOTHER_DEVICE": "Play on another device",
    "ON_THIS_DEVICE": "Play on this device",
    "OR": "Or",
    "PACKAGE_AND_SUBSCRIPTION": "Package and subscription",
    "PARENT_GATE_CLOSE": "Enter the year of your birth",
    "PARENT_GATE_ERROR_TEXT": "You have to be an adult in order to register. Enter the year of your birth to confirm you are over the age of 18.",
    "PARENT_GATE_ERROR_TITLE": "You are not old enough",
    "PASSWORD": "Password",
    "PAUSE_BETWEEN_X_AND_Y": "Between {from} and {to}",
    "PAUSE_OPTION_EASTER_SIMPLE": "I want to pause my subscription until easter",
    "PAUSE_OPTION_HALLOWEEN_SIMPLE": "I want to pause my subscription until halloween",
    "PAUSE_OPTION_MIDSUMMER_SIMPLE": "I want to pause my subscription until midsummer",
    "PAUSE_OPTION_ONE_MONTH_SIMPLE": "I want to pause my subscription for one month",
    "PAUSE_OPTION_SCHOOL_START_SIMPLE": "I want to pause my subscription until the school starts",
    "PAUSE_OPTION_SIX_MONTHS_SIMPLE": "I want to pause my subscription for six months",
    "PAUSE_OPTION_TWO_MONTHS_SIMPLE": "I want to pause my subscription for two months",
    "PAUSE_OPTION_YEAR_OUT_SIMPLE": "I want to pause my subscription for the rest of the year",
    "PAUSE_OR_CANCEL_DESCRIPTION_CANCEL": "If you choose to cancel your subscription, you will be able to play for the remaining time you paid for, but when the period is over, no more money will be charged and you can no longer play the games.",
    "PAUSE_OR_CANCEL_DESCRIPTION_PAUSE": "If you choose to pause your subscription, no money will be charged during the period that the pause is active. You can start your subscription again whenever you want, or it will start automatically when the pause is over. The time you have paid for remains after the pause is over.",
    "PAUSE_OR_CANCEL_SUBSCRIPTION": "Pause or cancel subscription",
    "PAUSE_WAS_CREATED": "Pause successfully scheduled.",
    "PAYMENT_HELP_APPLE_INSTRUCTIONS": "If your subscription has failed to renew, you may need to check your subscription page in the App Store. An Apple subscription is an agreement between you and Apple, so Zcooly cannot handle it.",
    "PAYMENT_HELP_STRIPE_INSTRUCTIONS": "You may need to check that the credit card you are using is still valid and that you allow online payments. Our payment provider Stripe will try to collect the payment a few times after a failed payment, so you may have to wait a few days after making a change before the subscription becomes active again. In some cases, a button is also available on your subscription page that you can use to manually retry a payment.",
    "PAY_PER_MONTH": "Pay monthly",
    "PAY_PER_SIX_MONTHS": "Pay semi-annually",
    "PAY_PER_YEAR": "Pay annually",
    "PLAN_SUMMARY_BASE_PRICE": "Full price",
    "PLAN_SUMMARY_COMPARISON_MONTHLY_PRICE": "Comparison price per month",
    "PLAN_SUMMARY_DISCOUNTS": "Discounts",
    "PLAN_SUMMARY_MEMBERSHIP_LARGE": "Membership Big",
    "PLAN_SUMMARY_PLAN_DISCOUNT_12_MONTHS": "Billed per 12 months (discount)",
    "PLAN_SUMMARY_PLAN_DISCOUNT_6_MONTHS": "Billed per 6 months (discount)",
    "PLAN_SUMMARY_TOTAL_MONTHLY_PRICE": "Calculated monthly price",
    "PLAN_SUMMARY_TOTAL_PRICE": "Period cost for 1 month",
    "PLAN_SUMMARY_TOTAL_PRICE_12_MONTHS": "Period cost for 12 months",
    "PLAN_SUMMARY_TOTAL_PRICE_6_MONTHS": "Period cost for 6 months",
    "PRICE_PER_MONTH": "From {price}/month.",
    "PROGRESS_CREATING_ACCOUNT": "Creating account",
    "PROGRESS_SENDING_WELCOME_EMAIL": "Sending welcome email to your email address",
    "PROGRESS_STARTING_SUBSCRIPTION": "Starting trial",
    "PROMPT_PARENT_GATE_TEXT_2": "",
    "PROMPT_PARENT_GATE_TEXT_APP_ACCOUNT": "",
    "PROMPT_PARENT_GATE_TITLE": "To continue, enter an adult's year of birth.",
    "QUARTERLY": "Quarterly",
    "REGISTRATION_BIG_PACKAGE_USP1": "Up to 3 children.",
    "REGISTRATION_CONFIRM_GROUP_BUTTON": "Confirm",
    "REGISTRATION_CONFIRM_GROUP_LINK_ERROR": "There was a problem with your link. Please contact <a href=\"mailto:support%40zcooly.com\">support&#64;zcooly.com</a> and we will help you.",
    "REGISTRATION_CONFIRM_GROUP_MESSAGE": "Do you want to give {name} access to your class {group}?",
    "REGISTRATION_CONFIRM_GROUP_THANK_YOU": "You have now given {name} access to your class {group}!",
    "REGISTRATION_ERROR_COULD_NOT_LOAD_PLANS": "Could not load any available subscription plans.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP1": "Educational games for kids ages 5-12.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP2": "Over 300 playful math challenges.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP3": "Educational tips to your inbox every week.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP4": "Follow your children's progress.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP5": "Safe app for many devices, always without ads.",
    "REGISTRATION_FAMILY_USP1": "Educational games for kids ages 5-12 created by teachers.",
    "REGISTRATION_FAMILY_USP2": "Follow your child's progress.",
    "REGISTRATION_FAMILY_USP3": "Educational tips to your inbox every week.",
    "REGISTRATION_FAMILY_USP4": "Try free for {trialDays} days with no commitment period.",
    "REGISTRATION_FAMILY_USP5": "",
    "REGISTRATION_FAMILY_USP6": "",
    "REGISTRATION_INTRO_FAMILY_CAMPAIGN": "Enter your information and create an account. With your account your family will get access to a world filled with educational math games for your kids to explore. You get an obligation free trial, after that it's only from {price} a month.",
    "REGISTRATION_INTRO_FAMILY_INVITE_BY_FRIEND": "Enter your information and create an account. Get access to a world filled with educational math games for your kids to explore.",
    "REGISTRATION_NOTICE": "Enjoy a {trialDays}-day free trial. After, payments align with your chosen interval. Cancel anytime in settings. By selecting 'Start trial period', Stripe securely encrypts and stores your data.",
    "REGISTRATION_PLAN_EXPLANATION": "No money is withdrawn before the end of the trial period ({trialEndDate}). After the trial period, your chosen subscription plan automatically starts",
    "REGISTRATION_PROGRESS_HEADING": "Your membership is starting",
    "REGISTRATION_SMALL_PACKAGE_USP1": "1 child.",
    "REGISTRATION_TEACHER_BILLING_QUARTERLY": "It costs 900 SEK per quarter after the trial period.",
    "REGISTRATION_TEACHER_BILLING_YEARLY": "It costs 720 SEK per quarter after the trial period, and you pay per year.",
    "REGISTRATION_TITLE_CONFIRM_GROUP": "Confirm new teacher",
    "REGISTRATION_TITLE_FAMILY": "Make learning fun.",
    "REGISTRATION_TITLE_FAMILY_INVITE_BY_FRIEND": "Yay! Your friend has invited you to try Zcooly for free an entire month.",
    "REGISTRATION_TITLE_FAMILY_INVITE_BY_NAME": "Yay! {firstName}{lastName} has invited you to try Zcooly for free an entire month.",
    "RESTORE_APPLE_SUB_PURCHASE_TEXT": "This button is used for restoring a purchase of a Zcooly subscription made previously on another device but with the same iCloud account. Do you wish to continue?",
    "RESTORE_APPLE_SUB_PURCHASE_TEXT_FINE_PRINT": "If the procedure fails, this might be because you're not logged in to an iCloud account that previously purchased a Zcooly subscription. If you suspect an error has occurred, please get in touch with our support.",
    "RESTORE_APPLE_SUB_PURCHASE_TITLE": "Restore a previous Apple subscription purchase",
    "RETRY_CHARGE_ERROR_MESSAGE": "It was not possible to try to withdraw money again because we did not find any unpaid invoices.",
    "RETRY_CHARGE_ERROR_TITLE": "An error has occurred",
    "SELECT_SCHOOL_OR_PRESCHOOL": "Select a school or preschool",
    "SELECT_YOUR_CLASS": "Select your class",
    "SIGN_IN_WITH_ZCOOLY": "Sign in",
    "SITE_META_DESCRIPTION": "Play our educational games for children and young teens!",
    "SITE_META_IMAGE": "https://cdn.zcooly.com/images/share/zcooly-1200x600.png",
    "SITE_META_TITLE": "Zcooly",
    "START_TRIAL_PERIOD": "Start trial period",
    "STRIPE_REGISTRATION_NOTICE": "You try for free for {trialDays} days. After the trial period {price} will be withdrawn from your account every month. You may cancel your subscription at any time in your account settings.",
    "STUDENT": "Student",
    "STUDENT_OR_TEACHER_QUESTION": "Are you a student or a teacher?",
    "SUBSCRIPTION_FUTURE_PAUSES_TITLE": "Scheduled pauses",
    "SUBSCRIPTION_PLAN_DISCOUNT": "{discount} Discount",
    "SUBSCRIPTION_PLAN_MONTHLY_COST": "{amount} / month",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_BIG": "Total {amount} for Zcooly",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_BIG_YEARLY": "Total {amount} per year for Zcooly",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_SMALL": "Total {amount} for Zcooly Small",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_SMALL_YEARLY": "Total {amount} per year for Zcooly Small",
    "SUBSCRIPTION_SECTION_ACTIONS_TITLE": "Subscription actions",
    "SUBSCRIPTION_TERMS": "Subscription terms",
    "SUBSCRIPTION_TERMS_SHORT": "Your subscription startas after the trial period ({trialEndDate}). You can cancel at any time.",
    "SUBSCRIPTION_TYPE_TITLE": "Subscription type",
    "SUBSCRIPTION_TYPE_ZCOOLY_FAMILY": "Zcooly Family",
    "SUBSCRIPTION_WAS_PAUSED": "You subscription is now paused.",
    "SWITCH_SUBSCRIPTION_PLAN_NOTICE": "The new option you select will be activated after the current period you have already payed for. Any discounts you have will be applied to the new price.",
    "TEACHER": "Teacher",
    "TEACHER_BILLING_COMPLETE_HEADING": "Thank you for completing the registration!",
    "TEACHER_BILLING_COMPLETE_MESSAGE": "Wow, now you and your students can try Zcooly for free all school year! In the next few weeks, we will send inspiration with tips and guidance on how you can quickly and easily use Zcooly in your classroom.",
    "TEACHER_REGISTRATION_BILLING_DESCRIPTION": "Fill in the billing information below, and you and your students will receive Zcooly for free for the rest of the school year.",
    "TEACHER_REGISTRATION_FAILED_SUBMIT": "The registration of your teacher account failed.",
    "TEACHER_REGISTRATION_INCOMPLETE_MESSAGE": "You have completed the registration process and as soon as we have confirmed your registration information you will receive an email with information on how to get started.",
    "TEACHER_REGISTRATION_TITLE_SETT_BILLING": "To get a longer trial period!",
    "TEACHER_REGISTRATION_WAITING_FOR_TEACHER_CONFIRM_MESSAGE": "You have completed the registration process and as soon as the registered teacher has accepted your registration information you will receive an email with information on how to get started.",
    "THANK_YOU_FOR_YOUR_REGISTRATION": "Thank you for your registration!",
    "TOGGLE_HIDDEN_CONTENT_HIDE": "Hide the content",
    "TOGGLE_HIDDEN_CONTENT_SHOW": "Show the content",
    "TRIED_STARTING_OVERLAPPING_SUBSCRIPTION_PAUSE_ERROR_MESSAGE": "The pause could not be scheduled because it's overlapping another existing pause.",
    "TRIED_STARTING_SUBSCRIPTION_PAUSE_IN_PAST_ERROR_MESSAGE": "The pause could not be scheduled because the start or end date is in the past.",
    "TRY_FOR_FREE_FOR_X_DAYS": "Try the game for free for {days} days",
    "TRY_THE_GAME_FOR_X_DAYS": "Try the game for free for {days} days",
    "TRY_THE_GAME_UNTIL_FURTHER_NOTICE": "Free until further notice",
    "YEARLY_(DISCOUNT)": "Yearly (Save 20%)",
    "YES": "Yes",
    "YOUR_CHILD_CANT_ACCESS_GAMES_IF_CANCELED_OR_PAUSED": "Your children will not be able to use Zcooly if you choose to pause or cancel your subscription.",
    "YOUR_INVITATION_WAS_NOT_SENT_USER_ALREADY_WAS_INVITED": "The email you to sent an invite to is already registered.",
    "YOUR_INVITATION_WAS_SUCCESSFULLY_SENT": "Your invitation was sent!",
    "YOUR_MEMBERSHIP_HAS_CHANGED": "Your membership has changed.",
    "YOUR_TRIAL_HAS_STARTED": "Awesome, your trial period has begun!",
    "YOU_HAVE_THIS_PACKAGE_ALREADY": "You already have this membership",
    "ZCOOLY_PACKAGE_BIG_DESCRIPTION": "For the bigger family.",
    "ZCOOLY_PACKAGE_BIG_NAME_LONG": "Zcooly Big (Up to 3 children)",
    "ZCOOLY_PACKAGE_BIG_TITLE": "Zcooly Big",
    "ZCOOLY_PACKAGE_SMALL_DESCRIPTION": "For the smaller family.",
    "ZCOOLY_PACKAGE_SMALL_NAME_LONG": "Zcooly Small (one child)",
    "ZCOOLY_PACKAGE_SMALL_TITLE": "Zcooly Small"
}
