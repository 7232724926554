import Resource from "@/api/resource/resource";
import currency from "@/services/currency.service";

class Campaign extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return process.env.VUE_APP_API_ENDPOINT + "/api/open/v1/campaigns/:action/";
  }

  /**
   * Validate a campaign code
   *
   * @return {Promise<Campaign>}
   */
  static async validateCampaignCode(code) {
    const cacheKey = `validate_campaign_code_${code}`;

    if (String(code).length === 0) {
      return Promise.reject();
    }

    if (this.cacheHas(cacheKey)) {
      const cachedResponse = this.cacheGet(cacheKey);

      if (cachedResponse?.response?.status === 404) {
        return Promise.reject(cachedResponse);
      }

      return Promise.resolve(cachedResponse);
    }

    return new Promise((resolve, reject) => {
      this.create({ action: "validate-code" }, { code }).then(
        response => {
          this.cacheSet(cacheKey, response);

          resolve(response);
        },
        error => {
          if (error.response?.status === 404) {
            this.cacheSet(cacheKey, error);
          }

          reject(error);
        }
      );
    });
  }

  /**
   * Massage campaign data
   * @param {Campaign} campaignValidation
   * @returns {Campaign}
   */
  static transformIncoming(campaignValidation) {
    campaignValidation.campaign_meta = Object.assign(
      {},
      JSON.parse(campaignValidation.campaign_meta || "{}")
    );

    /* Formatted price for new subscription plan to be used when applying a campaign code */
    if (
      campaignValidation.subscription_plan &&
      campaignValidation.subscription_plan.id
    ) {
      campaignValidation.subscription_plan.formatted_price = currency.formatPrice(
        campaignValidation.subscription_plan.price,
        campaignValidation.subscription_plan.currency
      );
    }

    return campaignValidation;
  }
}

export default Campaign;
