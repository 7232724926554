import { merge } from "lodash";

export default {
  namespaced: true,
  state: () => ({
    verbose: false
  }),
  mutations: {
    set(state, data) {
      state = merge(state, data);
    }
  },
  actions: {},
  strict: process.env.VUE_APP_ENV !== "production"
};
