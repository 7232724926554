import * as dayjs from "dayjs";
import "dayjs/locale/sv";
import { configure } from "vee-validate";
import {
  localize,
  setLocale as setVeeValidateLocale
} from "@vee-validate/i18n";
import sv from "@vee-validate/i18n/dist/locale/sv.json";
//import { setLocale } from "yup";
//import { useI18n } from "vue-i18n";
require("dayjs/locale/sv");

export default function() {
  setDayjsLocales();
  setVeeValidateLocales();
  setYupLocales();
}

function setYupLocales() {
  /*const { t } = useI18n();
  setLocale({
    mixed: {
      default: t("FIELD_HAS_INVALID_VALUE"),
      required: t("FIELD_IS_REQUIRED")
    },
    string: {
      email: t("FIELD_IS_NOT_AN_EMAIL"),
      required: t("FIELD_IS_REQUIRED"),
      length: ({ length }) => t("FIELD_IS_TOO_LONG", { length }),
      min: ({ min }) => t("FIELD_NEEDS_HAVE_X_AMOUNT_OF_CHARS", { min })
    },
    // use functions to generate an error object that includes the value from the schema
    number: {
      min: ({ min }) => ({ key: "field_too_short", values: { min } }),
      max: ({ max }) => ({ key: "field_too_big", values: { max } })
    }
  });*/
}

function setVeeValidateLocales() {
  configure({
    generateMessage: localize({
      sv
    })
  });
  setVeeValidateLocale("sv");
}

function setDayjsLocales() {
  dayjs.locale("sv");

  /**
   * Bugfix for swedish locale in dayjs.
   */
  dayjs.updateLocale("sv", {
    ordinal: number => {
      const endingNumber = number % 10;
      const ordinal =
        (endingNumber === 1 || endingNumber === 2) &&
        number !== 11 &&
        number !== 12
          ? "a"
          : "e";

      return `[${number}${ordinal}]`;
    }
  });
}
