import { Service } from "@/services/base.service";
import axios from "axios";
import { setCookie, getCookie } from "@/utilities/helpers";
import store from "@/store/index";
import CookieConsentService from "@/services/cookie-consent.service";
import localeService from "@/services/locale.service";

class Currency extends Service {
  constructor() {
    if (Currency._instance) {
      return Currency._instance;
    }
    super();
    Currency._instance = this;
    // ... Your rest of the constructor code goes after this

    this.cookieName = "zcoolyCurrency";
    this.loadPromise = null;
    this.defaultCurrency = "";
    this.value = null;
    this.supportedCurrencies = [
      "SEK",
      "USD",
      "EUR",
      "GBP",
      "CAD",
      "AUD",
      "NZD"
    ];
  }

  getCurrency() {
    const _this = this;
    return new Promise(resolve => {
      if (_this.value) {
        resolve(_this.value);
      } else {
        if (_this.loadPromise === null) {
          _this.loadPromise = _this.loadCurrency();
        }

        _this.loadPromise.then(
          currency => {
            CookieConsentService.addChangeCallback(cookie => {
              if (cookie.level && cookie.level.indexOf("functional") !== -1) {
                setCookie(_this.cookieName, currency, 7);
              }
            });

            _this.value = currency;
            resolve(currency);
          },
          () => {
            resolve(_this.defaultCurrency);
          }
        );
      }
    });
  }

  loadCurrency() {
    if (!this.defaultCurrency) {
      this.defaultCurrency = store.state.global.isInternational ? "USD" : "SEK";
    }
    const _this = this;

    return new Promise(resolve => {
      if (
        store.state.global.query?.currency &&
        typeof store.state.global.query.currency === "string" &&
        store.state.global.query.currency.length === 3 &&
        this.supportedCurrencies.indexOf(
          store.state.global.query.currency.toUpperCase()
        ) !== -1
      ) {
        resolve(store.state.global.query.currency.toUpperCase());
      } else if (getCookie(_this.cookieName)) {
        resolve(getCookie(_this.cookieName));
      } else {
        axios.get("https://ipapi.co/currency").then(
          response => {
            if (response.status === 200 && response.data) {
              let visitorCurrency = response.data.toUpperCase();

              if (!this.supportedCurrencies.includes(visitorCurrency)) {
                visitorCurrency = this.defaultCurrency;
              }

              resolve(visitorCurrency);
            } else {
              resolve(_this.defaultCurrency);
            }
          },
          () => {
            resolve(_this.defaultCurrency);
          }
        );
      }
    });
  }

  formatPrice(price, currencyCode) {
    currencyCode = currencyCode.toUpperCase();
    price = parseFloat(price);

    if (currencyCode === "SEK") {
      price = Math.round(price);
    } else {
      price = price.toString();

      if (/^\d+$/.test(price)) {
        price += ".00";
      } else if (/^\d+\.\d$/.test(price)) {
        price += "0";
      }
    }

    let formatted = price;

    switch (currencyCode) {
      case "AUD":
        formatted = "A$" + price;
        break;
      case "CAD":
        formatted = "CA$" + price;
        break;
      case "EUR":
        formatted = "€" + price;
        break;
      case "GBP":
        formatted = "£" + price;
        break;
      case "NZD":
        formatted = "NZ$" + price;
        break;
      case "SEK":
        formatted =
          price +
          (localeService.getGlobalLocale() === "sv" ? "\u00A0kr" : "\u00A0SEK");
        break;
      case "USD":
        formatted = "$" + price;
        break;

      default:
        console.log("No formatting options for currency code");
        break;
    }

    return formatted;
  }
}

export default new Currency();
