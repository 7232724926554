<template>
  <div>
    <Form
      as=""
      v-slot="{ validate, handleSubmit, values, meta, isSubmitting }"
      :initial-values="initialValues"
      :validationSchema="rules"
      :validateOnMount="validateOnMount"
    >
      <form
        @submit.prevent="createSubmitHandler(validate, handleSubmit, values)"
        @change="onChange"
        novalidate
      >
        <slot
          :values="values"
          :pending="meta.pending"
          :valid="meta.valid"
          :isSubmitting="isSubmitting"
          :meta="meta"
          :validationResults="validationResults"
        ></slot>
      </form>
    </Form>
  </div>
</template>
<script>
import { scrollToFirstError } from "@/utilities/helpers";
import { Form } from "vee-validate";
import debug from "@/utilities/debug";

export default {
  name: "ZForm",
  components: { Form },
  emits: ["submit", "preSubmit"],
  data() {
    return {
      validationResults: {}
    };
  },
  props: {
    rules: {
      type: Object
    },
    initialValues: {
      type: Object,
      default: undefined
    },
    validateOnMount: {
      type: Boolean,
      default: false
    },
    leaveFormWarning: Boolean
  },
  methods: {
    /**
     * The handleSubmit function only runs if all fields validate correctly.
     * After that, the anonymous function inside runs.
     * @param {Function} validate
     * @param {Function} handleSubmit
     * @param {Proxy} values
     */
    createSubmitHandler(validate, handleSubmit, values) {
      validate().then(
        async validationResult => {
          this.validationResults = validationResult;
          scrollToFirstError(validationResult);
          await this.$emit("preSubmit");

          handleSubmit(() => {
            return new Promise((resolve, reject) => {
              if (this.leaveFormWarning) {
                this.$store.commit("ui/setChangedForm", false);
              }
              this.$emit("submit", { values, resolve, reject });
            });
          });
        },
        () => {
          debug.log("Validate failed");
        }
      );
    },
    onChange() {
      if (this.leaveFormWarning && !this.$store.state.ui.formIsChanged) {
        this.$store.commit("ui/setChangedForm", true);
      }
    }
  }
};
</script>
