<template>
  <div class="confetti">
    <div :class="{ active: fig1 }"></div>
    <div :class="{ active: fig2 }"></div>
    <div :class="{ active: fig3 }"></div>
    <div :class="{ active: fig4 }"></div>
    <div :class="{ active: fig5 }"></div>
  </div>
</template>

<script>
export default {
  name: "Confetti",
  data() {
    return {
      fig1: false,
      fig2: false,
      fig3: false,
      fig4: false,
      fig5: false
    };
  },
  mounted() {
    const timeouts = [1000, 600, 300, 2000, 1200];

    for (let i = 1; i <= 5; i++) {
      setTimeout(() => {
        this["fig" + i] = true;
      }, timeouts[i - 1]);
    }
  }
};
</script>
<style scoped lang="scss">
.confetti {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;

  div {
    background-image: url("https://cdn.zcooly.com/images/decoration/confetti.svg");
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0;
    width: 274px;
    height: 277px;
    clip-path: circle(50% at 50% 50%);
    transform-origin: center;
    transform: scale(0.05);
    will-change: transform, opacity;

    &.active {
      animation-name: confetti;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-out;
      opacity: 1;
    }

    &:nth-child(1) {
      top: 15vh;
      left: 80vw;
    }

    &:nth-child(2) {
      top: 30vh;
      left: 20vw;
    }

    &:nth-child(3) {
      top: 50vh;
      left: 60vw;
    }

    &:nth-child(4) {
      top: 65vh;
      left: 30vw;
    }

    &:nth-child(5) {
      top: 80vh;
      left: 80vw;
    }
  }
}

@keyframes confetti {
  from {
    transform: scale(0.05);
    opacity: 1;
  }
  to {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
