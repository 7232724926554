import axios from "axios";

let isLoadingActionsNeeded = false;

export default {
  namespaced: true,
  state: () => ({
    actionsNeeded: {
      isLoaded: false,
      count: 0,
      family: 0,
      settings: 0,
      subscription: 0
    },
    formIsChanged: false,
    appIsLoading: false,
    showFooter: true,
    routeDirection: "next",
    previousRouteTo: null
  }),
  mutations: {
    setActionsNeeded(state, responseData) {
      state.actionsNeeded.family = 0;
      state.actionsNeeded.settings = 0;
      state.actionsNeeded.subscription = 0;
      state.actionsNeeded.isLoaded = true;

      if (responseData) {
        state.actionsNeeded.count = responseData.count ? responseData.count : 0;

        if (responseData.actions) {
          responseData.actions.forEach(action => {
            switch (action) {
              case "Child first name missing":
              case "Child still has default name":
                state.actionsNeeded.family++;
                break;
              case "Email needs to be updated":
                state.actionsNeeded.settings++;
                break;
              case "Subscription is past due":
              case "Subscription outdated":
                state.actionsNeeded.subscription++;
                break;
            }
          });
        }
      }
    },
    /**
     * @param state
     * @param {Boolean} isChanged
     */
    setChangedForm(state, isChanged) {
      state.formIsChanged = !!isChanged;
    },
    /**
     * @param state
     * @param {String} direction
     */
    setRouteDirection(state, direction) {
      state.routeDirection =
        direction === "prev" || direction === "back" ? "prev" : "next";
    },
    /**
     * @param state
     * @param {Object} to
     */
    setPreviousRoute(state, to) {
      state.previousRouteTo = to;
    },
    /**
     * @param state
     * @param {Boolean} isLoading
     */
    setAppIsLoading(state, isLoading) {
      state.appIsLoading = !!isLoading;
    },
    /**
     * @param state
     */
    resetActionsNeeded(state) {
      state.actionsNeeded.count = 0;
      state.actionsNeeded.family = 0;
      state.actionsNeeded.settings = 0;
      state.actionsNeeded.subscription = 0;
      state.actionsNeeded.isLoaded = false;
    },
    toggleFooter(state, showFooter) {
      state.showFooter = showFooter;
    }
  },
  actions: {
    activate: ({ state, dispatch }) => {
      if (!state.actionsNeeded.isLoaded) {
        dispatch("reloadActionsNeeded");
      }
    },
    reloadActionsNeeded: ({ commit }) => {
      if (isLoadingActionsNeeded) {
        return;
      }

      isLoadingActionsNeeded = true;

      axios
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/api/open/v1/users/actions-needed/"
        )
        .then(
          response => {
            isLoadingActionsNeeded = false;
            commit("setActionsNeeded", response?.data);
          },
          () => {
            isLoadingActionsNeeded = false;
          }
        );
    }
  },
  modules: {},
  strict: process.env.VUE_APP_ENV !== "production"
};
