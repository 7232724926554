import { createI18n } from "vue-i18n";
import { queryToObject } from "@/utilities/helpers";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

/**
 * Compute the active language.
 * @return {string}
 */
function getLanguage() {
  const query = queryToObject(),
    queryStart = window.location.href.indexOf("?");

  let language = process.env.VUE_APP_I18N_LOCALE || "en";

  if (
    window.location.href.indexOf(".com") !== -1 &&
    (queryStart === -1 || queryStart > window.location.href.indexOf(".com"))
  ) {
    language = "en";
  } else if (
    window.location.href.indexOf(".se") !== -1 &&
    (queryStart === -1 || queryStart > window.location.href.indexOf(".se"))
  ) {
    language = "sv";
  }

  if (query.lang || query.language) {
    let queryLanguage = query.lang || query.language;

    queryLanguage = queryLanguage.toLowerCase().substring(0, 2);

    if (["en", "sv"].indexOf(queryLanguage) !== -1) {
      language = queryLanguage;
    }
  }

  return language;
}

export { getLanguage };

export default createI18n({
  legacy: true,
  globalInjection: true,
  locale: getLanguage(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "sv",
  messages: loadLocaleMessages()
});
