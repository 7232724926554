import { queryToObject } from "@/utilities/helpers";
import { getLanguage } from "@/i18n";

const createUUID = function() {
  return Math.random()
    .toString(36)
    .substring(2, 9);
};

// https://51degrees.com/blog/missing-ipad-tablet-web-traffic
function getReportedRenderer() {
  let canvas = document.createElement("canvas");
  if (canvas !== null) {
    let context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (context) {
      let info = context.getExtension("WEBGL_debug_renderer_info");
      if (info) {
        return context.getParameter(info.UNMASKED_RENDERER_WEBGL);
      }
    }
  }
}

function activate(state, search) {
  let n = {};

  const renderer = getReportedRenderer();

  n.originalSearchQuery = window.location.search;
  /* Check if the variable exists in the query params */
  if ("isCypress" in search) {
    n.isCypress = search.isCypress !== "false";
  }

  /* Checks if variable is set and has a truthy value */
  if (search.source) {
    n.source = search.source;
    n.isApp = search.source.indexOf("app") !== -1;
  }

  if (search.version) {
    n.appVersion = search.version;
    // $location.search("version", null);
  }

  if (search.game) {
    n.game = search.game;
  } else if (search.gameTag) {
    n.game = search.gameTag;
  }

  if ("wsa" in search) {
    n.platform = "wsa";
    n.isWSA = true;
    // Spoof an ios device for e2e tests
  } else if ("isIOS" in search) {
    n.platform = "ios:iphone:spoofed";
    n.isIOS = true;
  } else if ("isAndroid" in search) {
    n.platform = "android:spoofed";
    n.isAndroid = true;
  } else if (window.navigator && window.navigator.userAgent) {
    if (window.navigator.userAgent.indexOf("iPhone") !== -1) {
      n.platform = "ios:iphone";
      n.isIOS = true;
    } else if (window.navigator.userAgent.indexOf("iPad") !== -1) {
      n.platform = "ios:ipad";
      n.isIOS = true;
    } else if (
      window.navigator.userAgent.toLowerCase().indexOf("macintosh") !== -1 &&
      renderer.includes("Apple")
    ) {
      n.platform = "ios:ipad";
      n.isNewIpad = true;
      n.isIOS = true;
    } else if (window.navigator.userAgent.indexOf("iPod") !== -1) {
      n.platform = "ios:ipod";
      n.isIOS = true;
    } else if (window.navigator.userAgent.indexOf("Android") !== -1) {
      n.platform = "android";
      n.isAndroid = true;
    } else {
      n.isDesktop = true;
    }
  } else {
    n.isDesktop = true;
  }

  n.userAgent = window.navigator.userAgent;

  n.isWeb = !n.isApp;

  if ("inAppSubscription" in search) {
    n.inAppSubscription = true;
    n.isWeb = false;
    n.isApp = true;
    if (n.source === "web") {
      n.source = "app";
    }
  }

  const queryStart = window.location.href.indexOf("?");

  if (
    !(
      window.location.href.indexOf(".se") !== -1 &&
      (queryStart === -1 || queryStart > window.location.href.indexOf(".se"))
    )
  ) {
    n.isInternational = true;
  }

  if (window.location.href.indexOf("stage") !== -1) {
    n.site = "stage";
  } else if (window.location.href.indexOf("login.zcooly") !== -1) {
    n.site = "production";
  } else {
    n.site = "local";
  }

  if (n.site === "local") {
    n.isInternational = false;
  }

  if ("swedish" in search) {
    n.isInternational = false;
  } else if ("english" in search) {
    n.isInternational = true;
  }

  if (n.isApp && !n.game) {
    n.game = "aio";
  }

  if (window.innerWidth < 600 || n.isIOS || n.isAndroid || n.isWSA) {
    n.isMobile = true;
  }

  if (n.game) {
    n.source += ":" + n.game;
  }
  n.language = getLanguage();

  n.query = queryToObject();
  n.tags = [];

  if (search.utm_source) {
    n.tags.push("utm_source_" + search.utm_source);
  }
  if (search.utm_medium) {
    n.tags.push("utm_medium_" + search.utm_medium);
  }
  if (search.utm_campaign) {
    n.tags.push("utm_campaign_" + search.utm_campaign);
  }
  if (search.utm_content) {
    n.tags.push("utm_content_" + search.utm_content);
  }

  if (n.source?.length && n.source.indexOf(":") > -1) {
    n.source.split(":").forEach(function(tag) {
      n.tags.push(tag);
    });
  }

  if (search.no_auto_login) {
    n.appAutoLoginChild = false;
  }

  if (search.stripeRedirect) {
    if (
      search.stripeRedirect === "always" ||
      search.stripeRedirect === "if_required"
    ) {
      n.stripePaymentRedirect = search.stripeRedirect;
    }
  }

  if (search.localWebGame) {
    n.localWebGame = true;
  }

  Object.assign(state, n);
}

function set(state, values) {
  Object.assign(state, values);
}

export default {
  namespaced: true,
  state: () => ({
    site: "",
    query: {},
    uuid: createUUID(),
    createUUID: createUUID,
    source: "web",
    tags: [],
    game: null,
    platform: "desktop",
    isWeb: false,
    isIOS: false,
    isNewIpad: false,
    isAndroid: false,
    isWSA: false,
    isApp: false,
    isDesktop: false,
    isInternational: false,
    inAppSubscription: false,
    isInAppSettings:
      window.location.pathname &&
      window.location.pathname.indexOf("/app/account") === 0,
    isMobile: false,
    isAdmin: false,
    isCypress: Boolean(window.Cypress),
    appVersion: "1.0.0",
    isSafari:
      window.navigator.userAgent.indexOf("Safari") !== -1 &&
      window.navigator.userAgent.indexOf("Chrome") === -1,
    debug: {
      originalUrl: window.location.href
    },
    originalSearchQuery: "",
    language: "sv",
    appAutoLoginChild: true,
    renewingAppleSubscription: false,
    stripePaymentRedirect: "if_required",
    localWebGame: false
  }),
  mutations: {
    activate,
    set,
    /**
     * @param state
     * @param {Object} data
     */
    setDebugData(state, data) {
      state.debug = Object.assign(state.debug, data);
    }
  },
  actions: {},
  modules: {},
  strict: process.env.VUE_APP_ENV !== "production"
};
