/* globals dataLayer */
import { Service } from "@/services/base.service";
import debug from "@/utilities/debug";
import CookieConsentService from "@/services/cookie-consent.service";

class GTM extends Service {
  constructor() {
    if (GTM._instance) {
      return GTM._instance;
    }
    super();
    GTM._instance = this;

    this.tag = "GTM-N6NP2VL";

    if (process.env.VUE_APP_ENV === "staging") {
      this.tag = "GTM-KXSKWPP";
    }

    this.isLoaded = false;
    this.eventsBeforeLoaded = [];
  }

  /**
   * Load the Google Tag Manager script in production if not already loaded.
   */
  load() {
    if (
      (process.env.VUE_APP_ENV === "production" ||
        process.env.VUE_APP_ENV === "staging") &&
      !this.isLoaded
    ) {
      const eventsBeforeLoaded = this.eventsBeforeLoaded;

      this.isLoaded = true;

      let gtmScriptUrl = "https://www.googletagmanager.com/gtm.js";

      if (process.env.VUE_APP_ENV === "staging") {
        gtmScriptUrl = "https://metrics.zcooly.com/gtm.js";
      }

      window.dataLayer = window.dataLayer || eventsBeforeLoaded;
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js"
      });

      window.gtag = function() {
        if (typeof window.dataLayer !== "undefined") {
          window.dataLayer.push(arguments);
        }
      };

      window.gtag("consent", "default", {
        ad_user_data: "denied",
        ad_personalization: "denied",
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied"
      });

      CookieConsentService.addChangeCallback(cookie => {
        window.gtag("consent", "update", {
          ad_user_data:
            cookie.level && cookie.level.indexOf("marketing") !== -1
              ? "granted"
              : "denied",
          ad_personalization:
            cookie.level && cookie.level.indexOf("marketing") !== -1
              ? "granted"
              : "denied",
          ad_storage:
            cookie.level && cookie.level.indexOf("marketing") !== -1
              ? "granted"
              : "denied",
          analytics_storage:
            cookie.level && cookie.level.indexOf("analytics") !== -1
              ? "granted"
              : "denied",
          functionality_storage:
            cookie.level && cookie.level.indexOf("functional") !== -1
              ? "granted"
              : "denied"
        });

        (function(w, d, s, l, i) {
          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = gtmScriptUrl + "?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", this.tag);
      });
    }
  }

  _push(event) {
    if (
      (process.env.VUE_APP_ENV === "production" ||
        process.env.VUE_APP_ENV === "staging") &&
      typeof dataLayer !== "undefined" &&
      this.isLoaded
    ) {
      dataLayer.push(event);
    } else {
      this.eventsBeforeLoaded.push(event);
    }
  }

  /**
   * Push a new page view to GTM.
   */
  pageView() {
    this._push({ event: "pageview" });
    debug.info("GTM pageview");
  }

  /**
   * Push a new event to GTM.
   * @param {string} category
   * @param {string} event
   * @param {string} label
   * @param {string} value
   * @param {object} data
   */
  event(category, event, label, value, data = {}) {
    const payload = {
      category,
      event,
      label,
      value
    };

    this._push(Object.assign(payload, data));

    debug.info("GTM event", {
      category,
      event,
      label,
      value,
      data
    });
  }
}

export default new GTM();
