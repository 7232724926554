import * as dayjs from "dayjs";
import * as localeData from "dayjs/plugin/localeData";
import * as duration from "dayjs/plugin/duration";
import * as relativeTime from "dayjs/plugin/relativeTime";
import * as localizedFormat from "dayjs/plugin/localizedFormat";
import * as objectSupport from "dayjs/plugin/objectSupport";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as updateLocale from "dayjs/plugin/updateLocale";

export default function() {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(localeData);
  dayjs.extend(localizedFormat);
  dayjs.extend(objectSupport);
  dayjs.extend(advancedFormat);
  dayjs.extend(updateLocale);
}
