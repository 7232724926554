import Resource from "@/api/resource/resource";
import { clone } from "lodash";

/**
 * @property {number} userID
 * @property {string} accessToken
 * @property {string} userType
 * @property {string} language
 */
class Registration extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return (
      process.env.VUE_APP_API_ENDPOINT + "/api/open/v1/registrations/:action/"
    );
  }

  /**
   * Send form data for extra parent completing their invitation and registering an account.
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async reportRegistrationStep(data) {
    return new Promise((resolve, reject) => {
      this.create({ action: "report-registration-step" }, data).then(user => {
        resolve(user);
      }, reject);
    });
  }

  /**
   * Validate an email address
   *
   * @param {string} email
   * @return {Promise<Resource>}
   */
  static async validateEmail(email) {
    const cacheKey = `validate_email_${email}`;

    if (String(email).length === 0) {
      return Promise.reject();
    }

    if (this.cacheHas(cacheKey)) {
      return Promise.resolve(this.cacheGet(cacheKey));
    }

    return new Promise((resolve, reject) => {
      this.create({ action: "validate-email" }, { email }).then(response => {
        this.cacheSet(cacheKey, response, 60);

        resolve(response);
      }, reject);
    });
  }

  static clearValidateEmailCache() {
    this.cacheDeletePattern(/^validate_email_/);
  }

  /**
   * Registering new teacher, or confirming new teacher to access your class.
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async registerTeacher(data) {
    const registrationData = clone(data);

    if (
      registrationData.school &&
      registrationData.school.startsWith("new-school-")
    ) {
      registrationData.school = registrationData.school.substring(11);
    }

    if (
      registrationData.group &&
      registrationData.group.startsWith("new-class-")
    ) {
      registrationData.group = registrationData.group.substring(10);
    }

    return new Promise((resolve, reject) => {
      this.create(
        { action: "register-swedish-teacher" },
        registrationData
      ).then(user => {
        resolve(user);
      }, reject);
    });
  }

  static async createTeacher(data) {
    const registrationData = clone(data);

    if (
      registrationData.school &&
      registrationData.school.startsWith("new-school-")
    ) {
      registrationData.school = registrationData.school.substring(11);
    }

    if (
      registrationData.group &&
      registrationData.group.startsWith("new-class-")
    ) {
      registrationData.group = registrationData.group.substring(10);
    }

    return new Promise((resolve, reject) => {
      this.create({ action: "create-teacher" }, registrationData).then(user => {
        resolve(user);
      }, reject);
    });
  }
}

export default Registration;
