<template>
  <Meta />
  <div class="mt-4 w-full flex items-center justify-center lg:mb-12">
    <img
      src="https://cdn.zcooly.com/images/logos/zcooly.png"
      width="256"
      height="69"
      alt="Zcooly"
      class="zcooly-logo"
    />
    <svg
      class="w-10 h-10 mx-2 fill-current pulsating-heart text-white"
      v-if="showBrandLogo"
    >
      <use xlink:href="#heart"></use>
    </svg>
    <img
      v-if="showBrandLogo"
      class="flex-shrink"
      :src="campaignData.brand_logo"
      :class="{
        'h-8': showBrandLogo,
        'h-12':
          !campaignData.campaign_meta.has_brand_meta || !campaignData.brand_logo
      }"
      :alt="campaignData.campaign_meta.brand_name"
      data-cy="campaign-brand-logo"
    />
  </div>
  <router-view></router-view>
</template>
<script>
import { mapState } from "vuex";
import Meta from "@/components/Meta";
export default {
  name: "Register",
  components: {
    Meta
  },
  computed: {
    ...mapState({
      campaignData: state => state.registration.campaignData,
      campaignDataIsLoading: state => state.registration.campaignDataIsLoading
    }),
    showBrandLogo() {
      return (
        this.campaignData?.campaign_meta?.has_brand_meta &&
        this.campaignData?.brand_logo
      );
    }
  }
};
</script>
