import Resource from "@/api/resource/resource";

let classLoadingPromises = {};

/**
 * @property {number} id
 * @property {string} name
 * @property {string} type
 * @property {string} license_expiry
 */
class Group extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return process.env.VUE_APP_API_ENDPOINT + "/api/open/v1/groups/:action/";
  }

  /**
   *
   * @return {Promise<Group[]>}
   */
  static getRegisteredGroups() {
    return new Promise((resolve, reject) => {
      if (this.cacheHas("registered-schools")) {
        resolve(this.cacheGet("registered-schools"));
        return;
      }

      this.list().then(list => {
        if (!list.length) {
          reject();
          return;
        }

        const registeredGroups = list.map(school => {
          return {
            value: school.id,
            label: school.name,
            subLabel: school.district
          };
        });

        this.cacheSet("registered-schools", registeredGroups, 3600);

        resolve(registeredGroups);
      }, reject);
    });
  }

  static getSchoolClasses(schoolId) {
    return new Promise((resolve, reject) => {
      const cacheKey = `classes_for_data_school_${schoolId}`;

      if (this.cacheHas(cacheKey)) {
        resolve(this.cacheGet(cacheKey));
        return;
      }

      if (!classLoadingPromises[schoolId]) {
        classLoadingPromises[schoolId] = this.list({
          data_school_id: schoolId
        });
      }

      classLoadingPromises[schoolId].then(list => {
        classLoadingPromises[schoolId] = false;

        if (!list.length) {
          resolve([]);
          return;
        }

        this.cacheSet(cacheKey, list, 3600);

        resolve(list);
      }, reject);
    });
  }

  static getRegisteredSchoolClasses(schoolId) {
    return new Promise((resolve, reject) => {
      const cacheKey = `classes_for_${schoolId}`;

      if (this.cacheHas(cacheKey)) {
        resolve(this.cacheGet(cacheKey));
        return;
      }

      this.list({ school_id: schoolId }).then(list => {
        if (!list.length) {
          resolve([]);
          return;
        }

        this.cacheSet(cacheKey, list, 3600);

        resolve(list);
      }, reject);
    });
  }
}

export default Group;
