import App from "../views/App/App.vue";
import { createRouter, createWebHistory } from "vue-router";
import GTM from "@/services/gtm.service";
import NotFound from "../views/NotFound.vue";
import Register from "../views/Register/Register.vue";
import store from "../store";
import Login from "../views/Login/Login.vue";
import auth from "@/utilities/auth";
import { openParentalGate, openPrompt } from "@/utilities/modal";
import debug from "@/utilities/debug";
import { scrollToY } from "@/utilities/helpers";

const routes = [
  {
    path: "/external-auth/:token",
    name: "ExternalAuth",
    component: () => import("../views/Account/Pages/ExternalAuth.vue")
  },
  {
    path: "/:form?/",
    component: Login,
    name: "start",
    meta: {
      guest: true
    }
  },
  {
    path: "/app/",
    component: App,
    meta: {
      guest: true
    },
    children: [
      {
        path: "apple-subscription/",
        name: "apple-splash",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "app-subscription" */ "../views/App/AppSubscriptionStart.vue"
          )
      },
      {
        path: "stripe-subscription/",
        name: "stripe-splash",
        component: () =>
          import(
            /* webpackChunkName: "stripe-subscription" */ "../views/App/AppSubscriptionStart.vue"
          )
      },
      {
        path: "apple-subscription/subscribe/",
        name: "apple-sub",
        component: () =>
          import(
            /* webpackChunkName: "apple-subscription" */ "../views/App/AppleSubscription/v1/AppleSubscription.vue"
          )
      },
      {
        path: "stripe-subscription/subscribe/",
        name: "stripe-sub",
        component: () =>
          import(
            /* webpackChunkName: "stripe-subscription" */ "../views/App/StripeSubscription/v1/StripeSubscription.vue"
          )
      },
      {
        path: "apple-subscription/children/",
        name: "apple-old-child-step",
        redirect: () => {
          return { name: "children" };
        }
      }
    ]
  },
  {
    path: "/register/",
    name: "register",
    meta: {
      guest: true
    },
    redirect: () => {
      return { path: "/register/parent/" };
    },
    component: Register,
    children: [
      {
        path: "parent/:campaignCode?/:invitationCode?",
        name: "register-family",
        component: () =>
          import(
            /* webpackChunkName: "register-family" */ "../views/Register/Family/v1/RegisterFamily.vue"
          )
      },
      {
        path: "parent/success/",
        name: "register-success",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "register-success" */ "../views/Register/Family/v2/RegistrationComplete.vue"
          )
      },
      {
        path: "parent/password/",
        name: "register-password",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "register-password" */ "../views/Register/Family/v2/PasswordStep.vue"
          )
      },
      {
        path: "parent/children/",
        name: "register-children",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "register-children" */ "../views/Register/Family/v2/ChildProfilesStep.vue"
          )
      },
      {
        path: "parent/child-levels/",
        name: "register-children-levels",
        meta: {
          requiresAuth: true
        },
        component: () =>
          import(
            /* webpackChunkName: "register-children-levels" */ "../views/Register/Family/v2/ChildLevelsStep.vue"
          )
      },
      {
        path: "accept-parent-invitation/",
        name: "accept-parent-invitation",
        component: () =>
          import(
            /* webpackChunkName: "accept-parent-invitation" */ "../views/Register/AcceptParentInvitation.vue"
          ),
        props: route => ({ queryData: route.query.data })
      },
      {
        path: "teacher/",
        name: "register-teacher",
        component: () =>
          import(
            /* webpackChunkName: "register-family" */ "../views/Register/RegisterTeacher.vue"
          )
      },
      {
        path: "sett/",
        name: "register-sett",
        component: () =>
          import(
            /* webpackChunkName: "register-sett" */ "../views/Register/RegisterSett.vue"
          )
      },
      {
        path: "complete-teacher-billing/",
        name: "complete-teacher-billing",
        meta: {
          requiresAuth: true,
          allowTeacher: true
        },
        component: () =>
          import(
            /* webpackChunkName: "complete-teacher-billing" */ "../views/Register/CompleteTeacherBilling.vue"
          ),
        props: route => ({ queryData: route.query.data })
      }
    ]
  },
  {
    path: "/children/",
    name: "children",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "children" */ "../views/Account/Children.vue")
  },
  {
    path: "/parent/",
    name: "account",
    meta: {
      requiresAuth: true,
      parentalGateApp: true
    },
    component: () =>
      import(/* webpackChunkName: "account" */ "../views/Account/Account.vue"),
    children: [
      {
        path: "/account/",
        name: "account-account-root",
        redirect: { name: "account-account" },
        component: () =>
          import(
            /* webpackChunkName: "sub-page-frame" */ "../views/Account/SubPageFrame.vue"
          ),
        children: [
          {
            path: "",
            name: "account-account",
            component: () =>
              import(
                /* webpackChunkName: "account-account" */ "../views/Account/Pages/Account.vue"
              )
          },
          {
            path: "settings/",
            name: "account-settings",
            component: () =>
              import(
                /* webpackChunkName: "account-settings" */ "../views/Account/Pages/Settings.vue"
              )
          },
          {
            path: "subscription/",
            name: "account-subscription",
            component: () =>
              import(
                /* webpackChunkName: "account-subscription" */ "../views/Account/Pages/Subscription.vue"
              )
          },
          {
            path: "family/",
            name: "account-family",
            component: () =>
              import(
                /* webpackChunkName: "account-family" */ "../views/Account/Pages/Family.vue"
              )
          },
          {
            path: "family/child/:childId/",
            name: "account-children-edit",
            component: () =>
              import(
                /* webpackChunkName: "account-children-edit" */ "../views/Account/Pages/ChildrenEdit.vue"
              )
          },
          {
            path: "invite/",
            name: "account-invite",
            component: () =>
              import(
                /* webpackChunkName: "account-invite" */ "../views/Account/Pages/Invite.vue"
              )
          },
          {
            path: "help/",
            name: "account-support",
            component: () =>
              import(
                /* webpackChunkName: "help" */ "../views/Account/Pages/Help.vue"
              )
          }
        ]
      },
      {
        path: "/learning/",
        name: "learning-root",
        redirect: { name: "learning" },
        component: () =>
          import(
            /* webpackChunkName: "sub-page-frame-card" */ "../views/Account/SubPageFrame.vue"
          ),
        children: [
          {
            path: "",
            name: "learning",
            component: () =>
              import(
                /* webpackChunkName: "learning" */ "../views/Account/Pages/Learning.vue"
              )
          },
          {
            path: ":type/:slug/",
            name: "article-page",
            props: true,
            meta: {
              requiresActiveAccount: true
            },
            component: () =>
              import(
                /* webpackChunkName: "article-page" */ "../views/Account/Pages/ArticlePage.vue"
              )
          },
          {
            path: ":type/:page(\\d+)?/",
            name: "article-list",
            props: true,
            meta: {
              requiresActiveAccount: true
            },
            component: () =>
              import(
                /* webpackChunkName: "article-list" */ "../views/Account/Pages/ArticleList.vue"
              )
          },
          {
            path: "news/:page(\\d+)?/",
            name: "article-news",
            props: true,
            meta: {
              requiresActiveAccount: true
            },
            component: () =>
              import(
                /* webpackChunkName: "article-news" */ "../views/Account/Pages/ArticleNews.vue"
              )
          }
        ]
      },
      {
        path: "/progress/",
        name: "progress-root",
        redirect: { name: "account-progress" },
        component: () =>
          import(
            /* webpackChunkName: "sub-page-frame" */ "../views/Account/SubPageFrame.vue"
          ),
        children: [
          {
            path: "",
            name: "account-progress",
            component: () =>
              import(
                /* webpackChunkName: "account-progress" */ "../views/Account/Pages/Progress.vue"
              )
          },
          {
            path: ":childId/subjects/",
            name: "account-progress-subjects",
            component: () =>
              import(
                /* webpackChunkName: "account-progress-subjects" */ "../views/Account/Pages/ProgressSubjects.vue"
              )
          },
          {
            path: ":childId/badges/",
            name: "account-progress-badges",
            component: () =>
              import(
                /* webpackChunkName: "account-progress-badges" */ "../views/Account/Pages/ProgressBadges.vue"
              )
          },
          {
            path: ":childId/subject/:subjectId/",
            name: "account-progress-subject-single",
            component: () =>
              import(
                /* webpackChunkName: "account-progress-subject-single" */ "../views/Account/Pages/ProgressSubjectSingle.vue"
              )
          },
          {
            path: ":childId/badge/:badgeId/",
            name: "account-progress-badge-single",
            component: () =>
              import(
                /* webpackChunkName: "account-progress-badge-single" */ "../views/Account/Pages/ProgressBadgeSingle.vue"
              )
          }
        ]
      }
    ]
  },
  {
    path: "/game/",
    name: "game",
    props: true,
    component: () => import(/* webpackChunkName: "game" */ "../views/Game")
  },

  /**
   * Landing pages
   */
  {
    path: "/reset-password/",
    name: "landing-reset-password",
    component: () =>
      import(
        /* webpackChunkName: "landing-reset-password" */ "../views/Landing/ResetPassword"
      )
  },
  {
    path: "/bjud-in/",
    name: "landing-invitation-campaign",
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "landing-invitation-campaign" */ "../views/Landing/InvitationCampaign"
      )
  },
  {
    path: "/register/confirm-class/",
    name: "landing-confirm-teacher",
    meta: {
      guest: true
    },
    component: () =>
      import(
        /* webpackChunkName: "landing-confirm-teacher" */ "../views/Landing/ConfirmTeacher"
      )
  },

  /**
   * Redirects for old routes.
   */
  {
    path: "/account/progress/",
    redirect: { name: "account-progress" }
  },
  {
    path: "/account/badges/:childId/",
    redirect: to => {
      return {
        name: "account-progress-badges",
        params: { childId: to.params.childId }
      };
    }
  },
  {
    path: "/account/badges/",
    redirect: { name: "account-progress" }
  },
  {
    path: "/app/account/:action?/:subAction?/:id?/",
    redirect: to => {
      switch (to.params?.action) {
        case "subscription":
          return {
            name: "account-subscription"
          };
        case "settings":
          return {
            name: "account-settings"
          };
      }

      if (
        to.params?.action === "my-children" &&
        to.params?.subAction === "edit" &&
        to.params?.id
      ) {
        return {
          name: "account-children-edit",
          params: { childId: to.params.id }
        };
      }

      return {
        name: "account-account"
      };
    }
  },

  // will match everything and put it under `$route.params.pathMatch`
  { path: "/:pathMatch(.*)*", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

let routeHistory = [];

router.beforeEach((to, from, next) => {
  if (from.name?.length) {
    store.commit("ui/setPreviousRoute", {
      name: from.name,
      params: from.params,
      query: from.query,
      hash: from.hash
    });
  }

  if (
    routeHistory.length > 1 &&
    to.name === routeHistory[routeHistory.length - 2]
  ) {
    store.commit("ui/setRouteDirection", "prev");

    // Remove last page from history.
    routeHistory.splice(routeHistory.length - 1, 1);

    setTimeout(() => {
      next();
    }, 15);

    return;
  }

  store.commit("ui/setRouteDirection", "next");
  routeHistory.push(to.name);
  routeHistory = routeHistory.slice(-20);
  next();
});

/**
 * Halt the navigation if a form is changed and not saved.
 */
router.beforeEach((to, from, next) => {
  debug.reportNavigation(to);

  if (store.state.ui.formIsChanged) {
    store.dispatch("modal/confirm", { name: "leave-form" }).then(
      () => {
        store.commit("ui/setChangedForm", false);
        next();
      },
      () => {
        next(false);
      }
    );
  } else {
    next();
  }
});

/**
 * Check if a route requires the user to have an active account.
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresActiveAccount)) {
    store.dispatch("user/loadUser").then(
      () => {
        if (store.state.user?.user?.active !== true) {
          openPrompt("inactive-subscription-lock").then(
            () => {
              next();
            },
            () => {
              next({
                name: "account-subscription",
                query: to.query,
                hash: to.hash
              });
            }
          );
        } else {
          next();
        }
      },
      () => {
        next();
      }
    );
  } else {
    next();
  }
});

/**
 * Check if a parental gate is required for the new route.
 */
router.beforeEach((to, from, next) => {
  if (
    store.state.global.isApp &&
    to.matched.some(record => record.meta.parentalGateApp)
  ) {
    openParentalGate().then(
      () => {
        next();
      },
      () => {
        next(false);
      }
    );
  } else {
    next();
  }
});

/**
 * Conditional redirects.
 */
router.beforeEach((to, from, next) => {
  debug.verbose(`Navigating to ${to.name}`);

  /**
   *  Enforce trailing slashes.
   */
  if (!to.path.endsWith("/")) {
    next({ path: to.path + "/", query: to.query, hash: to.hash });
    return;
  }

  if (to.query.action === "logout") {
    delete to.query.action;
    store.dispatch("user/logout").then(
      () => {},
      () => {}
    );
    next({
      name: "start",
      query: to.query,
      hash: to.hash
    });
    return;
  }
  /**
   *  Require authentication for locked endpoints.
   */
  if (to.matched.some(record => record.meta.requiresAuth)) {
    /**
     * Redirecting account base to progress page by default on web, not app.
     */
    store.dispatch("user/loadUser").then(() => {
      /**
       * If user is not logged in.
       */
      if (!store.getters["user/isLoggedIn"]) {
        /**
         * Redirect to start.
         */
        next({
          name: "start",
          params: { nextUrl: to.fullPath }
        });
      } else {
        /**
         * Allow navigation if user is a parent.
         */
        if (store.state.user?.user?.user_type === "parent") {
          handleParentRedirects(to, from, next);
        } else {
          if (to.matched.some(record => record.meta.allowTeacher)) {
            next();
          } else {
            /**
             * Teachers and students should be redirected to the play domain.
             */
            auth.redirectToApp("onLoginTeacher", {
              user: {
                ...auth.generateOldUserObject(store.state.user?.user),
                groupID: store.state.user?.user?.groups?.length
                  ? store.state.user.user.groups[0].id
                  : null,
                schoolID: store.state.user?.user?.school?.id
              },
              loginType: store.state.user?.user?.user_type
            });
          }
        }
      }
    });
  } else if (to.matched.some(record => record.meta.guest)) {
    store.dispatch("user/loadUser").then(() => {
      if (!store.getters["user/isLoggedIn"]) {
        next();
      } else {
        next({ name: "children" });
      }
    });
  } else {
    next();
  }
});

const handleParentRedirects = (to, from, next) => {
  if (to.name === "account" && store.getters["user/isLoggedIn"]) {
    if (store.state?.user?.user?.active) {
      next({ name: "account-progress" });
    } else {
      next({ name: "account-subscription" });
    }
  } else {
    next();
  }
};

/**
 * Register a page view in Google Tag Manager if in production.
 */
router.afterEach(() => {
  GTM.pageView();

  scrollToY(0);
});

export default router;
