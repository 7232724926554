/**
 * Serializes an object of params into a URL query string.
 * @param {Object} object
 * @param {string|null} prefix The key for an nested array of values. It will mainly be
 * used when recursively running this function.
 * @return {string}
 */
export function serializeParams(object, prefix = null) {
  let string = [];

  for (let param in object) {
    if (Object.prototype.hasOwnProperty.call(object, param)) {
      let key = prefix ? prefix + "[" + param + "]" : param,
        value = object[param];

      string.push(
        value !== null && typeof value === "object"
          ? serializeParams(value, key)
          : encodeURIComponent(key) + "=" + encodeURIComponent(value)
      );
    }
  }

  return string.join("&");
}
