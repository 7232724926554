/**
 * @param {number} number
 * @return {string}
 */
import DebugBase from "@/models/DebugBase";

/**
 * @property {string} path
 * @property {string} name
 * @property {Date} time
 */
class DebugNavigation extends DebugBase {
  /**
   * @param {object} route
   */
  constructor(route) {
    super();

    this.path = route.fullPath || null;
    this.name = route.name || null;
  }
}

export default DebugNavigation;
