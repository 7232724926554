import { defineRule } from "vee-validate";
import Registrations from "@/api/v1/registrations";
import i18n from "@/i18n";
import store from "@/store";

const $t = i18n.global.t;

function registerGlobalValidators() {
  const resolves = [];
  const timeouts = [];
  const cachedResolves = [];

  const debounceEmailValidation = async value => {
    try {
      const response = await Registrations.validateEmail(value);
      if (!response.valid) {
        return $t("FIELD_ERROR_INVALID_EMAIL");
      }
      if (!response.available) {
        return $t("FIELD_ERROR_NOT_UNIQUE_EMAIL");
      }
    } catch (error) {
      return false;
    }

    return true;
  };

  const debounceCampaignCodeValidation = async value => {
    if (value === "") {
      store.commit("registration/resetCampaignCode");
      /**
       * Reset campaign data.
       */
      return true;
    }
    let response = {};
    try {
      response = await store.dispatch("registration/setCampaignCode", value);
    } catch (error) {
      response = error;
    }

    if (!response.success) {
      return $t("CAMPAIGN_CODE_IS_NOT_VALID");
    }
    if (!response.is_valid) {
      return $t("CAMPAIGN_CODE_IS_NOT_VALID");
    }
    return true;
  };

  defineRule("required", value => {
    if (!value || (typeof value === "string" && !value.length)) {
      return $t("FIELD_ERROR_REQUIRED");
    }

    return true;
  });

  defineRule("email", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        stringValue
      )
    ) {
      return $t("FIELD_ERROR_INVALID_EMAIL");
    }

    return true;
  });

  defineRule("numeric", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (!/^\d+$/.test(stringValue)) {
      return $t("FIELD_ERROR_INVALID_NUMBER");
    }

    return true;
  });

  defineRule("uniqueEmail", (value, [originalEmail], ctx) => {
    if (
      originalEmail &&
      originalEmail.length &&
      value.length &&
      value.toLowerCase() === originalEmail.toLowerCase()
    ) {
      return true;
    }

    const valueIsValidated =
      cachedResolves[ctx.field] &&
      Object.prototype.hasOwnProperty.call(cachedResolves[ctx.field], value);
    const debounceTimeout = valueIsValidated ? 0 : 500;
    const previousResolve = resolves[ctx.field];
    const promise = new Promise((resolve, reject) => {
      /**
       * Set the last resolve fn for the next cycle.
       */
      resolves[ctx.field] = resolve;

      /**
       * Cancel the last resolve function.
       */
      clearTimeout(timeouts[ctx.field]);

      /**
       * Schedule it to be run after a set time.
       */
      timeouts[ctx.field] = setTimeout(() => {
        debounceEmailValidation(value).then(response => {
          if (!cachedResolves[ctx.field]) {
            cachedResolves[ctx.field] = {};
          }

          cachedResolves[ctx.field][value] = response;

          resolve(response);
        }, reject);
      }, debounceTimeout);
    });

    /* if there is a previous pending promise, force resolving it, by passing the newly created promise
     * since promises can resolve with other promises this means each previous validation attempt
     * will resolve with the same result as the last (most recent) validation attempt
     */
    if (previousResolve) {
      previousResolve(promise);
    }

    return promise;
  });

  defineRule("validCampaignCode", (value, params, ctx) => {
    const valueIsValidated =
      cachedResolves[ctx.field] &&
      Object.prototype.hasOwnProperty.call(cachedResolves[ctx.field], value);
    const debounceTimeout = valueIsValidated ? 0 : 500;
    const previousResolve = resolves[ctx.field];
    const promise = new Promise((resolve, reject) => {
      /**
       * Set the last resolve fn for the next cycle.
       */
      resolves[ctx.field] = resolve;

      /**
       * Cancel the last resolve function.
       */
      clearTimeout(timeouts[ctx.field]);

      /**
       * Schedule it to be run after a set time.
       */
      timeouts[ctx.field] = setTimeout(() => {
        debounceCampaignCodeValidation(value).then(response => {
          if (!cachedResolves[ctx.field]) {
            cachedResolves[ctx.field] = {};
          }

          cachedResolves[ctx.field][value] = response;

          resolve(response);
        }, reject);
      }, debounceTimeout);
    });

    /* if there is a previous pending promise, force resolving it, by passing the newly created promise
     * since promises can resolve with other promises this means each previous validation attempt
     * will resolve with the same result as the last (most recent) validation attempt
     */
    if (previousResolve) {
      previousResolve(promise);
    }

    return promise;
  });

  defineRule("name", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (!/^\p{L}+([ -]\p{L}+)*$/u.test(stringValue)) {
      return $t("FIELD_ERROR_INVALID_NAME");
    } else {
      return true;
    }
  });

  defineRule("tel", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (!/^[+\d\-\s]+$/.test(stringValue)) {
      return $t("FIELD_ERROR_INVALID_PHONE_NUMBER");
    } else {
      return true;
    }
  });

  defineRule("zip", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (!/^\d(\s?\d){4,}$/.test(stringValue)) {
      return $t("FIELD_ERROR_INVALID_ZIP_CODE");
    } else {
      return true;
    }
  });

  defineRule("password", value => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (stringValue.length < 6) {
      return $t("FIELD_ERROR_INVALID_PASSWORD");
    } else {
      return true;
    }
  });

  defineRule("minLength", (value, [limit]) => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (stringValue.length < limit) {
      return $t("FIELD_ERROR_TOO_SHORT", { limit: limit });
    }

    return true;
  });

  defineRule("maxLength", (value, [limit]) => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (stringValue.length > limit) {
      return $t("FIELD_ERROR_TOO_LONG", { limit: limit });
    }

    return true;
  });

  defineRule("length", (value, [min, max]) => {
    if (!value || !value.length) {
      return true;
    }

    const stringValue = String(value);
    if (stringValue.length < min) {
      return $t("FIELD_ERROR_TOO_SHORT", { limit: min });
    }

    if (stringValue.length > max) {
      return $t("FIELD_ERROR_TOO_LONG", { limit: max });
    }

    return true;
  });

  defineRule("min", (value, [min]) => {
    if (!value || !value.length) {
      return true;
    }
    const numericValue = Number(value);
    if (numericValue < min) {
      return $t("FIELD_ERROR_TOO_SMALL", { limit: min });
    }

    return true;
  });

  defineRule("max", (value, [max]) => {
    if (!value || !value.length) {
      return true;
    }
    const numericValue = Number(value);
    if (numericValue > max) {
      return $t("FIELD_ERROR_TOO_LARGE", { limit: max });
    }

    return true;
  });

  defineRule("minMax", (value, [min, max]) => {
    // The field is empty so it should pass
    if (!value || !value.length) {
      return true;
    }
    const numericValue = Number(value);
    if (numericValue < min) {
      return $t("FIELD_ERROR_TOO_SMALL", { limit: min });
    }

    if (numericValue > max) {
      return $t("FIELD_ERROR_TOO_LARGE", { limit: max });
    }

    return true;
  });

  defineRule("confirmed", (value, [target], ctx) => {
    if (!value || !value.length) {
      return true;
    }

    if (value === ctx.form[target]) {
      return true;
    }

    return $t("FIELD_ERROR_NOT_MATCHING");
  });

  defineRule("notSame", (value, [target, type], ctx) => {
    if (value !== ctx.form[target]) {
      return true;
    }

    if (type === "parentEmail") {
      return $t("FIELD_ERROR_CANNOT_MATCH_PARENT_EMAIL");
    } else {
      return $t("FIELD_ERROR_CANNOT_MATCH");
    }
  });
}

export default registerGlobalValidators;
