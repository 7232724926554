<template>
  <div class="input-container mb-4">
    <Field
      v-model="model"
      :name="name"
      :rules="rules"
      v-slot="{ field, meta, handleChange, handleInput, errorMessage }"
      :validate-on-mount="validateOnMount"
    >
      <span class="text-error text-sm" v-html="errorMessage" />
      <div v-if="visibilityToggle" class="relative">
        <span
          @click="toggleVisibility()"
          class="cursor-pointer absolute -top-9 right-4"
          :title="visibilityToggleTitle"
        >
          <svg v-if="visibilityToggled" class="w-6 h-6 fill-current">
            <use xlink:href="#invisible"></use>
          </svg>
          <svg v-if="!visibilityToggled" class="w-6 h-6 fill-current">
            <use xlink:href="#visible"></use>
          </svg>
        </span>
      </div>
      <input
        v-bind="field"
        :id="name"
        :type="inputType"
        v-on="yieldEventHandlers(handleChange, handleInput, errorMessage)"
        :class="{
          error: !!errorMessage,
          validated: !errorMessage && highlightValidated && !!modelValue,
          disabled: disabled
        }"
        :disabled="disabled"
        :placeholder="placeholderText"
        :autocomplete="autocomplete"
        autocorrect="off"
        autocapitalize="off"
        :pattern="pattern"
        v-focus[value]="focus"
      />
      <label :for="name" class="w-full">
        <span class="inline-flex flex-row flex-nowrap">
          <span class="mr-2 font-nunito-extrabold">{{ labelText }}</span>
          <span v-if="showNotification" class="notification-circle ml-1"
            >!</span
          >
          <span v-if="meta.pending"
            ><svg class="w-4 h-4 fill-current rotate">
              <use xlink:href="#spinner-blue"></use>
            </svg>
          </span>
        </span>
      </label>
    </Field>
  </div>
</template>
<script>
import { Field, useSubmitCount } from "vee-validate";

export default {
  name: "TextInput",
  components: { Field },
  props: {
    name: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    autocomplete: {
      type: String,
      default: undefined
    },
    pattern: {
      type: String,
      default: undefined
    },
    modelValue: { type: String },
    tLabel: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    tPlaceholder: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    rules: {
      type: [Object, String, Function],
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    highlightValidated: {
      type: Boolean,
      default: false
    },
    visibilityToggle: {
      type: Boolean,
      default: false
    },
    validateOnMount: {
      type: Boolean,
      default: false
    },
    showNotification: {
      type: Boolean,
      default: false
    },
    trim: {
      type: Boolean,
      default: true
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup() {
    const submitCount = useSubmitCount();

    return {
      submitCount
    };
  },
  data() {
    return {
      model: this.modelValue,
      visibilityToggled: false
    };
  },
  watch: {
    modelValue() {
      this.model = this.modelValue;
    }
  },
  computed: {
    inputType() {
      return this.visibilityToggled ? "text" : this.type;
    },
    visibilityToggleTitle() {
      return this.visibilityToggled
        ? this.$t("TOGGLE_HIDDEN_CONTENT_HIDE")
        : this.$t("TOGGLE_HIDDEN_CONTENT_SHOW");
    },
    labelText() {
      if (this.tLabel) {
        return this.$t(this.tLabel);
      } else if (this.label) {
        return this.label;
      }

      return "";
    },
    placeholderText() {
      if (this.tPlaceholder) {
        return this.$t(this.tPlaceholder);
      } else if (this.placeholder) {
        return this.placeholder;
      }

      return "";
    }
  },
  methods: {
    yieldEventHandlers(handleChange, handleInput, errorMessage) {
      const input = ev => {
        /**
         * Pass model value from Field component up to parent component.
         */
        this.$emit("update:modelValue", ev.target.value);
        handleInput(ev);
      };
      const change = ev => {
        /**
         * Pass model value from Field component up to parent component.
         */
        this.$emit("update:modelValue", ev.target.value);
        handleChange(ev);
      };
      const blur = ev => {
        if (this.trim) {
          ev.target.value = String(ev.target.value)
            .replaceAll(/(^[\s\r\n\t]+)|([\s\r\n\t]+$)/g, "")
            .replaceAll(/[\s]+/g, " ");
        }

        change(ev);
      };
      let lazy = {
        blur: blur,
        change: change,
        input: input
      };
      let aggressive = {
        blur: blur,
        change: change,
        input: change
      };
      return this.submitCount > 0 || errorMessage ? aggressive : lazy;
    },
    toggleVisibility() {
      this.visibilityToggled = !this.visibilityToggled;
    }
  }
};
</script>
