import lockScroll from "@/assets/libs/lock-scroll";
import debug from "../../utilities/debug";

export default {
  namespaced: true,
  state: () => ({
    modalStack: []
  }),
  getters: {
    showModal(state) {
      return !!state.modalStack.length;
    },
    topOfStack(state) {
      return state.modalStack[state.modalStack.length - 1];
    }
  },
  mutations: {
    pushModal(state, modal) {
      state.modalStack.push(modal);
      lockScroll(true, "#" + modal.selector);
      document.body.classList.add("lock-scroll");
      document.body.parentElement.classList.add("lock-scroll");
    },
    popModal(state, modal) {
      if (modal && state.modalStack.includes(modal)) {
        // Remove target modal from list
        state.modalStack.splice(state.modalStack.indexOf(modal), 1);
      } else {
        modal = state.modalStack.pop();
      }
      // move scroll focus to top most open modal
      lockScroll(false, "#" + modal.selector);

      if (state.modalStack.length) {
        lockScroll(
          true,
          "#" + state.modalStack[state.modalStack.length - 1].selector
        );
      } else {
        document.body.classList.remove("lock-scroll");
        document.body.parentElement.classList.remove("lock-scroll");
      }
    }
  },
  actions: {
    /**
     * Opens a new modal and return a promise which will be resolved or rejected
     * depending on the action taken in the modal.
     * @param commit
     * @param {object} data
     * @return {Promise<unknown>}
     */
    open({ commit }, data) {
      // lock scroll if not locked
      // mutate modalStack
      let modal = {};
      const promise = new Promise((resolve, reject) => {
        modal = {
          data,
          selector: "alert-" + data.name,
          disableOutsideClick: false,
          resolve,
          reject
        };
        debug.log("Opening modal: ", modal.data);
        commit("pushModal", modal);
      });
      /**
       * When user resolves or rejects, forward data to component and close modal
       **/
      return new Promise((resolve, reject) => {
        promise
          .then(
            response => {
              debug.log("Resolved: ", modal.data.name);
              resolve(response);
            },
            rejectionError => {
              debug.log("Rejected: ", modal.data.name);
              reject(rejectionError);
            }
          )
          .finally(() => {
            debug.log("Closing modal: ", modal.data);
            /* Timing fix for disable outside directive */
            setTimeout(() => {
              commit("popModal", modal);
            }, 10);
          });
      });
    },
    /**
     * Opens a new parental gate modal and return a promise which will be resolved or rejected
     * depending on the success of the challenge.
     * @param commit
     * @return {Promise<unknown>}
     */
    parentalGate({ commit }) {
      // lock scroll if not locked
      // mutate modalStack
      let modal = {};
      const promise = new Promise((resolve, reject) => {
        modal = {
          selector: "prompt-parental-gate",
          disableOutsideClick: true,
          resolve,
          reject
        };
        debug.log("Opening parental gate");
        commit("pushModal", modal);
      });
      /**
       * When user resolves or rejects, forward data to component and close modal
       **/
      return new Promise((resolve, reject) => {
        promise
          .then(
            response => {
              debug.log("Resolved parental gate");
              resolve(response);
            },
            rejectionError => {
              debug.log("Rejected parental gate");
              reject(rejectionError);
            }
          )
          .finally(() => {
            debug.log("Closing parental gate");
            /* Timing fix for disable outside directive */
            setTimeout(() => {
              commit("popModal", modal);
            }, 10);
          });
      });
    },
    /**
     * Opens a new confirm modal and return a promise which will be resolved or rejected
     * depending on if it's confirmed or not.
     * @param commit
     * @param {object} data
     * @return {Promise<unknown>}
     */
    confirm({ commit }, data) {
      // lock scroll if not locked
      // mutate modalStack
      let modal = {};
      const promise = new Promise((resolve, reject) => {
        modal = {
          data,
          selector: "confirm-" + data.name,
          disableOutsideClick: false,
          resolve,
          reject
        };
        debug.log("Opening confirm: ", modal.data);
        commit("pushModal", modal);
      });
      /**
       * When user resolves or rejects, forward data to component and close modal
       **/
      return new Promise((resolve, reject) => {
        promise
          .then(
            response => {
              debug.log("Resolved: ", modal.data.name);
              resolve(response);
            },
            rejectionError => {
              debug.log("Rejected: ", modal.data.name);
              reject(rejectionError);
            }
          )
          .finally(() => {
            debug.log("Closing confirm: ", modal.data);
            /* Timing fix for disable outside directive */
            setTimeout(() => {
              commit("popModal", modal);
            }, 10);
          });
      });
    },
    /**
     * Opens a new modal which will prompt the user to enter some information, and return a promise
     * which will be resolved or rejected depending on it the user fulfilled the prompt or not.
     * depending on the action taken in the modal.
     * @param commit
     * @param data
     * @return {Promise<unknown>}
     */
    prompt({ commit }, data) {
      // lock scroll if not locked
      // mutate modalStack
      let modal = {};

      const disableOutsideClick = data.disableOutsideClick || false;

      const promise = new Promise((resolve, reject) => {
        modal = {
          data,
          selector: "prompt-" + data.name,
          disableOutsideClick: disableOutsideClick,
          resolve,
          reject
        };
        debug.log("Opening prompt: ", modal.data);
        commit("pushModal", modal);
      });
      /**
       * When user resolves or rejects, forward data to component and close modal
       **/
      return new Promise((resolve, reject) => {
        promise
          .then(
            response => {
              debug.log("Resolved: ", modal.data.name);
              resolve(response);
            },
            rejectionError => {
              debug.log("Rejected: ", modal.data.name);
              reject(rejectionError);
            }
          )
          .finally(() => {
            debug.log("Closing prompt: ", modal.data);
            /* Timing fix for disable outside directive */
            setTimeout(() => {
              commit("popModal", modal);
            }, 10);
          });
      });
    }
  },
  strict: process.env.VUE_APP_ENV !== "production"
};
