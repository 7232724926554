import { createStore, createLogger } from "vuex";
import debug from "./modules/debug";
import global from "./modules/global";
import modal from "./modules/modal";
import registration from "./modules/registration";
import site from "./modules/site";
import ui from "./modules/ui";
import user from "./modules/user";

export default createStore({
  plugins: process.env.VUE_APP_ENV !== "production" ? [createLogger()] : [],
  devtools: true,
  modules: {
    debug,
    global,
    modal,
    registration,
    site,
    ui,
    user
  }
});
