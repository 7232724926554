export default {
  beforeMount(el) {
    let json = el.innerHTML
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
    el.innerHTML = json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
      function(match) {
        let cls = "format-number";
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = "format-key";
          } else {
            cls = "format-string";
          }
        } else if (/true|false/.test(match)) {
          cls = "format-boolean";
        } else if (/null/.test(match)) {
          cls = "format-null";
        }
        return '<span class="' + cls + '">' + match + "</span>";
      }
    );
  }
};
