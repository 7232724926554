<template>
  <div
    class="alert"
    :class="{ 'disabled-click-outside': this.modal?.disableOutsideClick }"
  >
    <transition name="slide" appear>
      <component
        v-click-outside="onClickOutside"
        v-show="initiated"
        :is="componentFile"
        :modal-data="modal"
      ></component>
    </transition>
  </div>
</template>

<script>
import string from "../utilities/string";
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";

export default {
  name: "Modal",
  data() {
    return {
      initiated: false
    };
  },
  computed: {
    ...mapGetters({
      topModal: "modal/topOfStack"
    }),
    componentFile() {
      const type = this.modal.selector.split("-", 2)[0],
        component = this.componentName;

      return defineAsyncComponent(() =>
        import(
          /* webpackChunkName: "[request]" */ `./modals/${type}/${component}.vue`
        )
      );
    },
    componentName() {
      return string
        .ucWords(
          this.modal.selector
            .split("-")
            .slice(1)
            .join(" ")
        )
        .replaceAll(" ", "");
    }
  },
  props: {
    modal: Object
  },
  created() {
    /**
     *  Trigger slide in animation
     */
    setTimeout(
      scope => {
        scope.initiated = true;
      },
      1,
      this
    );
  },
  methods: {
    onClickOutside() {
      /**
       *  Only register this if the modal is top of the stack
       */
      if (
        this.initiated &&
        this.topModal &&
        this.topModal.selector === this.modal.selector
      ) {
        if (
          typeof this.modal.reject === "function" &&
          !this.modal?.disableOutsideClick
        ) {
          this.modal.reject();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all 0.25s ease;
  transform: translateY(0px);
  opacity: 1;
  will-change: transform;
  z-index: 30;
}

// .slide-enter-to {
//   transition: all 0.25s ease;
//   transform: translateY(0px);
// }
// .slide-leave-from {
//   transition: all 0.25s ease;
//   transform: translateY(0px);
// }
.slide-leave-active {
  transition: all 0.25s ease;
  transform: translateY(0px);
  opacity: 1;
  will-change: transform;
  z-index: 30;
}

.slide-enter-from {
  transform: translateY(100px);
  opacity: 0;
  will-change: transform;
  z-index: 30;
}

.slide-leave-to {
  transform: translateY(100px);
  opacity: 0;
  will-change: transform;
  z-index: 30;
}
</style>
