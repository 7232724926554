<template>
  <div></div>
</template>
<script>
export default {
  name: "Meta",
  props: {
    title: String,
    description: String,
    image: String,
    lang: String,
    url: String
  },
  computed: {
    theTitle() {
      return this.title && this.title.length
        ? this.title + " - " + this.$t("SITE_META_TITLE")
        : this.$t("SITE_META_TITLE");
    },
    theDescription() {
      return this.description
        ? this.description
        : this.$t("SITE_META_DESCRIPTION");
    },
    theImage() {
      return this.image ? this.image : this.$t("SITE_META_IMAGE");
    },
    theLang() {
      return this.lang ? this.lang : this.$i18n.locale;
    },
    theUrl() {
      return this.url ? this.url : this.$route.fullPath;
    }
  },
  watch: {
    title() {
      this.setTitle();
    }
  },
  methods: {
    setTitle() {
      document.title = this.theTitle;

      document
        .querySelector('meta[name="title"]')
        .setAttribute("content", this.theTitle);
      document
        .querySelector('meta[itemprop="name"]')
        .setAttribute("content", this.theTitle);
      document
        .querySelector('meta[property="og:title"]')
        .setAttribute("content", this.theTitle);
      document
        .querySelector('meta[name="twitter:title"]')
        .setAttribute("content", this.theTitle);
      document
        .querySelector('meta[name="apple-mobile-web-app-title"]')
        .setAttribute("content", this.theTitle);
    }
  },
  mounted() {
    this.setTitle(this.theTitle);

    document
      .querySelector('meta[name="application-name"]')
      .setAttribute("content", this.$t("SITE_META_TITLE"));

    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.theDescription);
    document
      .querySelector('meta[itemprop="description"]')
      .setAttribute("content", this.theDescription);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", this.theDescription);
    document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute("content", this.theDescription);

    document
      .querySelector('meta[itemprop="image"]')
      .setAttribute("content", this.theImage);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", this.theImage);
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute("content", this.theImage);

    document.documentElement.setAttribute("lang", this.theLang);

    document
      .querySelector('meta[property="og:url"]')
      .setAttribute("content", this.theUrl);
    document
      .querySelector('meta[name="twitter:url"]')
      .setAttribute("content", this.theUrl);
  }
};
</script>
