<template>
  <div class="input-container mb-4">
    <Field
      v-model="model"
      :name="name"
      :rules="rules"
      v-slot="{ field, handleChange, handleInput, errorMessage }"
    >
      <span class="text-error text-sm" v-html="errorMessage" />
      <select
        :id="name"
        v-bind="field"
        :class="{ error: !!errorMessage, disabled: disabled }"
        :disabled="disabled"
        v-on="yieldEventHandlers(handleChange, handleInput, errorMessage)"
        v-if="!hasSlottedOptions"
        @focus="onFocus"
      >
        <option value="" :selected="modelValue === ''"
          >{{ placeholderText }}
        </option>
        <option
          v-for="(option, oid) in options"
          :key="oid"
          :value="option.value"
          :disabled="option.disabled"
          :selected="modelValue === option.value"
          >{{ option.label
          }}{{ option.subLabel ? " - " + option.subLabel : "" }}
        </option>
      </select>
      <select
        :id="name"
        v-bind="field"
        :class="{ error: !!errorMessage, disabled: disabled }"
        :disabled="disabled"
        v-on="yieldEventHandlers(handleChange, handleInput, errorMessage)"
        v-if="hasSlottedOptions"
        @focus="onFocus"
      >
        <slot></slot>
      </select>
    </Field>
    <label :for="name" class="inline-block w-full font-nunito-extrabold">
      {{ labelText }}
    </label>
  </div>
</template>
<script>
import { Field, useSubmitCount } from "vee-validate";

export default {
  name: "SelectInput",
  components: { Field },
  props: {
    name: {
      type: String,
      required: true
    },
    tLabel: {
      type: String,
      default: undefined
    },
    label: {
      type: String,
      default: undefined
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tPlaceholder: {
      type: String
    },
    placeholder: {
      type: String,
      default: undefined
    },
    tLoadingText: {
      type: String
    },
    loadingText: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    rules: {
      type: [Object, String, Function],
      default: undefined
    },
    modelValue: String
  },
  computed: {
    hasSlottedOptions() {
      return !!this.$slots.default;
    },
    labelText() {
      if (this.tLabel) {
        return this.$t(this.tLabel);
      } else if (this.label) {
        return this.label;
      }

      return "";
    },
    placeholderText() {
      if (this.isLoading) {
        if (this.tLoadingText) {
          return this.$t(this.tLoadingText);
        } else if (this.loadingText) {
          return this.loadingText;
        }
      }

      if (this.tPlaceholder) {
        return this.$t(this.tPlaceholder);
      } else if (this.placeholder) {
        return this.placeholder;
      }

      return "";
    }
  },
  emits: ["update:modelValue", "inputFocus"],
  setup() {
    const submitCount = useSubmitCount();
    return {
      submitCount
    };
  },
  data() {
    return {
      model: this.modelValue
    };
  },
  watch: {
    modelValue() {
      this.model = this.modelValue;
    }
  },
  methods: {
    yieldEventHandlers(handleChange, handleInput, errorMessage) {
      const input = ev => {
        /**
         * Pass model value from Field component up to parent component.
         */
        this.$emit("update:modelValue", ev?.target?.value);
        handleInput(ev?.target?.value);
      };
      const change = ev => {
        /**
         * Pass model value from Field component up to parent component.
         */
        this.$emit("update:modelValue", ev?.target?.value);
        handleChange(ev?.target?.value);
      };
      let lazy = {
        blur: change,
        change: change,
        input: input
      };
      let aggressive = {
        blur: change,
        change: change,
        input: change
      };
      return this.submitCount > 0 || errorMessage ? aggressive : lazy;
    },
    onFocus(event) {
      this.$emit("inputFocus", event.target);
    }
  }
};
</script>
