import store from "@/store/index";
import debug from "@/utilities/debug";
import semver from "semver";

/**
 * Opens an alert modal.
 * @param name
 * @param payload
 * @return {Promise<any>}
 */
export function openAlert(name, payload = {}) {
  return store.dispatch("modal/open", Object.assign({ name: name }, payload));
}

/**
 * Opens a confirm modal.
 * @param name
 * @param payload
 * @return {Promise<any>}
 */
export function openConfirm(name, payload = {}) {
  return store.dispatch(
    "modal/confirm",
    Object.assign({ name: name }, payload)
  );
}

/**
 * Opens a prompt modal.
 * @param name
 * @param payload
 * @return {Promise<any>}
 */
export function openPrompt(name, payload = {}) {
  return store.dispatch("modal/prompt", Object.assign({ name: name }, payload));
}

let lastSuccessfulParentalGateTime = 0;

/**
 * Opens a parental gate.
 *
 * The method contains a cached timer that saves a successful prompt for two minutes.
 * @return {Promise<any>}
 */
export function openParentalGate() {
  const now = new Date().getTime();

  return new Promise((resolve, reject) => {
    const diff = (now - lastSuccessfulParentalGateTime) / 1000;

    if (diff > 5 * 60) {
      debug.log("Parental gate cache is invalid.");

      store.dispatch("modal/parentalGate").then(() => {
        debug.log("Passed parental gate check.");

        lastSuccessfulParentalGateTime = new Date().getTime();

        resolve();
      }, reject);
    } else {
      debug.log(
        "Parental gate cache is still valid. It is only " +
          diff +
          " seconds old."
      );

      resolve();
    }
  });
}

export function handleAppTroubleAlert() {
  /**
   * Handle popup alerts for when an app has troubles, so we can notify the users of it.
   */
  if (
    process.env.VUE_APP_APP_TROUBLE &&
    process.env.VUE_APP_APP_TROUBLE_VERSION &&
    semver.satisfies(
      semver.coerce(store.state.global.appVersion),
      "<= " + process.env.VUE_APP_APP_TROUBLE_VERSION
    )
  ) {
    if (
      String(process.env.VUE_APP_APP_TROUBLE).toLowerCase() === "android" &&
      store.state.global.isAndroid &&
      !store.state.global.appTroubleAlertShown
    ) {
      openAlert("app-troubles-android").then(
        () => {},
        () => {}
      );
      store.state.global.appTroubleAlertShown = true;
    }
  }
}
