import DebugBase from "@/models/DebugBase";

/**
 * @property {string} method
 * @property {string} url
 * @property {number} status
 */
class DebugApiCall extends DebugBase {
  /**
   * @param {string} method
   * @param {string} url
   * @param {number} status
   */
  constructor(method, url, status) {
    super();

    this.method = method || null;
    this.url = url || null;
    this.status = status || null;
  }

  get formattedUrl() {
    return (
      "/" +
      this.url
        .split("/")
        .slice(3)
        .join("/")
    );
  }
}

export default DebugApiCall;
