<template>
  <div class="loading-bar" v-if="progress > 0">
    <div class="loading-bar__progress" :style="progressStyle"></div>
  </div>
</template>

<script>
import axios from "axios";

let loadingBarSmootheningInterval = null,
  finishedTimeout = null;

export default {
  name: "LoadingBar",
  data() {
    return {
      progress: 0.0
    };
  },
  computed: {
    progressStyle() {
      return {
        transform: "translate(" + this.progress + "%, 0)"
      };
    }
  },
  methods: {
    /**
     * Start the loader by showing the loading bar element and starting the loading progress
     * imitation to smooth out the loading.
     */
    start() {
      if (this.progress <= 0) {
        this.progress = 0.1;
      }

      if (finishedTimeout !== null) {
        clearTimeout(finishedTimeout);
        finishedTimeout = null;
      }

      if (loadingBarSmootheningInterval !== null) {
        clearInterval(loadingBarSmootheningInterval);
      }

      loadingBarSmootheningInterval = setInterval(() => {
        this.loadingBarSmoothening();
      }, 200);
    },
    /**
     * Finish the loading progress by setting the progress to 100% and waiting some time before hiding it.
     */
    done() {
      this.progress = 100.0;
      clearInterval(loadingBarSmootheningInterval);
      loadingBarSmootheningInterval = null;

      finishedTimeout = setTimeout(() => {
        this.progress = 0;
        finishedTimeout = null;
      }, 300);
    },
    /**
     * Increases the progress bar by a given amount of percent, or by a calculated amount
     * to smoothen the loading progress.
     * @param {Number|null} amount An amount of percent to increase the progress bar with.
     */
    increase(amount) {
      if (typeof amount !== "number") {
        if (this.progress >= 0 && this.progress < 20.0) {
          amount = 10.0;
        } else if (this.progress >= 20.0 && this.progress < 50.0) {
          amount = 4.0;
        } else if (this.progress >= 50.0 && this.progress < 80.0) {
          amount = 2.0;
        } else if (this.progress >= 80.0 && this.progress < 99.0) {
          amount = 0.5;
        } else {
          amount = 0;
        }

        if (this.progress + amount < 0) {
          this.progress = 0.0;
        } else if (this.progress > 99.5) {
          this.progress = 99.5;
        } else {
          this.progress += amount;
        }
      }
    },
    /**
     * Run loading progress smoothening.
     */
    loadingBarSmoothening() {
      this.increase(null);
    }
  },
  mounted() {
    const instance = axios;
    let requestsCounter = 0,
      batchSize = 0;

    instance.interceptors.request.use(config => {
      if (config?.progress === false) {
        config.onDownloadProgress = null;
        config.onUploadProgress = null;
      } else {
        requestsCounter++;
        batchSize++;

        this.start();
      }

      return config;
    });

    /**
     * Callback that runs on progress change for a download or upload. It calculates
     * the amount of percent the request progress change represents on the total
     * loading progress.
     * @param {ProgressEvent}  progressEvent
     */
    const onProgressChange = progressEvent => {
      const requestPercent =
          (Math.max(progressEvent.loaded, 1) /
            Math.max(progressEvent.total, 1)) *
          100,
        totalProgressImpact = requestPercent / Math.max(requestsCounter, 1);

      this.increase(totalProgressImpact);
    };

    /**
     * Calculate the approximately loaded progress for one or multiple concurrent requests.
     */
    const responseDoneCalcPercentage = () => {
      let leftPercent = Math.max(requestsCounter, 1) / Math.max(batchSize, 1);

      if (leftPercent < 1) {
        this.progress = (1 - leftPercent) * 100;
      }
    };

    /**
     * Intercept responses to handle a finished request.
     * @param {object} response
     * @return {object}
     */
    const responseInterceptor = response => {
      if (!response?.config?.progress) {
        requestsCounter--;
        if (requestsCounter === 0) {
          this.done();
          batchSize = 0;
        }

        responseDoneCalcPercentage();
      }

      return response;
    };

    /**
     * Intercept errors to handle a failed request.
     * @param {Error} error
     * @return {Promise<Error>}
     */
    const errorInterceptor = error => {
      if (!error?.config?.progress) {
        requestsCounter--;
        if (requestsCounter === 0) {
          this.done();
          batchSize = 0;
        }

        responseDoneCalcPercentage();
      }

      return Promise.reject(error);
    };

    instance.defaults.onDownloadProgress = onProgressChange;
    instance.defaults.onUploadProgress = onProgressChange;
    instance.interceptors.response.use(responseInterceptor, errorInterceptor);
  }
};
</script>
