import Resource from "@/api/resource/resource";

let citiesLoadingPromise = null,
  schoolLoadingPromises = {};

/**
 * @property {number} id
 * @property {string} name
 * @property {string} district
 */
class School extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return process.env.VUE_APP_API_ENDPOINT + "/api/open/v1/schools/:action/";
  }

  static getCities() {
    return new Promise((resolve, reject) => {
      if (this.cacheHas("cities")) {
        resolve(this.cacheGet("cities"));
        return;
      }

      if (citiesLoadingPromise === null) {
        citiesLoadingPromise = this.get({ action: "districts" });
      }

      citiesLoadingPromise.then(cities => {
        this.cacheSet("cities", cities, 3600);
        resolve(cities);

        citiesLoadingPromise = null;
      }, reject);
    });
  }

  static getCitySchools(city) {
    return new Promise((resolve, reject) => {
      const cacheKey = `schools_for_${city}`;

      if (this.cacheHas(cacheKey)) {
        resolve(this.cacheGet(cacheKey));
        return;
      }

      if (!schoolLoadingPromises[city]) {
        schoolLoadingPromises[city] = this.list({
          action: "district-schools",
          city: city
        });
      }

      schoolLoadingPromises[city].then(list => {
        schoolLoadingPromises[city] = false;

        if (!list.length) {
          resolve([]);
          return;
        }

        this.cacheSet(cacheKey, list, 3600);

        resolve(list);
      }, reject);
    });
  }

  /**
   *
   * @return {Promise<Object>}
   */
  static getRegisteredSchools() {
    return new Promise((resolve, reject) => {
      if (this.cacheHas("registered-schools")) {
        resolve(this.cacheGet("registered-schools"));
        return;
      }

      this.list().then(list => {
        if (!list.length) {
          reject();
          return;
        }

        const registeredSchools = list.map(school => {
          return {
            value: String(school.id),
            label: school.name,
            subLabel: school.district
          };
        });

        this.cacheSet("registered-schools", registeredSchools, 3600);

        resolve(registeredSchools);
      }, reject);
    });
  }
}

export default School;
