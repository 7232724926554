<template>
  <div :class="{ verbose: $store.state.debug.verbose }">
    <div
      class="page-wrapper flex w-full flex-col min-h-screen"
      id="page-wrapper"
    >
      <div class="page flex-1 relative">
        <router-view />
        <div class="hills-container">
          <div class="hills">
            <img
              class="hills-center cursor-default select-none max-w-xl lg:max-w-4xl"
              src="https://cdn.zcooly.com/images/decoration/hills.png"
              alt=""
              @click="debug()"
            />
          </div>
        </div>
        <Confetti v-if="this.$route.name === 'register-success'" />
      </div>

      <footer
        ref="footer"
        class="bg-ocean w-full flex-shrink-0"
        v-if="!global.isMobile && !global.isApp && $store.state.ui.showFooter"
      >
        <div
          class="container px-2 mx-auto flex text-white text-center justify-between sm:justify-center text-sm max-w-lg"
        >
          <div class="sm:w-1/6 py-4 sm:block font-nunito-extrabold">
            © {{ currentYear }} -
            <a
              :href="homeUrl"
              target="_blank"
              class="inline-block text-white underline hover:text-grayscale-lighter"
              >Zcooly AB</a
            >
          </div>
          <div class="sm:w-1/6 py-4 hidden sm:block font-nunito-extrabold">
            <a
              :href="contactUrl"
              target="_blank"
              class="inline-block text-white underline hover:text-grayscale-lighter"
              >{{ $t("CONTACT") }}</a
            >
          </div>
          <div class="flex items-center justify-center w-1/3">
            <a :href="facebookUrl" target="_blank">
              <img
                src="@/assets/images/footer/facebook.png"
                class="w-8 h-auto px-1 scale-up-on-hover"
                alt="Facebook"
              />
            </a>
            <a :href="twitterUrl" target="_blank">
              <img
                src="@/assets/images/footer/twitter.png"
                class="w-8 h-auto px-1 scale-up-on-hover"
                alt="Twitter"
              />
            </a>
            <a :href="youtubeUrl" target="_blank">
              <img
                src="@/assets/images/footer/youtube.png"
                class="w-8 h-auto px-1 scale-up-on-hover"
                alt="Youtube"
              />
            </a>
          </div>
          <div class="sm:w-1/6 py-4 hidden sm:block font-nunito-extrabold">
            <a
              @click="showCookieSettings"
              class="inline-block text-white underline hover:text-grayscale-lighter"
              >{{ $t("COOKIE_SETTINGS") }}</a
            >
          </div>
          <div class="sm:w-1/6 py-4 hidden sm:block font-nunito-extrabold">
            <a
              :href="aboutUrl"
              target="_blank"
              class="inline-block text-white underline hover:text-grayscale-lighter"
              >{{ $t("ABOUT") }}</a
            >
          </div>
        </div>
      </footer>
    </div>

    <LoadingBar />

    <!-- Modals -->
    <div id="alert-container" v-if="showModal">
      <Modal
        :id="modal.selector"
        v-for="modal in modalStack"
        :key="modal.selector"
        :modal="modal"
      >
      </Modal>
    </div>

    <Debug v-if="openDebug" @close="openDebug = false" />

    <div class="app-loader" v-if="showAppLoader">
      <img
        :src="require('@/assets/images/spinners/spinner.svg')"
        alt="Loading..."
      />
    </div>
  </div>
</template>

<script>
import AppFunctions from "@/services/app-functions.service";
import { mapGetters, mapState, mapMutations } from "vuex";
import Modal from "@/components/Modal.vue";
import defineSwedishLocales from "@/utilities/define-swedish-locales";
import extendDayJs from "@/utilities/extend-day-js";
import currency from "@/services/currency.service";
import GTM from "@/services/gtm.service";
import { openAlert } from "@/utilities/modal";
import { queryToObject } from "@/utilities/helpers";
import { loadSecret } from "@/utilities/secret";
import { defineAsyncComponent } from "vue";
import LoadingBar from "@/components/LoadingBar";
import Confetti from "@/components/Confetti";
import axios from "axios";
import CookieConsentService from "@/services/cookie-consent.service";
import debug from "@/utilities/debug";

const requireAll = requireContext => requireContext.keys().map(requireContext);
const req = require.context("./assets/svg-icons/", false, /\.svg$/);
requireAll(req);

const Debug = defineAsyncComponent(() =>
  import(/* webpackChunkName: "debug" */ "@/components/Debug")
);

export default {
  name: "AppWrapper",
  components: { Debug, Modal, LoadingBar, Confetti },
  data() {
    return {
      debugClicks: 0,
      currentYear: new Date().getFullYear(),
      openDebug: false
    };
  },
  beforeMount() {
    /**
     *  Set up stores
     */
    const queryObject = queryToObject();
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "global/activate") {
        const classes = {
          isIOS: state.global.isIOS,
          isAndroid: state.global.isAndroid,
          isSafari: state.global.isSafari,
          isNotDesktop: !state.global.isDesktop,
          isDesktop: state.global.isDesktop,
          isApp: state.global.isApp
        };
        Object.keys(classes).forEach(key => {
          if (classes[key]) {
            document.body.classList.add(key);
          }
        });
      }
    });
    this.initGlobal(queryObject);
    /**
     *  Adds plugin functionality to dayjs libs
     */
    extendDayJs();
    if (!this.$store.state.global.isInternational) {
      defineSwedishLocales();
    }

    /**
     * Loading the user's currency.
     */
    currency.getCurrency();

    /**
     * Load a user from either URL parameters or local storage.
     */
    this.$store.dispatch("user/loadUser", queryObject?.user);

    /**
     * Load Google Tag Manager if in production.
     */
    GTM.load();
  },
  computed: {
    ...mapState({
      modalStack: state => state.modal.modalStack,
      global: state => state.global,
      homeUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/"
          : "https://zcooly.se/",
      contactUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/help/"
          : "https://zcooly.se/hjalp/",
      facebookUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/go?https://www.facebook.com/zcoolysverige/"
          : "https://zcooly.se/go?https://www.facebook.com/zcoolysverige/",
      twitterUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/go?https://twitter.com/Zcooly"
          : "https://zcooly.se/go?https://twitter.com/Zcooly",
      youtubeUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/go?https://www.youtube.com/user/zcoolychannel"
          : "https://zcooly.se/go?https://www.youtube.com/user/zcoolychannel",
      aboutUrl: state =>
        state.global.isInternational
          ? "https://zcooly.com/about-zcooly/"
          : "https://zcooly.se/om-zcooly/",
      showAppLoader: state => state.ui.appIsLoading
    }),
    ...mapGetters({ showModal: "modal/showModal" })
  },
  methods: {
    ...mapMutations({
      initGlobal: "global/activate"
    }),
    defineSwedishLocales,
    debug() {
      if (this.debugClicks < 4) {
        this.debugClicks++;
        return false;
      }

      this.debugClicks = 0;
      this.openDebug = true;
      return true;
    },
    showCookieSettings() {
      CookieConsentService.showSettings();
    },
    /**
     * Adding/Removing body class that says the footer is shown.
     */
    handleFooter() {
      setTimeout(() => {
        if (
          !this.global.isMobile &&
          !this.global.isApp &&
          this.$store.state.ui.showFooter
        ) {
          document.body.classList.add("has-footer");
        } else {
          document.body.classList.remove("has-footer");
        }
      }, 20);
    }
  },
  mounted() {
    if (this.$store.state.global.isApp) {
      AppFunctions.init();
    }

    const query = queryToObject();

    if (typeof query.error === "string") {
      switch (query.error) {
        case "apple-login-cancelled":
          openAlert("apple-login-cancelled");
          break;
        case "oauth-already-registered":
          openAlert("oauth-already-registered", { email: query.email });
          break;
        case "oauth-login-failed":
          openAlert("oauth-login-failed");
          break;
        case "skolon-error":
          openAlert("skolon-error");
          break;
        case "ios-oauth-no-account":
          openAlert("ios-oauth-no-account");
          break;
      }
    }

    if (this.$store.state.global.isWeb) {
      loadSecret();
    }

    /**
     * Redirect normal internal links to be routed with vue router.
     */
    window.addEventListener("click", event => {
      let { target } = event,
        depth = 4;

      while (
        target &&
        target.tagName !== "A" &&
        target.parentNode &&
        depth > 0
      ) {
        target = target.parentNode;
        depth--;
      }

      if (
        target &&
        target.matches &&
        target.matches("a:not([href*='://'])") &&
        target.href
      ) {
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented
        } = event;

        /**
         * Don't handle with control keys.
         */
        if (metaKey || altKey || ctrlKey || shiftKey) {
          return;
        }

        /**
         * Don't handle if preventDefault was called.
         */
        if (defaultPrevented) {
          return;
        }

        /**
         * Don't handle right clicks.
         */
        if (button !== undefined && button !== 0) {
          return;
        }

        /**
         * Don't handle if target="_blank"
         */
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) {
            return;
          }
        }

        /**
         * Don't handle same page links/anchors.
         */
        const url = new URL(target.href),
          to = url.pathname,
          urlParams = {};

        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();

          if (url.search.length) {
            url.searchParams.forEach((value, key) => {
              urlParams[key] = value;
            });
          }

          this.$router.push({ path: to, query: urlParams });
        }
      }
    });

    const axiosInstance = axios;

    /**
     * Intercept responses to handle a finished request.
     * @param {object} response
     * @return {object}
     */
    const axiosResponseInterceptor = response => {
      if (
        response?.config?.url &&
        response?.config?.method &&
        response?.status
      ) {
        debug.reportApiCall(
          response.config.method,
          response.config.url,
          response.status
        );
      }

      return response;
    };

    /**
     * Intercept errors to handle a failed request.
     * @param {Error} error
     * @return {Promise<Error>}
     */
    const axiosErrorInterceptor = error => {
      if (
        error?.response?.config?.url &&
        error?.response?.config?.method &&
        error?.response?.status
      ) {
        debug.reportApiCall(
          error.response.config.method,
          error.response.config.url,
          error.response.status
        );
      }

      return Promise.reject(error);
    };

    axiosInstance.interceptors.response.use(
      axiosResponseInterceptor,
      axiosErrorInterceptor
    );

    if (
      !this.$store.state.global.isApp &&
      process.env.VUE_APP_ENV !== "local"
    ) {
      CookieConsentService.run();
    }

    document.addEventListener("fullscreenchange", event => {
      this.$event.trigger("fullscreen:change", event);
    });

    /**
     * Looking for a certain click sequence on the page for when to open the debug modal.
     *
     * You click in the corners of the window in the sequence below.
     *
     * tl = top left, tr = top right, bl = bottom left, br = bottom right.
     */
    const debugSequence = "tlbrtrbl";
    let debugSequenceClicked = "";

    document.addEventListener("click", event => {
      const clickAreaSize = 50;
      const isWithinLeft = event.clientX < clickAreaSize;
      const isWithinRight = event.clientX > window.innerWidth - clickAreaSize;
      const isWithinTop = event.clientY < clickAreaSize;
      const isWithinBottom = event.clientY > window.innerHeight - clickAreaSize;

      if (isWithinLeft && isWithinTop) {
        debugSequenceClicked += "tl";
      } else if (isWithinRight && isWithinTop) {
        debugSequenceClicked += "tr";
      } else if (isWithinLeft && isWithinBottom) {
        debugSequenceClicked += "bl";
      } else if (isWithinRight && isWithinBottom) {
        debugSequenceClicked += "br";
      } else {
        debugSequenceClicked = "";
      }

      if (
        debugSequenceClicked.length > 0 &&
        debugSequence.substring(0, debugSequenceClicked.length) !==
          debugSequenceClicked
      ) {
        debugSequenceClicked = "";
      }

      if (debugSequenceClicked === debugSequence) {
        this.openDebug = true;
        debugSequenceClicked = "";
      }
    });

    this.$event.on("footer:hide", this.handleFooter);
    this.$event.on("footer:show", this.handleFooter);
    this.handleFooter();
  }
};
</script>
<style scoped lang="scss">
.app-loader {
  @apply fixed flex items-center justify-center inset-0 z-10;
  background-color: rgba(0, 0, 0, 0.5);

  img {
    @apply w-32 h-32 animate-spin;
  }
}
</style>
