<template>
  <div class="login-window">
    <!-- Swedish family login form -->
    <h2 class="font-nunito-extrabold mb-2">
      {{ $t("LOG_IN_AS_FAMILY") }}
    </h2>
    <ZForm name="parentLoginForm" @submit="onLogin" class="mb-8">
      <div class="form-errors mb-2">
        <p class="error-message" v-if="loginParentError">
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          <span
            v-if="loginParentError === 'error'"
            data-cy="login-error-misc"
            >{{ $t("LOGIN_ERROR_MISC") }}</span
          >
          <span
            v-else-if="loginParentError === 'license-expired'"
            data-cy="login-error-expired"
            >{{ $t("LOGIN_ERROR_PARENT_LICENSE_EXPIRED") }}</span
          >
          <span
            v-else-if="loginParentError === 'credentials'"
            data-cy="login-error-credentials"
            >{{ $t("LOGIN_ERROR_CREDENTIALS") }}</span
          >
          <span
            v-else-if="loginParentError === 'teacher-in-app'"
            data-cy="login-error-teacher-in-app"
            >{{ $t("LOGIN_ERROR_TEACHER_IN_APP") }}</span
          >
          <span v-else data-cy="login-error-misc">{{
            $t("LOGIN_ERROR_MISC")
          }}</span>
        </p>
      </div>
      <TextInput
        type="email"
        v-model="login.email"
        rules="email|required"
        name="parent-email"
        t-label="EMAIL_ADDRESS"
        :disabled="isLoggingIn"
        data-cy="login-parent-email"
      />

      <TextInput
        type="password"
        v-model="login.password"
        rules="required"
        name="parent-password"
        t-label="PASSWORD"
        :disabled="isLoggingIn"
        visibility-toggle
        data-cy="login-parent-password"
      />

      <p class="forgot-password">
        <a
          @click="resetPassword"
          class="underline text-sm font-open-sans-bold cursor-pointer"
          data-cy="open-reset-password-button"
          >{{ $t("FORGOT_PASSWORD") }}</a
        >
      </p>
      <div class="button-container">
        <Button
          data-cy="login-parent-button"
          type="submit"
          class="btn btn--grass"
          :is-loading="isLoggingIn"
          :disabled="isLoggingIn"
          >{{ $t("LOGIN_ZCOOLY_PLAY") }}
        </Button>
      </div>
    </ZForm>

    <div class="registration-link text-sm text-center">
      <p class="mb-2">{{ $t("NOT_ALREADY_MEMBER") }}</p>
      <a
        @click.prevent="handleParentRegisterLink"
        class="underline font-open-sans-bold cursor-pointer"
        data-cy="parent-registration-link"
        >{{ $t("GET_ZCOOLY_PLAY") }}</a
      >
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button";
import ZForm from "@/components/form-parts/ZForm";
import TextInput from "@/components/form-parts/TextInput";
import { canUseAppleSubscription } from "@/utilities/feature-control";
import User from "@/api/v2/user";
import { openPrompt } from "@/utilities/modal";
import auth from "@/utilities/auth";

export default {
  name: "ParentLogin",
  components: { Button, ZForm, TextInput },
  data() {
    return {
      login: {
        email: "",
        password: ""
      },
      loginParentError: null,
      isLoggingIn: false
    };
  },
  methods: {
    handleParentRegisterLink() {
      if (
        this.$store.state.global.inAppSubscription &&
        canUseAppleSubscription() &&
        this.$store.state.global.isIOS
      ) {
        this.$router.push({ name: "apple-splash" });
      } else if (
        this.$store.state.global.isApp &&
        !this.$store.state.global.isIOS
      ) {
        this.$router.push({ name: "stripe-splash" });
      } else {
        this.$router.push({ name: "register-family" });
      }
    },
    async onLogin() {
      if (this.isLoggingIn) {
        return;
      }

      this.isLoggingIn = true;
      try {
        const user = await User.loginUser({
          email: this.login.email,
          password: this.login.password
        });

        if (user.user_type === "teacher") {
          if (this.$store.state.global.isApp) {
            this.loginParentError = "teacher-in-app";
          } else {
            auth.redirectToApp("onLoginTeacher", {
              user: auth.generateOldUserObject(user),
              loginType: "teacher"
            });
          }
        } else {
          this.$store.dispatch("user/setUser", {
            user: user,
            token: user.login.token,
            fromLogin: true
          });

          await this.$router.push({ name: "children" });
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          this.loginParentError = "credentials";
        } else if (error?.response?.status === 402) {
          this.loginParentError = "license-expired";
        } else {
          this.loginParentError = "error";
        }
      }

      this.isLoggingIn = false;
    },
    resetPassword() {
      openPrompt("reset-password", {
        email: this.login.email
      }).then(
        () => {},
        () => {}
      );
    }
  }
};
</script>
