import Resource from "@/api/resource/resource";

class Invitation extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return (
      process.env.VUE_APP_API_ENDPOINT + "/api/open/v1/invitations/:action/"
    );
  }

  /**
   * Validate a campaign code
   *
   * @return {Promise<unknown>}
   */
  static async getSender(sender) {
    return new Promise((resolve, reject) => {
      this.get({ action: "get-sender", sender }).then(response => {
        resolve(response);
      }, reject);
    });
  }
}

export default Invitation;
