let clickOriginatedInside = false;

export default {
  beforeMount(element, binding) {
    /**
     * Handling click events on the body.
     * @param {MouseEvent} event
     */
    element.clickOutsideEvent = event => {
      /**
       * Check if the element is the one being clicked. If it is, do nothing, if clicked
       * somewhere else, run callback.
       *
       * It also checks if the click was started inside or outside the element.
       */
      if (
        !(element === event.target || element.contains(event.target)) &&
        !clickOriginatedInside
      ) {
        binding.value();
      }
    };

    element.dragOrSelectHandler = event => {
      /**
       * Save if the start of the click originated from inside the element or started on the outside.
       * @type {boolean}
       */
      clickOriginatedInside =
        element === event.target || element.contains(event.target);
    };

    /**
     * Bind click handler on body when the element is created.
     */
    document.body.addEventListener("click", element.clickOutsideEvent);
    document.body.addEventListener("mousedown", element.dragOrSelectHandler);
    document.body.addEventListener("touchstart", element.dragOrSelectHandler);
  },
  unmounted(element) {
    /**
     * Remove click handler when element is removed.
     */
    document.body.removeEventListener("click", element.clickOutsideEvent);
    document.body.removeEventListener("mousedown", element.dragOrSelectHandler);
    document.body.removeEventListener(
      "touchstart",
      element.dragOrSelectHandler
    );
  }
};
