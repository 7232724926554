import { Service } from "@/services/base.service";
import "vanilla-cookieconsent/dist/cookieconsent.js";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import i18n from "@/i18n";

/**
 * Get the cookie domain from the current URL.
 *
 * @return {string} Domain name for the cookie.
 */
function getCookieDomain() {
  if (window.location.href.indexOf("zcooly.se") !== -1) {
    return ".zcooly.se";
  } else if (window.location.href.indexOf("zcooly.com") !== -1) {
    return ".zcooly.com";
  } else if (window.location.href.indexOf("local.se") !== -1) {
    return ".local.se";
  } else if (window.location.href.indexOf("local.com") !== -1) {
    return ".local.com";
  } else if (window.location.href.indexOf("127.0.0.1:8080") !== -1) {
    return ".127.0.0.1:8080";
  } else if (window.location.href.indexOf("127.0.0.1:8000") !== -1) {
    return ".127.0.0.1:8000";
  } else if (window.location.href.indexOf("127.0.0.1") !== -1) {
    return ".127.0.0.1";
  } else if (window.location.href.indexOf("localhost:8080") !== -1) {
    return ".localhost:8080";
  } else if (window.location.href.indexOf("localhost:8000") !== -1) {
    return ".localhost:8000";
  } else if (window.location.href.indexOf("localhost") !== -1) {
    return ".localhost";
  } else {
    return ".zcooly.com";
  }
}

const cookieDomain = getCookieDomain();

class CookieConsent extends Service {
  constructor() {
    if (CookieConsent._instance) {
      return CookieConsent._instance;
    }
    super();
    CookieConsent._instance = this;

    this.cc = window.initCookieConsent();
    this.changeCallbacks = [];
    this.isLoaded = false;
    this.loadedCookie = null;
  }

  /**
   * Add a callback that should be triggered when the cookie preferences are loaded or changed.
   * @param {function} callback
   */
  addChangeCallback(callback) {
    if (this.isLoaded) {
      callback.call(null, this.loadedCookie);
    }

    this.changeCallbacks.push(callback);
  }

  run() {
    this.cc.run({
      // ...
      force_consent: true,
      revision: 0,
      autorun: true,
      current_lang: i18n.global.locale,
      autoclear_cookies: true,
      cookie_domain: cookieDomain,
      cookie_name: "cookie_consent",
      gui_options: {
        consent_modal: {
          layout: "box", // box/cloud/bar
          position: "middle center", // bottom/middle/top + left/right/center
          transition: "slide", // zoom/slide
          swap_buttons: false // enable to invert buttons
        },
        settings_modal: {
          layout: "box",
          transition: "slide"
        }
      },
      onAccept: cookie => {
        console.log("Accepted", cookie);

        this.isLoaded = true;
        this.loadedCookie = cookie;

        this.changeCallbacks.forEach(callback => {
          callback.call(null, cookie);
        });
      },
      onChange: (cookie, changedPreferences) => {
        console.log("Changed", cookie, changedPreferences);

        this.loadedCookie = cookie;

        this.changeCallbacks.forEach(callback => {
          callback.call(null, cookie);
        });
      },
      languages: {
        en: {
          consent_modal: {
            title: "We use cookies",
            description:
              'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only upon approval. <a aria-label="Cookie policy" class="cc-link" href="https://zcooly.com/cookies/" target="_blank">Read more about how we use cookies</a><br><br>You can also read more about which cookies we store, and adjust settings per category.<br><a aria-label="Cookie settings" class="cc-link" data-cc="c-settings" target="_blank">Cookie settings</a>',
            primary_btn: {
              text: "Accept all",
              role: "accept_all"
            },
            secondary_btn: {
              text: "Deny all",
              role: "accept_necessary"
            }
          },
          settings_modal: {
            title: "Cookie preferences",
            save_settings_btn: "Save settings",
            accept_all_btn: "Accept all",
            reject_all_btn: "Reject all",
            cookie_table_headers: [
              //{ name: "Name },
              { displayName: "Name" },
              { vendor: "Vendor" },
              //{ domain: "Domain },
              { expiration: "Expiration" },
              { description: "Description" }
            ],
            blocks: [
              {
                title: "Cookie usage",
                description:
                  "We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want."
              },
              {
                title: "Strictly necessary cookies",
                description:
                  "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true
                },
                cookie_table: [
                  {
                    name: "cookie_consent",
                    displayName: "cookie_consent",
                    vendor: "Zcooly",
                    domain: cookieDomain,
                    expiration: "6 months",
                    description:
                      "This cookie saves the cookie consent settings you do here."
                  },
                  {
                    name: "__stripe_mid",
                    displayName: "__stripe_mid",
                    vendor: "Stripe",
                    domain: "stripe.com",
                    expiration: "1 year",
                    description: "To provide fraud prevention."
                  },
                  {
                    name: "__stripe_sid",
                    displayName: "__stripe_sid",
                    vendor: "Stripe",
                    domain: cookieDomain,
                    expiration: "1 day",
                    description: "To provide fraud prevention."
                  }
                ]
              },
              {
                title: "Functional",
                description:
                  "Used for certain functions in the service and to improve the performance by storing some calculated values.",
                toggle: {
                  value: "functional",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "zcoolyCurrency",
                    displayName: "zcoolyCurrency",
                    vendor: "Zcooly",
                    domain: cookieDomain,
                    expiration: "7 days",
                    description:
                      "Saved the currency that should be used for your subscription so it doesn't need to be fetched every time."
                  }
                ]
              },
              {
                title: "Analytics",
                description:
                  "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All the data is anonymized and cannot be used to identify you.",
                toggle: {
                  value: "analytics",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "^_ga",
                    displayName: "_ga",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 years",
                    description:
                      "We use Google Analytics to analyze our website traffic to improve user experience and understand our marketing efforts.",
                    is_regex: true
                  },
                  {
                    name: "^_gat",
                    displayName: "_gat",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "1 minute",
                    description: "Used to throttle request rate.",
                    is_regex: true
                  },
                  {
                    name: "_gid",
                    displayName: "_gid",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "1 day",
                    description: "Used to distinguish users."
                  },
                  {
                    name: "__utma",
                    displayName: "__utma",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 years",
                    description:
                      "Used to distinguish users and sessions. The cookie is created when the javascript library executes and no existing __utma cookies exists. The cookie is updated every time data is sent to Google Analytics."
                  },
                  {
                    name: "__utmb",
                    displayName: "__utmb",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "30 minutes",
                    description:
                      "Used to determine new sessions/visits. The cookie is created when the javascript library executes and no existing __utmb cookies exists. The cookie is updated every time data is sent to Google Analytics."
                  },
                  {
                    name: "__utmc",
                    displayName: "__utmc",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "End of browser session",
                    description:
                      "Not used in ga.js. Set for interoperability with urchin.js. Historically, this cookie operated in conjunction with the __utmb cookie to determine whether the user was in a new session/visit."
                  },
                  {
                    name: "^__utmt",
                    displayName: "__utmt",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "10 minutes",
                    description: "Used to throttle request rate.",
                    is_regex: true
                  },
                  {
                    name: "__utmv",
                    displayName: "__utmv",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 years",
                    description:
                      "Used to store visitor-level custom variable data. This cookie is created when a developer uses the _setCustomVar method with a visitor level custom variable. This cookie was also used for the deprecated _setVar method. The cookie is updated every time data is sent to Google Analytics."
                  },
                  {
                    name: "__utmz",
                    displayName: "__utmz",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "6 months",
                    description:
                      "Stores the traffic source or campaign that explains how the user reached your site. The cookie is created when the javascript library executes and is updated every time data is sent to Google Analytics."
                  }
                ]
              },
              {
                title: "Marketing",
                description:
                  "Used to measure some of our marketing campaigns and give kickback to certain partners.",
                toggle: {
                  value: "marketing",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "_fbp",
                    displayName: "_fbp",
                    vendor: "Facebook",
                    domain: cookieDomain,
                    expiration: "3 months",
                    description:
                      "The cookie identifies, for example, browsers for the purpose of providing advertising and website analysis services."
                  },
                  {
                    name: "_gcl_au",
                    displayName: "_gcl_au",
                    vendor: "Google",
                    domain: cookieDomain,
                    expiration: "3 months",
                    description:
                      "Google Adsense - to store and track conversions."
                  },
                  {
                    name: "_scid",
                    displayName: "_scid",
                    vendor: "Snapchat",
                    domain: "snapchat.com",
                    expiration: "1 year",
                    description:
                      "The cookie is used to help identify a visitor."
                  },
                  {
                    name: "_ttp",
                    displayName: "_ttp",
                    vendor: "TikTok",
                    domain: "tiktok.com",
                    expiration: "13 months",
                    description:
                      "We use the Tiktok pixel to understand how our ads on Tiktok perform."
                  },
                  {
                    name: "_uetsid",
                    displayName: "_uetvid",
                    vendor: "Bing",
                    domain: cookieDomain,
                    expiration: "1 day",
                    description:
                      "The cookie is used to track traffic coming from Bing to Zcooly."
                  },
                  {
                    name: "_uetvid",
                    displayName: "_uetvid",
                    vendor: "Bing",
                    domain: cookieDomain,
                    expiration: "1 month",
                    description:
                      "The cookie is used to track traffic coming from Bing to Zcooly."
                  },
                  {
                    name: "MUID",
                    displayName: "MUID",
                    vendor: "Bing",
                    domain: "bing.com",
                    expiration: "1 year",
                    description:
                      "The cookie is used to track traffic coming from Bing to Zcooly."
                  },
                  {
                    name: "sc_at",
                    displayName: "sc_at",
                    vendor: "Snapchat",
                    domain: "snapchat.com",
                    expiration: "1 year",
                    description:
                      "The cookie is used to identify a visitor navigating from Snapchat to Zcooly"
                  },
                  {
                    name: "X-AB",
                    displayName: "X-AB",
                    vendor: "Snapchat",
                    domain: "sc-static.net/snapchat.com",
                    expiration: "1 day",
                    description:
                      "The cookie is used by Snapchat for A/B testing."
                  }
                ]
              },
              {
                title: "More information",
                description:
                  'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="mailto:support%40zcooly.com">contact our support</a>.'
              }
            ]
          }
        },
        sv: {
          consent_modal: {
            title: "Vi använder cookies",
            description:
              'Hej, webbsidan använder nödvändiga cookies för att säkerställa att den fungerar korrekt, och spårningscookies för att förstå hur du interagerar med den. Spårningscookies sätts bara vid din tillåtelse. <a aria-label="Cookie policy" class="cc-link" href="https://zcooly.se/cookies/" target="_blank">Läs mer om hur vi använder cookies</a><br><br>Du kan också läsa mer om vilka cookies vi lagrar och göra inställningar per kategori.<br><a aria-label="Cookie-inställningar" class="cc-link" data-cc="c-settings" target="_blank">Öppna inställningar</a>',
            primary_btn: {
              text: "Acceptera alla",
              role: "accept_all"
            },
            secondary_btn: {
              text: "Neka alla",
              role: "accept_necessary"
            }
          },
          settings_modal: {
            title: "Cookieinställningar",
            save_settings_btn: "Spara inställningar",
            accept_all_btn: "Acceptera alla",
            reject_all_btn: "Neka alla",
            cookie_table_headers: [
              //{ name: "Namn" },
              { displayName: "Namn" },
              { vendor: "Utgivare" },
              //{ domain: "Domain" },
              { expiration: "Livslängd" },
              { description: "Beskrivning" }
            ],
            blocks: [
              {
                title: "Cookieanvändning",
                description:
                  "Vi använder cookies för att säkerställa webbplatsens grundläggande funktioner och för att förbättra din onlineupplevelse. Du kan välja för varje kategori om du vill ha dem eller inte, och du kan ändra dig när du vill."
              },
              {
                title: "Nödvändiga cookies",
                description:
                  "Dessa cookies är nödvändiga för att säkerställa att den fungerar korrekt. Utan dessa cookies skulle inte webbsidan fungera ordentligt.",
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true
                },
                cookie_table: [
                  {
                    name: "cookie_consent",
                    displayName: "cookie_consent",
                    vendor: "Zcooly",
                    domain: cookieDomain,
                    expiration: "6 månader",
                    description: "Sparar dina cookie-preferenser du gör här."
                  },
                  {
                    name: "__stripe_mid",
                    displayName: "__stripe_mid",
                    vendor: "Stripe",
                    domain: "stripe.com",
                    expiration: "1 år",
                    description: "För att förebygga bedrägeri."
                  },
                  {
                    name: "__stripe_sid",
                    displayName: "__stripe_sid",
                    vendor: "Stripe",
                    domain: cookieDomain,
                    expiration: "1 dag",
                    description: "För att förebygga bedrägeri."
                  }
                ]
              },
              {
                title: "Funktionella",
                description:
                  "Används för vissa funktioner i tjänsten och för att förbättra prestandan genom att lagra vissa beräknade värden.",
                toggle: {
                  value: "functional",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "zcoolyCurrency",
                    displayName: "zcoolyCurrency",
                    vendor: "Zcooly",
                    domain: cookieDomain,
                    expiration: "7 dagar",
                    description:
                      "Sparar vilken valuta du bör prenumerera med så den inte behöver hämtas varje gång."
                  }
                ]
              },
              {
                title: "Analys",
                description:
                  "Dessa cookies samlar information om hur du använder webbsidan, vilka sidor du besöker och vilka länkar du klickar på. All data anonymiseras och kan inte användas till att identifiera dig.",
                toggle: {
                  value: "analytics",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "^_ga",
                    displayName: "_ga",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 år",
                    description:
                      "Vi använder Google Analytics för att analysera vår webbplatstrafik för att förbättra användarupplevelsen och förstå våra marknadsföringsinsatser.",
                    is_regex: true
                  },
                  {
                    name: "^_gat",
                    displayName: "_gat",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "1 minut",
                    description:
                      "Används för att begränsa förfrågningsfrekvensen.",
                    is_regex: true
                  },
                  {
                    name: "_gid",
                    displayName: "_gid",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "1 dag",
                    description: "Används för att särskilja användare."
                  },
                  {
                    name: "__utma",
                    displayName: "__utma",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 år",
                    description:
                      "Används för att skilja användare och sessioner åt. Cookien skapas när javascript-biblioteket körs och inga befintliga __utma-cookies existerar. Cookien uppdateras varje gång data skickas till Google Analytics."
                  },
                  {
                    name: "__utmb",
                    displayName: "__utmb",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "30 minuter",
                    description:
                      "Används för att fastställa nya sessioner/besök. Cookien skapas när javascript-biblioteket körs och inga befintliga __utmb-cookies existerar. Cookien uppdateras varje gång data skickas till Google Analytics."
                  },
                  {
                    name: "__utmc",
                    displayName: "__utmc",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "Till slutet på sessionen",
                    description:
                      "Används inte i ga.js. Ställ in för interoperabilitet med urchin.js. Historiskt sett fungerade denna cookie tillsammans med __utmb-cookien för att avgöra om användaren var i en ny session/besök."
                  },
                  {
                    name: "^__utmt",
                    displayName: "__utmt",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "10 minuter",
                    description:
                      "Används för att begränsa förfrågningsfrekvensen.",
                    is_regex: true
                  },
                  {
                    name: "__utmv",
                    displayName: "__utmv",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "2 år",
                    description:
                      "Används för att lagra anpassad variabeldata på besöksnivå. Denna cookie skapas när en utvecklare använder metoden _setCustomVar med en anpassad variabel på besöksnivå. Denna cookie användes också för den föråldrade _setVar-metoden. Cookien uppdateras varje gång data skickas till Google Analytics."
                  },
                  {
                    name: "__utmz",
                    displayName: "__utmz",
                    vendor: "Google",
                    domain: "google.com",
                    expiration: "6 månader",
                    description:
                      "Lagrar trafikkällan eller kampanjen som förklarar hur användaren nådde din webbplats. Cookien skapas när javascript-biblioteket körs och uppdateras varje gång data skickas till Google Analytics."
                  }
                ]
              },
              {
                title: "Marknadsföring",
                description:
                  "Används för att mäta vissa av våra marknadsföringskampanjer och ge kickback till vissa partners.",
                toggle: {
                  value: "marketing",
                  enabled: false,
                  readonly: false
                },
                cookie_table: [
                  {
                    name: "_fbp",
                    displayName: "_fbp",
                    vendor: "Facebook",
                    domain: cookieDomain,
                    expiration: "3 månader",
                    description:
                      "Cookien identifierar exempelvis webbläsare i syfte att tillhandahålla annonserings- och webbplatsanalystjänster."
                  },
                  {
                    name: "_gcl_au",
                    displayName: "_gcl_au",
                    vendor: "Google",
                    domain: cookieDomain,
                    expiration: "3 månader",
                    description:
                      "Google Adsense – för att lagra och spåra konverteringar."
                  },
                  {
                    name: "_scid",
                    displayName: "_scid",
                    vendor: "Snapchat",
                    domain: "snapchat.com",
                    expiration: "1 år",
                    description:
                      "Cookien används till att identifiera en besökare."
                  },
                  {
                    name: "_ttp",
                    displayName: "_ttp",
                    vendor: "TikTok",
                    domain: "tiktok.com",
                    expiration: "13 månader",
                    description:
                      "Vi använder Tiktok-pixeln för att förstå hur våra annonser på Tiktok presterar."
                  },
                  {
                    name: "_uetsid",
                    displayName: "_uetvid",
                    vendor: "Bing",
                    domain: cookieDomain,
                    expiration: "1 dag",
                    description:
                      "Cookien används för att spåra trafik från Bing till Zcooly."
                  },
                  {
                    name: "_uetvid",
                    displayName: "_uetvid",
                    vendor: "Bing",
                    domain: cookieDomain,
                    expiration: "1 månad",
                    description:
                      "Cookien används för att spåra trafik från Bing till Zcooly."
                  },
                  {
                    name: "MUID",
                    displayName: "MUID",
                    vendor: "Bing",
                    domain: "bing.com",
                    expiration: "1 år",
                    description:
                      "Cookien används för att spåra trafik från Bing till Zcooly."
                  },
                  {
                    name: "sc_at",
                    displayName: "sc_at",
                    vendor: "Snapchat",
                    domain: "snapchat.com",
                    expiration: "1 år",
                    description:
                      "Cookien används till att identifiera en besökare som navigerar från Snapchat till Zcooly."
                  },
                  {
                    name: "X-AB",
                    displayName: "X-AB",
                    vendor: "Snapchat",
                    domain: "sc-static.net/snapchat.com",
                    expiration: "1 dag",
                    description: "Cookien används av Snapchat för A/B-testning."
                  }
                ]
              },
              {
                title: "Mer information",
                description:
                  'För eventuella frågor angående vår policy för cookies och dina val, vänligen <a class="cc-link" href="mailto:support%40zcooly.com">kontakta vår support</a>.'
              }
            ]
          }
        }
      }
    });
  }

  showSettings() {
    this.cc.showSettings();
  }
}

export default new CookieConsent();
