import axios from "axios";
import { serializeParams } from "@/api/resource/helpers";
import debug from "@/utilities/debug";

let cache = {};

/**
 * @abstract
 */
class Resource {
  /**
   * @param {Object} data
   */
  constructor(data = {}) {
    if (this.constructor === Resource) {
      throw new Error("Object of Abstract Class cannot be created.");
    }

    /**
     * Populate attributes with the data object.
     */
    for (let attribute in data) {
      if (Object.prototype.hasOwnProperty.call(data, attribute)) {
        this[attribute] = data[attribute];
      }
    }
  }

  /**
   * This needs to be implemented by the resource class that inherits this base class.
   *
   * @return {string}
   */
  static get url() {
    throw new Error("You need to implement the static get url() method.");
  }

  /**
   * Make a GET request.
   *
   * @param {Object} params
   *
   * @return Promise<any>
   */
  static get(params = {}) {
    return new Promise((resolve, reject) => {
      axios.get(parseUrl(this.url, params)).then(response => {
        if (response?.data) {
          resolve(new this(this.transformIncoming(response.data)));
        }

        reject();
      }, reject);
    });
  }

  /**
   * Make a GET request to fetch a list of objects.
   *
   * @param {Object} params
   * @param {function|null} listGetter Used to return the list of objects from the response
   * when the response is an object instead of an array.
   *
   * @return Promise<any[]>
   */
  static list(params = {}, listGetter = null) {
    return new Promise((resolve, reject) => {
      axios.get(parseUrl(this.url, params)).then(response => {
        if (response?.data) {
          let responseList =
            typeof listGetter === "function"
              ? listGetter.call(null, response.data)
              : response.data;

          if (Array.isArray(responseList)) {
            let list = [];

            responseList.forEach(item => {
              item = this.transformIncoming(item);

              list.push(new this(item));
            });

            resolve(list);
          }
        }

        reject();
      }, reject);
    });
  }

  /**
   * Make a POST request.
   *
   * @param {Object} params
   * @param {Object} data
   *
   * @return Promise<any>
   */
  static create(params = {}, data = {}) {
    data = this.transformOutgoing(data);

    return new Promise((resolve, reject) => {
      axios.post(parseUrl(this.url, params, data), data).then(response => {
        if (response?.data) {
          resolve(new this(this.transformIncoming(response.data)));
        }

        reject();
      }, reject);
    });
  }

  /**
   * Make a PATCH request.
   *
   * @param {Object} params
   * @param {Object} data
   *
   * @return Promise<any>
   */
  static update(params = {}, data = {}) {
    data = this.transformOutgoing(data);

    return new Promise((resolve, reject) => {
      axios.patch(parseUrl(this.url, params, data), data).then(response => {
        if (response?.data) {
          resolve(new this(this.transformIncoming(response.data)));
          return;
        }

        /**
         * Some endpoints may return an empty body on some PATCH calls, and we can use this to handle the error that would otherwise occur.
         */
        if (response?.status === 204) {
          resolve(new this());
          return;
        }

        reject();
      }, reject);
    });
  }

  /**
   * Manipulates a single resource when it is fetched.
   * @param {Object} resource
   * @return {Object}
   */
  static transformIncoming(resource) {
    return resource;
  }

  /**
   * Manipulates a single resource when it is sent to be created or updated.
   * @param {Object} resource
   * @return {Object}
   */
  static transformOutgoing(resource) {
    return resource;
  }

  /**
   * Make a PATCH request.
   *
   * @param {Object} params
   * @param {Object} data
   *
   * @return Promise<AxiosResponse<any>>
   */
  static delete(params = {}, data = {}) {
    return axios.delete(parseUrl(this.url, params, data));
  }

  /**
   * @return {Object} an object containing all data as key: value.
   */
  data() {
    let data = {};

    for (let attribute in this) {
      if (Object.prototype.hasOwnProperty.call(this, attribute)) {
        data[attribute] = this[attribute];
      }
    }

    return data;
  }

  /**
   * Return the resource data as json.
   *
   * @return {string}
   */
  json() {
    return JSON.stringify(this.data());
  }

  /**
   * Create the object using the properties in the object as parameters in the configured resource URL.
   *
   * @param {Object} params Extra URL params
   *
   * @return {Promise<any>}
   */
  create(params = {}) {
    const self = this;

    return new Promise((resolve, reject) => {
      self.constructor.create(params, self.data()).then(obj => {
        for (let attribute in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, attribute)) {
            self[attribute] = obj[attribute];
          }
        }

        resolve(self);
      }, reject);
    });
  }

  /**
   * Update the object using the properties in the object as parameters in the configured resource URL.
   *
   * @param {Object} params Extra URL params
   *
   * @return {Promise<any>}
   */
  update(params = {}) {
    const self = this;

    return new Promise((resolve, reject) => {
      self.constructor.update(params, self.data()).then(obj => {
        for (let attribute in obj) {
          if (Object.prototype.hasOwnProperty.call(obj, attribute)) {
            self[attribute] = obj[attribute];
          }
        }

        resolve(self);
      }, reject);
    });
  }

  /**
   * Delete the object using the properties in the object as parameters in the configured resource URL.
   *
   * @param {Object} params Extra URL params
   * @return {Promise<any>}
   */
  delete(params = {}) {
    const self = this;
    let data = self.data();

    return new Promise((resolve, reject) => {
      self.constructor.delete(params, data).then(() => {
        for (let attribute in data) {
          if (Object.prototype.hasOwnProperty.call(data, attribute)) {
            delete self[attribute];
          }
        }
        self._data = {};

        resolve(self);
      }, reject);
    });
  }

  /**
   * Check if the resource cache contains a valid entry for the given key.
   *
   * @param {string} key
   * @return {boolean}
   */
  static cacheHas(key) {
    return (
      Object.prototype.hasOwnProperty.call(cache, key) &&
      cache[key].validUntil > new Date()
    );
  }

  /**
   * Get the cached value for a given key, if it is still valid.
   *
   * @param {string} key
   * @return {*|null}
   */
  static cacheGet(key) {
    return this.cacheHas(key) ? cache[key].value : null;
  }

  /**
   * Save a value in the resource cache, with an optional valid duration in seconds.
   *
   * @param key
   * @param value
   * @param duration
   */
  static cacheSet(key, value, duration = 3600) {
    let validUntil = new Date();
    validUntil.setSeconds(validUntil.getSeconds() + duration);

    cache[key] = {
      value: value,
      validUntil: validUntil
    };

    flushOldCacheEntries(cache);

    debug.log(`Saved cache entry ${key} for ${duration} seconds.`, value);
  }

  /**
   * Delete a cached value from the resource cache.
   *
   * @param key
   */
  static cacheDelete(key) {
    if (Object.prototype.hasOwnProperty.call(cache, key)) {
      delete cache[key];
    }

    flushOldCacheEntries(cache);
  }

  /**
   * Deletes multiple cached values from the resource cache based on how their names matches the given RegExp pattern.
   * @param {RegExp} pattern
   */
  static cacheDeletePattern(pattern) {
    let count = 0;

    Object.keys(cache).forEach(key => {
      if (pattern.test(key)) {
        delete cache[key];
        count++;
      }
    });

    debug.log(
      `Cache delete from pattern ${pattern.toString()} cleared ${count} items.`
    );

    flushOldCacheEntries(cache);
  }

  /**
   * Clear all the resource cached data,
   */
  static cacheFlush() {
    cache = {};

    debug.log("Flushed cache.");
  }
}

/**
 * @param {string} url
 * @param {Object} params
 * @param {Object} data
 *
 * @return {string}
 */
function parseUrl(url, params = {}, data = {}) {
  let getParams = {};

  for (let key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      if (url.indexOf(":" + key) !== -1) {
        url = url.replaceAll(":" + key, params[key]);
      } else {
        getParams[key] = params[key];
      }
    }
  }

  for (let key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      url = url.replaceAll(":" + key, data[key]);
    }
  }

  url = url.replaceAll(/\/:[\w-_]+/g, "");
  url = url.replaceAll(/https?:\/\/.*(\/\/).*/g, "/");

  if (Object.keys(getParams).length > 0) {
    let query = serializeParams(getParams, null);

    if (url.indexOf("?") !== -1) {
      url += query;
    } else {
      url += "?" + query;
    }
  }

  return url;
}

/**
 * Removes all outdated objects from the cache.
 *
 * @param {Object} cache
 */
function flushOldCacheEntries(cache) {
  let now = new Date(),
    count = 0;

  Object.keys(cache).forEach(key => {
    if (
      Object.prototype.hasOwnProperty.call(cache, key) &&
      cache[key].validUntil < now
    ) {
      delete cache[key];
      count++;
    }
  });

  if (count > 0) {
    debug.log(`Cleaned cache from ${count} old items.`);
  }
}

export default Resource;
