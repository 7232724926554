export default {
  namespaced: true,
  state: () => ({
    title: "Zcooly",
    utm: "utm_source=login",
    env: process.env.VUE_APP_ENV
  }),
  mutations: {},
  actions: {},
  modules: {},
  strict: process.env.VUE_APP_ENV !== "production"
};
