/**
 * Changes the first letter of a word to uppercase.
 * @param {string} string
 * @return {string}
 */
function ucFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Changes the first letter of each word in a sentence to uppercase.
 * @param {string} string
 * @return {string}
 */
function ucWords(string) {
  return string
    .split(" ")
    .map(ucFirst)
    .join(" ");
}

/**
 * Changes the first letter of a word to lowercase.
 * @param {string} string
 * @return {string}
 */
function lcFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Changes the first letter of each word in a sentence to lowercase.
 * @param {string} string
 * @return {string}
 */
function lcWords(string) {
  return string
    .split(" ")
    .map(ucFirst)
    .join(" ");
}

/**
 * Converts id strings to text.
 * @param {string} string
 * @return {string}
 */
function idToText(string) {
  return ucFirst(string.replaceAll("-", " "));
}

function randomizeString(
  characters = 8,
  characterSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
) {
  let result = "";
  for (let i = 0; i < characters; i++) {
    result += characterSet.charAt(
      Math.floor(Math.random() * characterSet.length)
    );
  }

  return result;
}

module.exports = {
  idToText,
  lcFirst,
  lcWords,
  ucFirst,
  ucWords,
  randomizeString
};
