import { Service } from "@/services/base.service";
import axios from "axios";
import { setCookie, getCookie } from "@/utilities/helpers";
import CookieConsentService from "@/services/cookie-consent.service";
import i18n from "@/i18n";
import store from "@/store";
import User from "@/api/v2/user";

const cookieName = "zcoolyLocale";

//TODO: Update cookie consent module with this cookie, instead of zcoolyCurrency.

let loadPromise = null;
let saveTimer = null;

class Locale extends Service {
  constructor() {
    if (Locale._instance) {
      return Locale._instance;
    }
    super();

    Locale._instance = this;

    this.currency = null;
    this.countryCode = null;
    this.zipCode = null;
  }

  getValue(valueName, defaultValue) {
    if (["currency", "countryCode", "zipCode"].indexOf(valueName) === -1) {
      return Promise.resolve(null);
    }

    const _this = this;
    return new Promise(resolve => {
      if (_this[valueName]) {
        resolve(_this[valueName]);
      } else {
        if (loadPromise === null) {
          loadPromise = _this.loadValues();
        }

        loadPromise.then(
          data => {
            _this.currency = data.currency || "";
            _this.countryCode = data.country_code || "";
            _this.zipCode = data.postal || "";

            CookieConsentService.addChangeCallback(cookie => {
              if (cookie.level && cookie.level.indexOf("functional") !== -1) {
                setCookie(
                  cookieName,
                  JSON.stringify({
                    currency: _this.currency,
                    countryCode: _this.countryCode,
                    zipCode: _this.zipCode
                  }),
                  7
                );
              }
            });

            resolve(_this[valueName] || defaultValue);
          },
          () => {
            resolve(defaultValue);
          }
        );
      }
    });
  }

  loadValues() {
    return new Promise((resolve, reject) => {
      if (getCookie(cookieName)) {
        resolve(getCookie(cookieName));
      } else {
        axios.get("https://ipapi.co/json").then(
          response => {
            if (response.status === 200 && response.data) {
              resolve(JSON.parse(response.data));
            } else {
              reject();
            }
          },
          () => {
            reject();
          }
        );
      }
    });
  }

  setGlobalLocale(locale) {
    i18n.global.locale = locale;
  }

  getGlobalLocale() {
    return i18n.global.locale;
  }

  saveUserLocale(locale) {
    if (!store.state.user?.user?.id) {
      return;
    }

    if (saveTimer !== null) {
      clearTimeout(saveTimer);
    }

    saveTimer = setTimeout(() => {
      User.update({ id: store.state.user.user.id }, { language: locale });
    }, 500);
  }
}

export default new Locale();
