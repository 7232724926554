{
    "ABORT": "Avbryt",
    "ABOUT": "Om Zcooly",
    "ACCEPT_PARENT_INVITATION_DESCRIPTION": "Du har blivit inbjudan till att dela familjekonto med {senderEmail}. Se över dina uppgifter här och välj ett lösenord för att komma igång.",
    "ACCEPT_PARENT_INVITATION_ERROR": "Länken till den här sidan är ofullständig. Vänligen kontrollera att hela länken är med, eller kontakta <a href=\"mailto:support%40zcooly.se\">support</a> så hjälper vi dig.",
    "ACCEPT_PARENT_INVITATION_FAILED_SUBMIT": "Registreringen av ditt föräldrakonto misslyckades.",
    "ACCEPT_PARENT_INVITATION_HEADING": "Slutför registreringen av ditt föräldrakonto",
    "ADDRESS_CITY*": "Stad*",
    "ADDRESS_ZIP*": "Postnummer*",
    "ALERT_CHILD_REMOVED_TEXT": "Barnanvändaren har tagits bort, och all barnets data har raderats.",
    "ALERT_CHILD_REMOVED_TITLE": "Barnanvändaren har tagits bort",
    "ALERT_COULD_NOT_INVITE_PARENT_REGISTRATION_TEXT": "Vi lyckades inte bjuda in din partner. Du kan försöka igen på sidan \"Prenumeration\" på Mitt konto.",
    "ALERT_COULD_NOT_INVITE_PARENT_REGISTRATION_TITLE": "Något gick snett",
    "ALERT_NO_INVITATION_CODE_TEXT": "Misslyckades med att skapa en inbjudningslänk. Försök igen eller kontakta supporten om problemet kvarstår.",
    "ALERT_NO_INVITATION_CODE_TITLE": "Åh nej!",
    "ALERT_REGISTRATION_CHILD_PROFILE_ERROR_TEXT": "Vi har registrerat att ett fel har inträffat och kommer att försöka lösa felet så snart som möjligt. De kan slutföra detta steg senare på din kontosida.",
    "ALERT_REGISTRATION_CHILD_PROFILE_ERROR_TITLE": "Ett fel har uppstått",
    "ALERT_REGISTRATION_ERROR_TEXT": "Vi har registrerat att ett fel har inträffat och kommer att försöka lösa felet så snart som möjligt. Om felet kvarstår, kontakta vår support så hjälper vi dig.",
    "ALERT_REGISTRATION_ERROR_TITLE": "Ett fel har uppstått",
    "ALERT_REGISTRATION_PLAN_ERROR_TEXT": "Vi har registrerat att ett fel har inträffat och kommer att försöka lösa felet så snart som möjligt. De kan slutföra detta steg senare på prenumerationssidan under din kontosida.",
    "ALERT_REGISTRATION_PLAN_ERROR_TITLE": "Ett fel har uppstått",
    "ALERT_YOUR_QR_CODE_DESCRIPTION": "Rikta en QR-kodsläsare mot QR-koden för att öppna din länk.",
    "ALERT_YOUR_QR_CODE_TITLE": "Din QR-kod",
    "APPROVED_CAMPAIGN_CODE": "Godkänd! Du får ta del av följande kampanj:",
    "APP_SUBSCRIPTION_SLOGAN": "Gör kunskap kul.",
    "BILLING_INFORMATION": "Fakturauppgifter",
    "BILLING_REFERENCE*": "Fakturareferens*",
    "BILLING_STREET*": "Fakturaadress*",
    "BIRTH_YEAR": "Födelseår",
    "BY_CLICKING_I_AGREE": "Genom att trycka på 'Gå vidare' godkänner du Zcoolys",
    "CAMPAIGN_CODE": "Kampanjkod",
    "CAMPAIGN_CODE_IS_NOT_VALID": "Kampanjkoden är inte giltig.",
    "CANCEL": "Avbryt",
    "CANCEL_BOOKING": "Avboka",
    "CANCEL_MY_FREE_UNTIL_FURTHER_NOTICE": "Avbryt min gratisperiod",
    "CARD_NUMBER": "Kortnummer",
    "CHANGE": "Ändra",
    "CHANGE_PACKAGE_NEW_PRICE_PER_12_MONTHS": "Ditt nya pris blir {price}/12 månader.",
    "CHANGE_PACKAGE_NEW_PRICE_PER_6_MONTHS": "Ditt nya pris blir {price}/6 månader.",
    "CHANGE_PACKAGE_NEW_PRICE_PER_MONTH": "Ditt nya pris blir {price}/månad.",
    "CHANGE_PAYMENT_INTERVAL": "Ändra",
    "CHANGE_SUBSCRIPTION_PAYMENT_INTERVAL": "Ändra betalningsintervall",
    "CHANGE_SUBSCRIPTION_PLAN": "Ändra medlemskap",
    "CHANGING_SUBSCRIPTION_TOO_SOON_TEXT": "Vänligen vänta fem minuter innan du gör fler ändringar på din prenumeration.",
    "CHANGING_SUBSCRIPTION_TOO_SOON_TITLE": "Hoppsan!",
    "CHOOSE_PACKAGE": "Välj paket",
    "CHOOSE_PAYMENT_METHOD": "Välj betalningssätt",
    "CHOOSE_SUBSCRIPTION_PAYMENT_INTERVAL": "Välj betalningsintervall",
    "CHOOSE_SUBSCRIPTION_PLAN": "Välj prenumerationsplan",
    "CHOOSE_THIS_PACKAGE": "Välj detta medlemskap",
    "CLOSE": "Stäng",
    "COMPLETE_REGISTRATION": "Slutför registrering",
    "CONFIRM_ACCOUNT_DELETION_DESCRIPTION": "Om du genomför åtgärden kommer din data och dina barns framsteg i spelen raderas permanent. Du kommer få ett e-postmeddelande efteråt som bekräftar borttagningen av din data.",
    "CONFIRM_ACCOUNT_DELETION_DESCRIPTION_EXTRA_PARENT": "Om du genomför åtgärden kommer din personliga data raderas permanent. Eftersom du inte är kontoägare så kommer din familjs data vara kvar. Du kommer få ett e-postmeddelande efteråt som bekräftar borttagningen av din data.",
    "CONFIRM_ACCOUNT_DELETION_EMAIL_INSTRUCTION": "För att säkerställa att detta inte sker av misstag, t.ex. för att man klickat fel, så ber vi dig mata in din e-postadress i fältet nedan. Stämmer e-postadressen kommer raderingen påbörjas.",
    "CONFIRM_ACCOUNT_DELETION_ERROR_EMAIL": "E-postadressen matchade inte ditt konto.",
    "CONFIRM_ACCOUNT_DELETION_ERROR_OTHER": "Något blev fel, vänligen kontakta vår support så hjälper vi dig komma vidare.",
    "CONFIRM_ACCOUNT_DELETION_TITLE": "Är du säker?",
    "CONFIRM_APPLE_SUB_LOGOUT": "Är du säker?",
    "CONFIRM_CANCEL_SUBSCRIPTION_TITLE": "Vill du avsluta din prenumeration?",
    "CONFIRM_CLASS_TAKEOVER_TEXT": "Klassen du angivit är redan registrerad av en annan pedagog.<br><br>Om du är säker på att du är behörig till den klassen kan du skicka in din registrering ändå, den registrerade pedagogen får då avgöra om du är behörig.<br><br>Vill du skicka in din registrering?",
    "CONFIRM_CLASS_TAKEOVER_TITLE": "Få tillgång till din klass",
    "CONFIRM_LOGOUT": "Du kommer få ange din e-postadress och ditt lösenord för att logga in igen.<br>Är du säker?",
    "CONFIRM_REMOVE_CHILD_DESCRIPTION": "Om du tar bort barnanvändaren kommer all dess framsteg och data försvinna.",
    "CONFIRM_REMOVE_CHILD_TITLE": "Vill du verkligen ta bort barnanvändaren?",
    "CONTACT": "Kontakt",
    "CONTINUE": "Fortsätt",
    "CONTINUE_AND_GET_FREE_ALL_YEAR_LONG": "Fortsätt och få gratis hela året",
    "COOKIE_SETTINGS": "Cookies",
    "COST_PER_SIX_MONTHS": "{price}/halvår",
    "COST_PER_YEAR": "{price}/år",
    "COULD_NOT_CREATE_SUBSCRIPTION_PAUSE": "Kunde inte planlägga paus",
    "COULD_NOT_CREATE_SUBSCRIPTION_PAUSE_ERROR_MESSAGE": "Något gick snett när pausen skulle planläggas. Kontakta oss på Zcooly om detta skulle uppstå igen.",
    "CREATE_ACCOUNT": "Skapa konto",
    "CREATE_PARENT_PROFILE": "Bli medlem",
    "CURRENT_PAYMENT_INTERVAL": "Aktivt betalningsintervall",
    "DISCOUNT": "{discount}% Rabatt",
    "DONE": "Färdig",
    "EMAIL": "Email",
    "EMAIL_ADDRESS": "E-postadress",
    "EMAIL_ADDRESS_OR_USERNAME": "E-postadress eller användarnamn",
    "EMAIL_US_WITH_ANY_QUESTIONS": "Har du några frågor kontakta <a href='mailto:support%40zcooly.com'>support&#64;zcooly.com</a> så hjälper vi dig.",
    "ENTER_CAMPAIGN_CODE": "Skriv in din kampanjkod",
    "ENTER_FIRSTNAME": "Ange förnamn",
    "ENTER_LASTNAME": "Ange efternamn",
    "ENTER_PARENT_EMAIL": "Ange email",
    "ENTER_PARTNER_PARENT_EMAIL": "Ange din partners email",
    "ENTER_PASSWORD": "Ange lösenord",
    "ENTER_PIN": "Ange PIN",
    "ERROR_MAXIMUM_REMINDERS_SENT": "Du kan inte skicka fler inbjudningar till den här adressen.",
    "ERROR_MESSAGE_UNABLE_TO_DO_ACTION": "Det gick inte att utföra åtgärden.",
    "FIELD_ERROR_CANNOT_MATCH": "Fältet får inte ha samma värde.",
    "FIELD_ERROR_CANNOT_MATCH_PARENT_EMAIL": "E-postadresserna får inte vara samma.",
    "FIELD_ERROR_INVALID_EMAIL": "Ogiltig e-postadress",
    "FIELD_ERROR_INVALID_NAME": "Ogiltigt namn. Använd bara bokstäver, mellanslag eller bindestreck.",
    "FIELD_ERROR_INVALID_NUMBER": "Fältet får bara innehålla siffror.",
    "FIELD_ERROR_INVALID_PASSWORD": "Lösenordet måste vara minst 6 tecken.",
    "FIELD_ERROR_INVALID_PHONE_NUMBER": "Ogiltigt telefonnummer",
    "FIELD_ERROR_INVALID_ZIP_CODE": "Ogiltigt postnummer",
    "FIELD_ERROR_NOT_MATCHING": "Fälten matchar inte",
    "FIELD_ERROR_NOT_UNIQUE_EMAIL": "E-postadressen är redan registrerad",
    "FIELD_ERROR_REQUIRED": "Detta fält är obligatoriskt",
    "FIELD_ERROR_TOO_LARGE": "Värdet måste vara lägre än {limit}",
    "FIELD_ERROR_TOO_LONG": "Fältet får inte ha fler än {limit} tecken",
    "FIELD_ERROR_TOO_SHORT": "Fältet måste ha minst {limit} tecken",
    "FIELD_ERROR_TOO_SMALL": "Värdet måste vara högre än {limit}",
    "FIELD_HAS_INVALID_VALUE": "Ogiltigt värde.",
    "FIELD_IS_NOT_AN_EMAIL": "E-postadressen måste ha ett korrekt format.",
    "FIELD_IS_REQUIRED": "Obligatoriskt fält.",
    "FIELD_IS_TOO_LONG": "Fältet får max ha {length} tecken.",
    "FIELD_NEEDS_HAVE_X_AMOUNT_OF_CHARS": "Fältet måste minst ha {min} tecken.",
    "FIRSTNAME": "Förnamn",
    "FORGOT_PASSWORD": "Glömt lösenordet?",
    "FREE_FOREVER": "Gratis för evigt",
    "FREE_UNTIL_2023": "Hela läsåret!",
    "FURTHER_NOTICE_CANCEL_AND_CONFIRM_LOSS_OF_DATA": "Är du säker på att du skall avbryta din gratisperiod? Det kommer att innebära att du inte kommer att kunna fortsätta att använda Zcooly gratis, du kommer även att förlora all information om dina barns framsteg.",
    "FURTHER_NOTICE_CANCEL_AND_CONFIRM_LOSS_OF_DATA_TITLE": "Avbryt och sluta använda Zcooly gratis",
    "GENERIC_PAYMENT_ERROR_MESSAGE": "Din valda betalmetod misslyckades. Om dina uppgifter stämmer så beror det oftast på att din bank har nekat betalning. Det kan bero på att betalningar på internet inte stöds, att du inte har tillräckligt med pengar på kontot eller att betalkortet är inaktiverat eller rapporterat som borttappat/stulet. Vänligen kontrollera att du kan genomföra betalningar på internet och prova igen.",
    "GET_ZCOOLY_PLAY": "Jag vill skapa konto",
    "GO_TO_NEXT_STEP_REGISTRATION": "Gå vidare",
    "GO_TO_NEXT_STEP_REGISTRATION_PLAN_SELECTION_MONTH": "Gå vidare ({totalPrice}/mån)",
    "GO_TO_NEXT_STEP_REGISTRATION_PLAN_SELECTION_MONTHS": "Gå vidare ({totalPrice}/{months} mån)",
    "INVITATION_SEND_BY_EMAIL_DESCRIPTION": "Ange en väns e-postadress som du vill bjuda in här. När du klickat på skicka så kommer vi att skicka en inbjudan via e-post med din länk bifogad.",
    "INVITATION_SEND_BY_EMAIL_HEADING": "Bjud in en vän via mail",
    "INVITE_EXTRA_PARENT_DESCRIPTION": "Du kan bjuda in en till förälder till att dela familjekontot. Den andra föräldern får tillgång till samma funktioner som du kan använda, men ni har separata inställningar och inloggningsuppgifter.",
    "INVITE_EXTRA_PARENT_HEADING": "Bjud in förälder",
    "INVITE_PARENT": "Bjud in förälder",
    "I_AGREE_TO_THE_BLANK": "Ja, jag godkänner Zcoolys ",
    "I_CONFIRM": "Jag bekräftar",
    "I_GOT_A_CAMPAIGN_CODE": "Jag har en kampanjkod",
    "I_WANT_TO_CANCEL_MY_SUBSCRIPTION": "Jag vill säga upp min prenumeration",
    "I_WANT_TO_LOG_IN": "Jag vill logga in",
    "I_WANT_TO_MY_CANCEL_SUBSCRIPTION": "Säga upp prenumeration",
    "I_WANT_TO_PAUSE_LONG_TRIAL_SUBSCRIPTION": "Jag vill avbryta gratisperioden",
    "I_WANT_TO_PAUSE_OR_CANCEL_SUBSCRIPTION": "Pausa eller avsluta prenumeration",
    "I_WANT_TO_SKIP_THIS_STEP": "Jag vill hoppa över detta steg",
    "KEEP_FREE_UNTIL_FURTHER_NOTICE": "Behåll min gratisperiod",
    "KEEP_MY_SUBSCRIPTION": "Behåll min prenumeration",
    "LASTNAME": "Efternamn",
    "LOGIN": "Logga in",
    "LOGIN_ERROR_CREDENTIALS": "E-postadress och lösenord hör inte ihop. Försök igen.",
    "LOGIN_ERROR_INTERNATIONAL_CREDENTIALS": "Användarnamn och lösenord hör inte ihop. Försök igen.",
    "LOGIN_ERROR_MISC": "Hoppsan! Något gick fel",
    "LOGIN_ERROR_PARENT_LICENSE_EXPIRED": "Tyvärr har din licens gått ut, var god kontrollera dina uppgifter på din kontosida",
    "LOGIN_ERROR_STUDENT_CREDENTIALS": "Felaktig PIN-kod. Prata med din lärare för att få hjälp.",
    "LOGIN_ERROR_STUDENT_LICENSE_EXPIRED": "Klassens licens har gått ut. Prata med din lärare för att få hjälp.",
    "LOGIN_ERROR_TEACHER_IN_APP": "Du har ett lärarkonto och kan bara logga in i en webbläsare.",
    "LOGIN_ERROR_TEACHER_LICENSE_EXPIRED": "Tyvärr har din licens för din klass gått ut, kontakta oss så hjälper vi dig att komma igång igen!",
    "LOGIN_FAMILY": "Familj",
    "LOGIN_SCHOOL": "Klass",
    "LOGIN_WITH_SKOLON": "Logga in med Skolon",
    "LOGIN_ZCOOLY_PLAY": "Logga in på Zcooly",
    "LOGOUT_FROM_ZCOOLY_PLAY": "Logga ut från Zcooly",
    "LOG_IN": "Logga in",
    "LOG_IN_AS_CLASS": "Logga in",
    "LOG_IN_AS_FAMILY": "Logga in",
    "LOG_IN_WITH_APPLE": "Logga in med Apple",
    "LOG_IN_WITH_APPLE_SUBSCRIPTION": "Återställ köp för Apple Subscription",
    "LONG_TRIAL_PAUSED": "Din prenumeration är nu pausad.",
    "LOSS_OF_DATA_BY_CANCELING_TERMS": "Jag bekräftar härmed att jag är medveten om att jag kommer att förlora mina barns framsteg i Zcooly och övriga uppgifter hos Zcooly inom ett år.",
    "MONTH": "Månad",
    "MONTHS": "Månader",
    "MONTH_APRIL": "April",
    "MONTH_AUGUST": "Augusti",
    "MONTH_DECEMBER": "December",
    "MONTH_FEBRUARY": "Februari",
    "MONTH_JANUARY": "Januari",
    "MONTH_JULY": "Juli",
    "MONTH_JUNE": "Juni",
    "MONTH_MARCH": "Mars",
    "MONTH_MAY": "Maj",
    "MONTH_NOVEMBER": "November",
    "MONTH_OCTOBER": "Oktober",
    "MONTH_SEPTEMBER": "September",
    "MOST_POPULAR": "Populärast",
    "NO": "Nej",
    "NOTICE_APPLE_LOGIN_CANCELLED": "Inloggningen med Apple avbröts.",
    "NOTICE_CHILD_UNPAID_FOR": "För att kunna spela med detta barn måste du uppgradera ditt konto på prenumerationssidan.",
    "NOTICE_IOS_OAUTH_NO_ACCOUNT": "Du kan inte logga in med ditt Apple-konto eftersom det inte är registrerat hos oss. Försök att skapa en Apple-prenumeration så är du snart igång.",
    "NOTICE_NO_APPLE_SUBSCRIPTION": "Du verkar inte ha en aktiv Apple-prenumeration. Logga in här med ett konto i Zcooly eller klicka på \"Jag vill skapa konto\".",
    "NOTICE_OAUTH_ALREADY_REGISTERED": "Du har redan ett konto registrerat till {email} - Prova att logga in på det kontot istället.",
    "NOTICE_OAUTH_LOGIN_FAILED": "Inloggningen misslyckades.",
    "NOTICE_SKOLON_ERROR": "Inloggning med Skolon misslyckades. Antingen valde du att avbryta eller neka åtkomst till Zcooly, eller så har du inte tillgång till produkten. Vänligen kontakta <a href=\"mailto:support%40zcooly.se\">support&#64;zcooly.se</a> så hjälper vi dig.",
    "NOTICE_TITLE_APPLE_LOGIN_CANCELLED": "Inloggning med Apple misslyckades.",
    "NOTICE_TITLE_CHILD_UNPAID_FOR": "Barnanvändaren är låst",
    "NOTICE_TITLE_NO_APPLE_SUBSCRIPTION": "Ingen Apple-prenumeration",
    "NOTICE_TITLE_SKOLON_ERROR": "Inloggning med Skolon misslyckades",
    "NOT_ALREADY_MEMBER": "Har du inte ett Zcooly-konto?",
    "OK": "OK",
    "OKAY": "Okej",
    "ON_ANOTHER_DEVICE": "Spela på en annan enhet",
    "ON_THIS_DEVICE": "Spela på denna enhet",
    "OR": "Eller",
    "PACKAGE_AND_SUBSCRIPTION": "Paket och prenumeration",
    "PARENT_GATE_CLOSE": "Fyll i vuxens födelseår",
    "PARENT_GATE_ERROR_TEXT": "På grund av att du måste vara vuxen för att ha ett konto måste du ange ett födelseår som överensstämmer med att du är över 18 år.",
    "PARENT_GATE_ERROR_TITLE": "Du kommer inte förbi barnlåset.",
    "PASSWORD": "Lösenord",
    "PAUSE_BETWEEN_X_AND_Y": "Mellan {from} och {to}",
    "PAUSE_OPTION_EASTER_SIMPLE": "Pausa min prenumeration till och med påsk",
    "PAUSE_OPTION_HALLOWEEN_SIMPLE": "Pausa min prenumeration till och med halloween",
    "PAUSE_OPTION_MIDSUMMER_SIMPLE": "Pausa min prenumeration till och med midsommar",
    "PAUSE_OPTION_ONE_MONTH_SIMPLE": "Pausa min prenumeration 1 månad",
    "PAUSE_OPTION_SCHOOL_START_SIMPLE": "Pausa min prenumeration till och med skolstarten",
    "PAUSE_OPTION_SIX_MONTHS_SIMPLE": "Pausa min prenumeration 6 månader",
    "PAUSE_OPTION_TWO_MONTHS_SIMPLE": "Pausa min prenumeration 2 månader",
    "PAUSE_OPTION_YEAR_OUT_SIMPLE": "Pausa min prenumeration till och med nästa år",
    "PAUSE_OR_CANCEL_DESCRIPTION_CANCEL": "Om du väljer att avsluta din prenumeration kommer du kunna spela under resten av tiden du har betalat för, men när perioden är slut kommer det inte dras mer pengar och ni kan inte längre spela.",
    "PAUSE_OR_CANCEL_DESCRIPTION_PAUSE": "Om du väljer att pausa din prenumeration kommer det inte dras pengar under perioden som pausen är aktiv. Du kan själv starta din prenumeration igen när du vill eller så kommer den automatiskt startas när pausen är slut. Den tid du har betalat för finns kvar efter att pausen är slut.\n",
    "PAUSE_OR_CANCEL_SUBSCRIPTION": "Pausa eller avsluta prenumeration",
    "PAUSE_WAS_CREATED": "Pausen är nu schemalagd.",
    "PAYMENT_HELP_APPLE_INSTRUCTIONS": "Om prenumerationen inte har kunnat förnyas kan du behöva kontrollera den på din prenumerationssida i App Store. En Apple-prenumeration är ett avtal mellan dig och Apple, så Zcooly kan inte hantera den.",
    "PAYMENT_HELP_STRIPE_INSTRUCTIONS": "Du kan behöva kontrollera att kreditkortet du använder fortfarande är giltigt och att du tillåter betalningar över internet. Vår betalningsleverantör Stripe kommer prova att hämta betalningen vid några tillfällen efter en misslyckad betalning, så du kan behöva vänta några dagar efter att du gjort en ändring innan prenumerationen blir aktiv igen. I vissa fall finns också en knapp tillgänglig på din prenumerationssida som du kan använda för att manuellt prova en betalning igen.",
    "PAY_PER_MONTH": "Betala månadsvis",
    "PAY_PER_SIX_MONTHS": "Betala halvårsvis",
    "PAY_PER_YEAR": "Betala årsvis",
    "PLAN_SUMMARY_BASE_PRICE": "Grundpris",
    "PLAN_SUMMARY_COMPARISON_MONTHLY_PRICE": "Jämförelsepris per månad",
    "PLAN_SUMMARY_DISCOUNTS": "Rabatter",
    "PLAN_SUMMARY_MEMBERSHIP_LARGE": "Medlemskap stor",
    "PLAN_SUMMARY_PLAN_DISCOUNT_12_MONTHS": "Betala var tolfte månad (rabatt)",
    "PLAN_SUMMARY_PLAN_DISCOUNT_6_MONTHS": "Betala var sjätte månad (rabatt)",
    "PLAN_SUMMARY_TOTAL_MONTHLY_PRICE": "Beräknad månadskostnad",
    "PLAN_SUMMARY_TOTAL_PRICE": "Periodens kostnad för 1 månad",
    "PLAN_SUMMARY_TOTAL_PRICE_12_MONTHS": "Periodens kostnad för 12 månader",
    "PLAN_SUMMARY_TOTAL_PRICE_6_MONTHS": "Periodens kostnad för 6 månader",
    "PRICE_PER_MONTH": "Från {price}/månad.",
    "PROGRESS_CREATING_ACCOUNT": "Skapar konto",
    "PROGRESS_SENDING_WELCOME_EMAIL": "Skickar välkomstmail till din e-postadress",
    "PROGRESS_STARTING_SUBSCRIPTION": "Startar provperiod",
    "PROMPT_PARENT_GATE_TEXT_2": "",
    "PROMPT_PARENT_GATE_TEXT_APP_ACCOUNT": "",
    "PROMPT_PARENT_GATE_TITLE": "För att komma vidare, fyll i en vuxens födelseår.",
    "QUARTERLY": "Kvartalsvis",
    "REGISTRATION_BIG_PACKAGE_USP1": "Upp till 3 barn.",
    "REGISTRATION_CONFIRM_GROUP_BUTTON": "Godkänn",
    "REGISTRATION_CONFIRM_GROUP_LINK_ERROR": "Det var något problem med din länk. Vänligen kontakta <a href=\"mailto:support%40zcooly.se\">support&#64;zcooly.se</a> så hjälper vi dig.",
    "REGISTRATION_CONFIRM_GROUP_MESSAGE": "Vill du godkänna att {name} får tillgång till din klass {group}?",
    "REGISTRATION_CONFIRM_GROUP_THANK_YOU": "Du har nu godkänt att {name} får tillgång till din klass {group}!",
    "REGISTRATION_ERROR_COULD_NOT_LOAD_PLANS": "Kunde inte hitta några tillgängliga prisalternativ.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP1": "En värld av lärande med över 300 spelövningar framtagna av pedagoger som följer läroplanen.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP2": "För alla barn i åldrarna 5-12 år och fungerar på alla enheter.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP3": "Få koll på vad ditt barn lär sig i spelen och få matte-tips varje vecka.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP4": "Prova gratis i {trialDays} dagar utan bindningstid.",
    "REGISTRATION_FAMILY_CAMPAIGN_USP5": "",
    "REGISTRATION_FAMILY_USP1": "En värld av lärande med över 300 spelövningar framtagna av pedagoger som följer läroplanen.",
    "REGISTRATION_FAMILY_USP2": "För alla barn i åldrarna 5-12 år och fungerar på alla enheter.",
    "REGISTRATION_FAMILY_USP3": "Få koll på vad ditt barn lär sig i spelen och få matte-tips varje vecka.",
    "REGISTRATION_FAMILY_USP4": "Prova gratis i {trialDays} dagar utan bindningstid.",
    "REGISTRATION_FAMILY_USP5": "",
    "REGISTRATION_FAMILY_USP6": "",
    "REGISTRATION_INTRO_FAMILY_CAMPAIGN": "Fyll i dina uppgifter och skapa ett konto. Genom kontot får din familj tillgång till en värld full av lärande spel i matematik för dina barn att utforska. Du får prova gratis utan bindningstid, därefter från {price} i månaden.",
    "REGISTRATION_INTRO_FAMILY_INVITE_BY_FRIEND": "Fyll i dina uppgifter och skapa ett konto. Genom kontot får din familj tillgång till en värld full av lärande spel i matematik.",
    "REGISTRATION_NOTICE": "Njut av en {trialDays}-dagars gratis provperiod. Efter det sker betalningar enligt din valda intervall. Avbryt när som helst i inställningarna. Genom att välja 'Starta provperiod' krypterar och lagrar Stripe dina uppgifter säkert.",
    "REGISTRATION_PLAN_EXPLANATION": "Inga pengar dras innan testperiodens slut ({trialEndDate}). Efter provperioden går du automatisk över till din valda prenumerationsplan",
    "REGISTRATION_PROGRESS_HEADING": "Ditt medlemskap startas",
    "REGISTRATION_SMALL_PACKAGE_USP1": "1 barn.",
    "REGISTRATION_TEACHER_BILLING_QUARTERLY": "Efter provperioden kostar det 900 kr per kvartal.",
    "REGISTRATION_TEACHER_BILLING_YEARLY": "Efter provperioden kostar det 720 kr per kvartal och du betalar årsvis.",
    "REGISTRATION_TITLE_CONFIRM_GROUP": "Bekräfta ny lärare",
    "REGISTRATION_TITLE_FAMILY": "Gör kunskap kul",
    "REGISTRATION_TITLE_FAMILY_INVITE_BY_FRIEND": "Hurra! Din vän har bjudit dig på en gratismånad!",
    "REGISTRATION_TITLE_FAMILY_INVITE_BY_NAME": "Hurra! {firstName} {lastName} har bjudit in dig på en gratismånad!",
    "RESTORE_APPLE_SUB_PURCHASE_TEXT": "Den här knappen används för att återställa ett tidigare köp av en Apple prenumeration för Zcooly som gjorts på en annan enhet men med samma iCloud-konto. Vill du fortsätta?",
    "RESTORE_APPLE_SUB_PURCHASE_TEXT_FINE_PRINT": "Om processen misslyckas kan det vara för att du inte är inloggad på samma iCloud konto som när du genomförde det tidigare köpet. Om du misstänker att ett fel har uppstått är du välkommen att kontakta våran support.",
    "RESTORE_APPLE_SUB_PURCHASE_TITLE": "Återställ ett tidigare köp av Apple prenumeration",
    "RETRY_CHARGE_ERROR_MESSAGE": "Det gick inte att försöka dra pengar igen för vi hittade inga obetalda fakturor.",
    "RETRY_CHARGE_ERROR_TITLE": "Ett fel har uppstått",
    "SELECT_SCHOOL_OR_PRESCHOOL": "Välj skola eller förskola",
    "SELECT_YOUR_CLASS": "Välj din klass",
    "SIGN_IN_WITH_ZCOOLY": "Logga in på Zcooly",
    "SITE_META_DESCRIPTION": "Spela våra lärande spel för barn och ungdomar!",
    "SITE_META_IMAGE": "https://cdn.zcooly.com/images/share/zcooly-1200x600.png",
    "SITE_META_TITLE": "Zcooly",
    "START_TRIAL_PERIOD": "Starta provperiod",
    "STRIPE_REGISTRATION_NOTICE": "Du provar gratis i {trialDays} dagar. Efter provperioden kommer {price} att dras från ditt konto varje månad. Du kan avsluta din prenumeration när som helst via Mitt konto.",
    "STUDENT": "Elev",
    "STUDENT_OR_TEACHER_QUESTION": "Är du elev eller pedagog?",
    "SUBSCRIPTION_FUTURE_PAUSES_TITLE": "Schemalagda pauser",
    "SUBSCRIPTION_PLAN_DISCOUNT": "{discount} Rabatt",
    "SUBSCRIPTION_PLAN_MONTHLY_COST": "{amount}/mån",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_BIG": "Totalt {amount} för Zcooly",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_BIG_YEARLY": "Totalt {amount} per år för Zcooly",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_SMALL": "Totalt {amount} för Zcooly Lilla",
    "SUBSCRIPTION_PLAN_TOTAL_COST_FOR_SMALL_YEARLY": "Totalt {amount} per år för Zcooly Lilla",
    "SUBSCRIPTION_SECTION_ACTIONS_TITLE": "Kontoåtgärder",
    "SUBSCRIPTION_TERMS": "Prenumerationsvillkor",
    "SUBSCRIPTION_TERMS_SHORT": "Din prenumeration startar efter provperiodens slut ({trialEndDate}). Du avslutar när du vill.",
    "SUBSCRIPTION_TYPE_TITLE": "Prenumerationstyp",
    "SUBSCRIPTION_TYPE_ZCOOLY_FAMILY": "Zcooly Familj",
    "SUBSCRIPTION_WAS_PAUSED": "Prenumerationen är nu pausad",
    "SWITCH_SUBSCRIPTION_PLAN_NOTICE": "Det nya alternativet du väljer kommer att träda i kraft efter perioden du redan har betalt för. Eventuella rabatter du har kommer fortfarande gälla.",
    "TEACHER": "Pedagog",
    "TEACHER_BILLING_COMPLETE_HEADING": "Tack för att du fullföljt registreringen!",
    "TEACHER_BILLING_COMPLETE_MESSAGE": "Wow, nu får du och dina elever testa Zcooly gratis hela låsåret! Under de närmsta veckorna kommer vi att skicka inspiration med tips och vägledning kring hur du snabbt och enkelt kan använda Zcooly i ditt klassrum.",
    "TEACHER_REGISTRATION_BILLING_DESCRIPTION": "Fyll i fakturauppgifter nedan, så får du och dina elever Zcooly gratis hela läsåret.",
    "TEACHER_REGISTRATION_FAILED_SUBMIT": "Registreringen av ditt lärarkonto misslyckades.",
    "TEACHER_REGISTRATION_INCOMPLETE_MESSAGE": "Du är nu färdig med din registrering och kommer snart få ett mail med information om hur du kommer igång så fort vi har sett över era registreringsuppgifter.",
    "TEACHER_REGISTRATION_TITLE_SETT_BILLING": "För att få en längre provperiod!",
    "TEACHER_REGISTRATION_WAITING_FOR_TEACHER_CONFIRM_MESSAGE": "Du är nu färdig med din registrering och kommer snart få ett mail med information om hur du kommer igång så fort som den ansvarige pedagogen godkänt din åtkomst till klassen.",
    "THANK_YOU_FOR_YOUR_REGISTRATION": "Tack för din registrering!",
    "TOGGLE_HIDDEN_CONTENT_HIDE": "Dölj innehållet",
    "TOGGLE_HIDDEN_CONTENT_SHOW": "Visa innehållet",
    "TRIED_STARTING_OVERLAPPING_SUBSCRIPTION_PAUSE_ERROR_MESSAGE": "Pausen kunde inte planläggas då den överlappar en annan, redan befintlig paus.",
    "TRIED_STARTING_SUBSCRIPTION_PAUSE_IN_PAST_ERROR_MESSAGE": "Pausen kunde inte planläggas då startdatumet eller slutdatumet redan har varit.",
    "TRY_FOR_FREE_FOR_X_DAYS": "Prova gratis i {days} dagar",
    "TRY_THE_GAME_FOR_X_DAYS": "Prova gratis i {days} dagar",
    "TRY_THE_GAME_UNTIL_FURTHER_NOTICE": "Gratis tillsvidare",
    "YEARLY_(DISCOUNT)": "Årsvis (Spara 20%)",
    "YES": "Ja",
    "YOUR_CHILD_CANT_ACCESS_GAMES_IF_CANCELED_OR_PAUSED": "Ditt barn kan inte spela Zcooly om din prenumeration är avslutad eller pausad.",
    "YOUR_INVITATION_WAS_NOT_SENT_USER_ALREADY_WAS_INVITED": "E-postadressen du försökte skicka till är redan registrerad.",
    "YOUR_INVITATION_WAS_SUCCESSFULLY_SENT": "Din inbjudan skickades!",
    "YOUR_MEMBERSHIP_HAS_CHANGED": "Ditt medlemskap har ändrats.",
    "YOUR_TRIAL_HAS_STARTED": "Grymt, din provperiod är startad!",
    "YOU_HAVE_THIS_PACKAGE_ALREADY": "Du har redan detta medlemskap",
    "ZCOOLY_PACKAGE_BIG_DESCRIPTION": "För den större familjen.",
    "ZCOOLY_PACKAGE_BIG_NAME_LONG": "Zcooly Stora (upp till 3 barn)",
    "ZCOOLY_PACKAGE_BIG_TITLE": "Zcooly Stora",
    "ZCOOLY_PACKAGE_SMALL_DESCRIPTION": "För den mindre familjen.",
    "ZCOOLY_PACKAGE_SMALL_NAME_LONG": "Zcooly Lilla (ett barn)",
    "ZCOOLY_PACKAGE_SMALL_TITLE": "Zcooly Lilla"
}
