/**
 * @param {number} number
 * @return {string}
 */
function padNumber(number) {
  return number < 10 ? "0" + number : number.toString();
}

/**
 * @property {Date} time
 */
class DebugBase {
  constructor() {
    this.time = new Date();
  }

  get formattedTime() {
    return (
      padNumber(this.time.getHours()) +
      ":" +
      padNumber(this.time.getMinutes()) +
      ":" +
      padNumber(this.time.getSeconds()) +
      "." +
      this.time.getMilliseconds()
    );
  }

  get formattedUrl() {
    return (
      "/" +
      this.url
        .split("/")
        .slice(3)
        .join("/")
    );
  }
}

export default DebugBase;
