import store from "@/store/index";
import toast from "@/assets/libs/toast";
import DebugApiCall from "@/models/DebugApiCall";
import DebugNavigation from "@/models/DebugNavigation";

let instance = null;
const style = {
  log: "color:#4d4d4d",
  info: "color:#4185ab",
  warn: "color:#f2d024",
  error: "color:#e3342f"
};
const history = [];

let apiCallLog = [];
let errorLog = [];
let navigationLog = [];

/**
 * Console method handler which managed the message and native console methods.
 * @param {string} level
 * @param {object|array} args
 */
function callConsoleLog(level, args) {
  args = Array.prototype.slice.call(args);

  /**
   * Exit early if in production.
   */
  if (process.env.VUE_APP_ENV === "production") {
    return;
  }

  /**
   * Get the file, line from where the debug method was called.
   */
  const stack = new Error().stack.toString().split(/\r\n|\n/)[3];
  if (stack) {
    const src = `${stack
      .substring(stack.lastIndexOf("/") + 1)
      .replace(/:\d+\)?$/, "")
      .replace(/\?[^:]+:/, ":")}`;
    const time = new Date().toLocaleTimeString("sv");

    /**
     * Add timestamp, level and source in the front of the call.
     */
    args.unshift(style[level]);
    args.unshift(`%c[${time} ${level} ${src}]\n`);
  }

  /**
   * Add message to history log, but keep only the latest 1000 records in it.
   */
  history.push(args);
  if (history.length > 1000) {
    history.splice(0, history.length - 1000);
  }

  /**
   * Call the native console method.
   */
  console.log.apply(console, args);
}

/**
 * Enable a global method which prints the console history in the console.
 */
console.history = function() {
  console.dir(history);
};

class Debug {
  constructor() {
    if (instance) {
      throw new Error("Debug is already initialized.");
    }
    instance = this;
  }

  log() {
    callConsoleLog("log", arguments);
  }

  info() {
    callConsoleLog("info", arguments);
  }

  warn() {
    callConsoleLog("warn", arguments);
  }

  error() {
    callConsoleLog("error", arguments);
  }

  todo() {
    let args = Array.prototype.slice.call(arguments);
    args.unshift("############ TODO ############\n");
    args.push("\n############ TODO ############");
    callConsoleLog("info", args);
  }

  verbose() {
    if (store.state.debug.verbose) {
      let args = Array.prototype.slice.call(arguments);
      args.unshift("############ VERBOSE ############\n");
      callConsoleLog("log", args);

      if (
        args.length >= 2 &&
        (typeof args[1] === "string" || typeof args[1] === "number")
      ) {
        toast({
          message: args[1]
        });
      }
    }
  }

  /**
   * Report a route change or navigation.
   *
   * @param {object} route
   */
  reportNavigation(route) {
    navigationLog.push(new DebugNavigation(route));
    navigationLog = navigationLog.slice(-1000);
  }

  /**
   * @returns {DebugNavigation[]}
   */
  getNavigations() {
    return navigationLog;
  }

  /**
   * Report a captured error, either as the event, or a message as a string.
   *
   * @param {Event|string} eventOrString
   */
  reportError(eventOrString) {
    if (
      eventOrString instanceof Event &&
      eventOrString?.message &&
      eventOrString?.filename &&
      eventOrString?.lineno
    ) {
      errorLog.push(
        "Error: " +
          eventOrString.message +
          "[" +
          eventOrString.filename +
          ":" +
          eventOrString.lineno +
          "]"
      );
    } else {
      errorLog.push(eventOrString.toString());
    }

    errorLog = errorLog.slice(-1000);
  }

  /**
   * @returns {object[]|string[]}
   */
  getErrors() {
    return errorLog;
  }

  /**
   * Report an API call.
   *
   * @param {string} method
   * @param {string} url
   * @param {string} status
   */
  reportApiCall(method, url, status) {
    apiCallLog.push(new DebugApiCall(method, url, status));
    apiCallLog = apiCallLog.slice(-1000);
  }

  /**
   * @returns {DebugApiCall[]}
   */
  getApiCalls() {
    return apiCallLog;
  }
}

const debugInstance = new Debug();
/* Prevent modification of properties and values */
Object.freeze(debugInstance);
export default debugInstance;
