import axios from "axios";
import User from "../../api/v2/user";
import debug from "../../utilities/debug";
import i18n from "@/i18n";
import { isObject, merge } from "lodash";

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

let userLoadPromise = null,
  firstUserLoaded = false;

export default {
  namespaced: true,
  state: {
    status: "",
    token: "",
    user: {
      id: null,
      user_type: ""
    },
    /**
     * Prevent Race condition when logging out and reaching a new page that runs loadUser
     */
    isLoggingOut: false
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state) {
      state.status = "success";
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.isLoggingOut = true;
      state.status = "";
      state.user = {};
    },
    addChild(state, child) {
      state.user.children.push(child);
    },
    updateChild(state, child) {
      const childToUpdate = state.user.children.findIndex(c => {
        return c.id === child.id;
      });
      state.user.children[childToUpdate] = child;
    },
    updateSubscription(state, subscription) {
      state.user.subscription = subscription;
    },
    set_user(state, data) {
      state.user = data.user;
      state.token = data.token;
    },
    resetLogoutStatus(state) {
      state.isLoggingOut = false;
    },
    setActive(state, isActive) {
      if (state.user) {
        state.user.active = isActive;
      }
    },
    setSubscription(state, subscription) {
      if (state.user) {
        if (isObject(state.user.subscription)) {
          merge(state.user.subscription, subscription);
        } else {
          state.user.subscription = subscription;
        }
      }
    }
  },
  actions: {
    /**
     * Login the user.
     *
     * @param context
     * @param credentials
     * @return {Promise<unknown>}
     */
    login({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        commit("auth_request");

        User.loginUser(credentials)
          .then(response => {
            const user = response.data();

            dispatch("setUser", { user, token: user.login.token });

            commit("auth_success");
            resolve(response);
          })
          .catch(error => {
            commit("auth_error");
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            reject(error);
          });
      });
    },
    /**
     * Logout the user by clearing the store data, removing data from local storage, and
     * removing the authorization header.
     *
     * @param commit
     * @param state
     * @return {Promise<void>}
     */
    logout({ commit }) {
      commit("logout");

      localStorage.removeItem("user");
      localStorage.removeItem("token");

      delete axios.defaults.headers.common["Authorization"];
      setTimeout(() => {
        commit("resetLogoutStatus");
      }, 3000);
      return Promise.resolve();
    },
    /**
     * Set the user in the store and local storage, also set the authorization header for future requests.
     *
     * @param context
     * @param {object} user
     * @param {string} token
     * @param {bool} fromLogin
     * @return {Promise<void>}
     */
    setUser({ commit, state }, { user, token, fromLogin }) {
      if (fromLogin) {
        firstUserLoaded = true;
      }

      /**
       * Opt out earlier if currently in process of logging out.
       */
      if (state.isLoggingOut) {
        commit("logout");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];

        return Promise.resolve();
      }

      commit("set_user", { user, token });

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);

      axios.defaults.headers.common["Authorization"] = "Bearer " + token;

      /**
       * Change the language of the UI based on the user's preferred language.
       */
      if (user.language) {
        i18n.global.locale = user.language;
      }

      return Promise.resolve();
    },
    /**
     * This is called on each page transition.
     *
     * @param context
     * @param userData
     * @return {Promise<void>}
     */
    loadUser({ dispatch, getters }, userData) {
      /**
       * Return immediately if user is already signed in.
       */
      if (getters.isLoggedIn) {
        return Promise.resolve();
      }

      let data = {},
        shouldRefresh = false;

      /**
       * Check if user data is supplied through a GET parameter.
       */
      if (typeof userData === "string") {
        userData = decodeURIComponent(userData);

        /**
         * Check if the user data is a plain JSON object, or if it needs to be base64 decoded before unpacking with JSON.
         */
        if (userData.substring(0, 1) === "{") {
          data = JSON.parse(userData);
          debug.log("Parsing JSON user data from URL.");
        } else {
          data = JSON.parse(atob(userData));
          debug.log("Parsing base64 user data from URL.");
        }
      }

      /**
       * Check if user credentials was passed in the URL, then set the user.
       */
      if (data.id && data.token && data.user_type) {
        dispatch("setUser", { user: data, token: data.token });

        shouldRefresh = true;
      } else if (data.userID && data.accessToken && data.userType) {
        /**
         * Check if the user was supplies using the old format, then convert it and set the user.
         */
        dispatch("setUser", {
          user: {
            id: data.userID,
            token: btoa(data.userID + ":" + data.accessToken),
            user_type: data.userType
          },
          token: btoa(data.userID + ":" + data.accessToken)
        });

        shouldRefresh = true;
      } else {
        /**
         * If no user was supplied as a GET parameter override, try to load a user from local storage.
         */
        const user = localStorage.getItem("user");
        let token = localStorage.getItem("token");

        if (user && isJson(user)) {
          data = JSON.parse(user);

          /**
           * Handle session if loading from old login.
           */
          if (data.userID && data.accessToken && data.userType) {
            data = {
              id: data.userID,
              access_token: data.accessToken,
              user_type: data.userType
            };
            token = btoa(data.id + ":" + data.access_token);
          }

          if (token) {
            data.token = token;
            debug.log("Loading user from local storage.");

            shouldRefresh = true;

            dispatch("setUser", { user: data, token: data.token });
          }
        }
      }

      if (shouldRefresh) {
        dispatch("refreshUser");
      }

      return Promise.resolve();
    },
    async refreshUser({ dispatch }) {
      if (userLoadPromise !== null) {
        await userLoadPromise;
        return true;
      }

      let outsideResolve;
      userLoadPromise = new Promise(resolve => {
        outsideResolve = resolve;
      });

      const user = await User.getCurrentUser(
        !firstUserLoaded ? { "page-load": 1 } : {}
      );

      firstUserLoaded = true;

      await dispatch("setUser", { token: user.login.token, user });
      outsideResolve();
      userLoadPromise = null;

      return true;
    }
  },
  getters: {
    isLoggedIn: state => {
      return !!state.token && !!state.user.id && !!state.user.user_type;
    },
    isActive: state => !!state.user.active
  },
  modules: {},
  strict: process.env.VUE_APP_ENV !== "production"
};
