<template>
  <div class="w-full flex justify-center items-center py-8">
    <div class="inline-block w-6 h-6 rotate">
      <svg class="w-full h-full">
        <use xlink:href="#spinner-blue"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner"
};
</script>
