export default {
  // When the bound element is mounted into the DOM...
  mounted(element, binding) {
    if (binding.value !== false) {
      // Focus the element

      setTimeout(() => {
        element.focus();
      }, 300);
    }
  }
};
