"use strict";

/**
 * @param {{message: string, type?: string, autoRemoveTime?: int}} options
 * @param {string} options.message                - The message to be displayed in the toast.
 * @param {string} [options.type=default]         - Can be one of the following: success, info, warning, danger or default, defaults to "default".
 * @param {int}    [options.autoRemoveTime=10000] - Auto close time in milliseconds, defaults to 10000.
 */
export default function(options) {
  if (!options.message) {
    throw new Error("Toast.js - You need to set a message to display");
  }

  options.type = options.type || "default";
  options.autoRemoveTime = options.autoRemoveTime || 10000;

  function run() {
    let toastEl = document.createElement("div"),
      toastContainerEl = document.querySelector(".toastjs-container");

    if (!toastContainerEl) {
      toastContainerEl = document.createElement("div");
      toastContainerEl.classList.add("toastjs-container");
      toastContainerEl.setAttribute("role", "alert");
      document.body.appendChild(toastContainerEl);
    }

    toastEl.classList.add("toastjs");
    toastEl.classList.add(options.type);
    toastEl.setAttribute("aria-hidden", true);
    toastEl.innerHTML = "<p>" + options.message + "</p>";
    toastContainerEl.appendChild(toastEl);

    toastEl.addEventListener("click", function() {
      removeToast(toastEl);
    });

    setTimeout(function() {
      toastEl.removeAttribute("aria-hidden");
    }, 350);

    if (options.autoRemoveTime && typeof options.autoRemoveTime === "number") {
      setTimeout(function() {
        removeToast(toastEl);
      }, options.autoRemoveTime);
    }
  }

  function removeToast(toast) {
    if (document.querySelector(".toastjs-container").contains(toast)) {
      toast.setAttribute("aria-hidden", true);
      setTimeout(function() {
        document.querySelector(".toastjs-container").removeChild(toast);
      }, 350);
    }
  }

  if (typeof options.message === "string") {
    run();
  } else if (typeof options.message.then === "function") {
    options.message.then(
      function(text) {
        options.message = text;
        run();
      },
      function(text) {
        options.message = text;
        run();
      }
    );
  }
}
