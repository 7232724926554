import Resource from "@/api/resource/resource";

class Registration extends Resource {
  /**
   * @param {object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return process.env.VUE_APP_API_ENDPOINT + "/api/open/v2/register/:action/";
  }

  /**
   * Register a stripe parent.
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async registerParent(data) {
    return new Promise((resolve, reject) => {
      this.create({ action: "parent" }, data).then(user => {
        resolve(user);
      }, reject);
    });
  }

  /**
   * Send form data for extra parent completing their invitation and registering an account.
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async acceptParentInvitation(data) {
    return new Promise((resolve, reject) => {
      this.create({ action: "accept-parent-invitation" }, data).then(user => {
        resolve(user);
      }, reject);
    });
  }

  /**
   * Invite parent to an existing account
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async inviteParent(data) {
    return new Promise((resolve, reject) => {
      this.create({ action: "invite-parent" }, data).then(user => {
        resolve(user);
      }, reject);
    });
  }

  /**
   * Updates a teacher's school's customer with new billing information.
   *
   * @param {object} data
   * @return {Promise<Resource>}
   */
  static async teacherUpdateBilling(data) {
    return new Promise((resolve, reject) => {
      this.create({ action: "teacher-update-billing" }, data).then(user => {
        resolve(user);
      }, reject);
    });
  }
}

export default Registration;
