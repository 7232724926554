<template>
  <div class="login-window">
    <!-- Swedish family login form -->
    <h2 class="font-nunito-extrabold mb-2">
      {{ $t("SIGN_IN_WITH_ZCOOLY") }}
    </h2>
    <ZForm name="internationalLoginForm" @submit="onLogin" class="mb-8">
      <div class="form-errors mb-2">
        <p class="error-message" v-if="loginError">
          <svg class="w-3 h-3 mr-1 inline-block">
            <use xlink:href="#form-error"></use>
          </svg>
          <span v-if="loginError === 'error'">{{
            $t("LOGIN_ERROR_MISC")
          }}</span>
          <span v-else-if="loginError === 'license-expired'">{{
            $t("LOGIN_ERROR_PARENT_LICENSE_EXPIRED")
          }}</span>
          <span
            v-else-if="loginError === 'teacher-in-app'"
            data-cy="login-error-teacher-in-app"
            >{{ $t("LOGIN_ERROR_TEACHER_IN_APP") }}</span
          >
          <span
            v-else-if="loginError === 'credentials'"
            data-cy="login-error-credentials"
            >{{ $t("LOGIN_ERROR_INTERNATIONAL_CREDENTIALS") }}</span
          >
          <span v-else>{{ $t("LOGIN_ERROR_MISC") }}</span>
        </p>
      </div>
      <TextInput
        type="text"
        v-model="login.username"
        rules="required"
        name="international-email"
        t-label="EMAIL_ADDRESS_OR_USERNAME"
        :disabled="isLoggingIn"
        data-cy="international-login-email"
      />

      <TextInput
        type="password"
        v-model="login.password"
        rules="required"
        name="international-password"
        t-label="PASSWORD"
        :disabled="isLoggingIn"
        visibility-toggle
        data-cy="international-login-password"
      />

      <p class="forgot-password">
        <a
          @click="resetPassword"
          class="underline text-sm font-open-sans-bold cursor-pointer"
          >{{ $t("FORGOT_PASSWORD") }}</a
        >
      </p>
      <div class="button-container">
        <Button
          type="submit"
          class="btn btn--grass"
          :is-loading="isLoggingIn"
          :disabled="isLoggingIn"
          data-cy="international-login-button"
          >{{ $t("LOGIN_ZCOOLY_PLAY") }}
        </Button>
      </div>
    </ZForm>

    <div class="registration-link text-sm text-center">
      <p class="mb-2">{{ $t("NOT_ALREADY_MEMBER") }}</p>
      <a
        @click.prevent="handleParentRegisterLink"
        class="underline font-open-sans-bold cursor-pointer"
        >{{ $t("GET_ZCOOLY_PLAY") }}</a
      >
    </div>
  </div>
</template>
<script>
import Button from "@/components/Button";
import ZForm from "@/components/form-parts/ZForm";
import TextInput from "@/components/form-parts/TextInput";
import { canUseAppleSubscription } from "@/utilities/feature-control";
import User from "@/api/v2/user";
import { openPrompt } from "@/utilities/modal";
import auth from "@/utilities/auth";

export default {
  name: "InternationalLogin",
  components: { Button, ZForm, TextInput },
  data() {
    return {
      login: {
        username: "",
        password: ""
      },
      loginError: null,
      isLoggingIn: false
    };
  },
  methods: {
    handleParentRegisterLink() {
      if (
        this.$store.state.global.inAppSubscription &&
        canUseAppleSubscription()
      ) {
        this.$router.push({ name: "apple-splash" });
      } else if (
        this.$store.state.global.isApp &&
        !this.$store.state.global.isIOS
      ) {
        this.$router.push({ name: "stripe-splash" });
      } else {
        this.$router.push({ name: "register-family" });
      }
    },
    async onLogin() {
      if (this.isLoggingIn) {
        return;
      }

      this.isLoggingIn = true;
      try {
        const credentials = {
          password: this.login.password
        };

        if (this.login.username.indexOf("@") !== -1) {
          credentials.email = this.login.username;
        } else {
          credentials.username = this.login.username;
        }

        const user = await User.loginUser(credentials);

        if (user.user_type === "teacher") {
          if (this.$store.state.global.isApp) {
            this.loginError = "teacher-in-app";
          } else {
            auth.redirectToApp("onLoginTeacher", {
              user: auth.generateOldUserObject(user),
              loginType: "teacher"
            });
          }
        } else if (user.user_type === "student") {
          auth.redirectToApp("onLoginStudent", {
            user: auth.generateOldUserObject(user),
            loginType: "student"
          });
        } else {
          this.$store.dispatch("user/setUser", {
            user: user,
            token: user.login.token,
            fromLogin: true
          });

          await this.$router.push({ name: "children" });
        }
      } catch (error) {
        if (error.response?.status === 401) {
          this.loginError = "credentials";
        } else if (error.response?.status === 402) {
          this.loginError = "license-expired";
        } else {
          this.loginError = "error";
        }
      }

      this.isLoggingIn = false;
    },
    resetPassword() {
      const email =
        this.login.username.length && this.login.username.indexOf("@") !== -1
          ? this.login.username
          : null;

      openPrompt("reset-password", {
        email
      });
    }
  }
};
</script>
