import Resource from "../resource/resource";

/**
 * @param {Object} user
 * @return {Object}
 */
function getChildAvatarStyle(user) {
  let letterIndex = 0;
  const style = {};

  if (user.first_name) {
    const alphabet = "abcdefghijklmnopqrstuvwxyzåäö".split(""),
      firstLetter = user.first_name.substring(0, 1).toLowerCase();

    letterIndex =
      alphabet.indexOf(firstLetter) !== -1 ? alphabet.indexOf(firstLetter) : 0;
  }

  const hue = letterIndex * 12;
  style.color = "hsl(" + hue + ", 54%, 45%)";

  return style;
}

/**
 * @property {Number} id
 * @property {String} access_token
 * @property {String} user_type
 * @property {String|null} first_name
 * @property {String|null} last_name
 * @property {String} language
 * @property {String} created_at
 * @property {String} updated_at
 * @property {Object} meta
 * @property {Object} login
 * @property {Boolean} active
 *
 * @property {String|null} avatar_classes
 * @property {String|null} avatar_url
 * @property {String|null} avatar_svg
 * @property {Boolean|null} needs_action
 *
 * @property {Object|null} group
 * @property {Object|null} subscription
 * @property {Array|null} children
 */
class User extends Resource {
  /**
   * @param {Object} data
   */
  constructor(data = {}) {
    super(data);
  }

  /**
   * @return {string}
   */
  static get url() {
    return process.env.VUE_APP_API_ENDPOINT + "/api/open/v2/user/:id/:action/";
  }

  /**
   * @param {Object} user
   * @return {Object}
   */
  static transformIncoming(user) {
    /**
     * Adding meta if it's missing.
     */
    if (Object.prototype.hasOwnProperty.call(user, "meta")) {
      if (Object.prototype.hasOwnProperty.call(user.meta, "length")) {
        user.meta = {};
      }
    } else {
      user.meta = {};
    }

    if (user.user_type === "child") {
      user.avatar_url = user.meta?.avatar_url ? user.meta.avatar_url : null;
      user.avatar_style = getChildAvatarStyle(user);
      user.avatar_classes = `child-background-0${(user.id % 8) + 1}`;
      user.needs_action =
        (user.language === "sv" && user.first_name === "Barn") ||
        (user.language === "en" && user.first_name === "Child") ||
        !user.first_name;
    }

    if (user.user_type === "parent") {
      /**
       * Setting default avatar on parent.
       */
      if (!user.meta.avatar) {
        user.meta.avatar = "parent-avatar-0";
      }

      user.avatar_svg = user.meta.avatar;

      user.login.newsletter = user.login.newsletter === 1;
    }

    return user;
  }

  static transformOutgoing(user) {
    if (user.user_type === "parent") {
      user.login.newsletter = user.login.newsletter ? 1 : 0;
    }

    return user;
  }

  /**
   * Login and return User resource on success.
   * @return {Promise<User>}
   */
  static loginUser(credentials) {
    return User.create(
      {
        action: "login"
      },
      credentials
    );
  }

  /**
   * Get the current user objekt.
   *
   * The response is cached for 10 seconds to prevent duplicates at page load.
   *
   * @param {object} params
   *
   * @returns {Promise<User>}
   */
  static async getCurrentUser(params = {}) {
    return new Promise((resolve, reject) => {
      if (this.cacheHas("current-user")) {
        resolve(this.cacheGet("current-user"));

        return;
      }

      this.get(params).then(subjects => {
        this.cacheSet("current-user", subjects, 10);

        resolve(subjects);
      }, reject);
    });
  }
}

export default User;
