<template>
  <div class="card" :class="{ 'card--sub': isSubCard }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Card",
  props: {
    isSubCard: {
      type: Boolean,
      default: false
    }
  }
};
</script>
