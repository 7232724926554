export function scrollToY(y) {
  window.scrollTo(0, y);
  document.body.scrollTop = y;
  document.documentElement.scrollTop = y;

  const pageWrapper = document.getElementById("page-wrapper");
  if (pageWrapper) {
    pageWrapper.scrollTo(0, y);
    pageWrapper.scrollTop = y;
  }
}

/* Takes a validation result and scrolls to the first element with the first error found in the vee-validate form */
export function scrollToFirstError(validationResult) {
  if (
    !validationResult.valid &&
    validationResult.errors &&
    Object.keys(validationResult.errors).length
  ) {
    const el = document.querySelector(
      `[name='${Object.keys(validationResult.errors)[0]}']`
    );
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }
}

/**
 * Get the value of a cookie.
 * @param {string} cookieName
 * @return {string}
 */
export function getCookie(cookieName) {
  const name = cookieName + "=",
    decodedCookie = decodeURIComponent(document.cookie),
    cookies = decodedCookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}

/**
 * Set the value of a cookie.
 * @param {string} cookieName
 * @param {string} cookieValue
 * @param {number} daysToExpiration
 */
export function setCookie(cookieName, cookieValue, daysToExpiration) {
  let date = new Date();

  date.setTime(date.getTime() + daysToExpiration * 24 * 60 * 60 * 1000);
  document.cookie =
    cookieName +
    "=" +
    cookieValue +
    ";expires=" +
    date.toUTCString() +
    ";path=/";
}

/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export function post(path, params, method = "post") {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  for (const key in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
    }
  }

  document.body.appendChild(form);
  form.submit();
}

/**
 * Get all query parameters as an object.
 * @return {object}
 */
export function queryToObject() {
  let queryObject = {},
    pair = null,
    queryArray = window.location.search.substring(1).split("&");

  for (let i = 0; i < queryArray.length; i++) {
    pair = queryArray[i].split("=");
    queryObject[pair[0]] = pair[1] || null;
  }

  return queryObject;
}

/**
 * Transform a name to its possessive form.
 * @param {string} string
 * @return {{en: string, sv: string}}
 */
export function possessiveForm(string) {
  const translations = {
    en: "",
    sv: ""
  };

  if (!string || string.length === 0) {
    return translations;
  }

  const lastChar = string.slice(-1);

  translations.en = string + (lastChar.toLowerCase() === "s" ? "'" : "'s");
  translations.sv = string + (lastChar.toLowerCase() === "s" ? "" : "s");

  return translations;
}
