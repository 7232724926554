import debug from "@/utilities/debug";

const callbacks = {};

let instance = null;

class Event {
  /**
   * Attach a new event listener to a given event.
   * @param {string} event The event name.
   * @param {function} callback The callback to add to the event listener list.
   */
  on(event, callback) {
    if (!Object.prototype.hasOwnProperty.call(callbacks, event)) {
      callbacks[event] = [];
    }

    callbacks[event].push(callback);

    debug.log(`Adding event listener to "${event}".`);
  }

  /**
   * Removes all or a specific event listener from a given event.
   *
   * @param {string} event The event name.
   * @param {function} callback The optional callback to remove from the event listener list.
   * @returns {boolean}
   */
  off(event, callback) {
    if (
      !Object.prototype.hasOwnProperty.call(callbacks, event) ||
      callbacks[event].length === 0
    ) {
      return false;
    }

    if (typeof callback === "function") {
      const indicesToRemove = [];

      callbacks[event].forEach((cb, cbIndex) => {
        if (cb === callback) {
          indicesToRemove.push(cbIndex);
        }
      });

      if (indicesToRemove.length > 0) {
        indicesToRemove.reverse().forEach(cbIndex => {
          callbacks[event].splice(cbIndex, 1);

          debug.log(`Removed an event listener from "${event}".`);
        });
      }
    } else {
      callbacks[event] = [];

      debug.log(`Removed all event listeners from "${event}".`);
    }

    return true;
  }

  /**
   * Trigger all registered event listeners for a given event, with an optional number of arguments to pass to the listeners.
   *
   * @param {string} event The event name.
   * @param {any} payload One or multiple arguments to pass to the event listeners.
   * @returns {boolean}
   */
  trigger(event, ...payload) {
    if (
      !Object.prototype.hasOwnProperty.call(callbacks, event) ||
      callbacks[event].length === 0
    ) {
      debug.log(`Event "${event}" did not have any listeners.`);

      return false;
    }

    callbacks[event].forEach(cb => {
      cb.call(this, ...payload);
      debug.log(`Triggered event "${event}" with payload:`, ...payload);
    });

    return true;
  }

  /**
   * Get a promise that will be resolved when the given event is triggered, or rejected when the optional timeout is finished.
   *
   * @param {string} event The event name.
   * @param {number} timeout A number of milliseconds to wait for the event to be triggered before it should be aborted and rejected.
   * @returns {Promise<any>}
   */
  awaitEvent(event, timeout = 0) {
    return new Promise((resolve, reject) => {
      let timer = null;
      if (timeout > 0) {
        timer = setTimeout(() => {
          this.off(event, callback);
          this.off("reject:" + event, rejectCallback);
          debug.log(
            `Rejected promise for event "${event}" because timeout of ${timeout} was reached.`
          );
          reject();
        }, timeout);
      }

      const callback = (...payload) => {
        clearTimeout(timer);
        debug.log(`Event "${event}" resolved and returned payload:`, payload);
        resolve(payload.length ? payload[0] : null);
      };

      const rejectCallback = () => {
        debug.log(
          `Event "${event}" was rejected by external event "reject:${event}"`
        );
        reject();
      };

      this.on(event, callback);
      this.on("reject:" + event, rejectCallback);
    });
  }
}

export function useEvent(options) {
  if (instance === null) {
    instance = new Event(options);
  }

  return instance;
}

export default {
  install: (app, options) => {
    app.config.globalProperties.$event = useEvent(options);
  }
};
